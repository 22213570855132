import * as React from "react";
import Card from "@mui/material/Card";
import "../../../../../HomePage/SubComponents/CardListing/Card.css";
import "../../../../../App.css";
import "../../../../../Components.css";
import CardActions from "@mui/material/CardActions";
import { Checkbox, FormControlLabel } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import CardMiddleSection from "../../../../../HomePage/SubComponents/MapandListing/CardMiddleSection";
import "../../../../../HomePage/SubComponents/MapandListing/CardMain.css";

const UserListingCard = ({
  item,
//   ListingOwnerName,
  selectedCards,
  setSelectedCards,
}) => {
  const listingDateHide = true;

  const handleCheckboxChange = (event, uId) => {
    if (event.target.checked) {
      setSelectedCards((prevSelectedUIds) => [...prevSelectedUIds, uId]);
    } else {
      setSelectedCards((prevSelectedUIds) =>
        prevSelectedUIds.filter((selectedUId) => selectedUId !== uId)
      );
    }
  };

  return (
    <>
      <div className="mapandListing_card">
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <div className="p-0">
              <Card className="main_cardmi">
                <a
                  className="linkline"
                  href={`/assignment-sale/${item.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CardMiddleSection
                    listingDateHide={listingDateHide}
                    item={item}
                  />
                </a>
                <div className="Listingselect_favViews">
                  <div className="ne">
                    {" "}
                    <CardActions>
                      <div className="grey_card listing_selctedDiv}">
                        <FormControlLabel
                          control={
                            <Checkbox
                              className="compare_box listing_selectBox"
                              style={{
                                color: "#676767",
                              }}
                              onChange={(event) =>
                                handleCheckboxChange(event, item.uId)
                              }
                              checked={selectedCards.includes(item.uId)}
                            />
                          }
                          label={
                            <span
                              className="cardMain_compare"
                              style={{ fontSize: "12px" }}
                            >
                              Select
                            </span>
                          }
                        />
                      </div>
                    </CardActions>
                  </div>
                  <div className="two heartadShare aligncenter justifycenter">
                    {" "}
                    <CardActions disableSpacing style={{ gap: "7px" }}>
                      <div className="listingFav_views">
                        <p className="m-0">{`Fav(${
                          item?.favourits
                            ? item?.favourits?.toLocaleString("en-US")
                            : 0
                        })`}</p>
                      </div>
                      <div className="listingFav_views">
                        <p className="m-0">{`Views(${
                          item?.views ? item?.views?.toLocaleString("en-US") : 0
                        })`}</p>
                      </div>
                    </CardActions>
                  </div>
                </div>
              </Card>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default UserListingCard;
