import React, { useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { NewsIcon } from "../../../SvgIcons/Icons";
import ArticleSlider from "./Articles/ArticleSlider";

const RealtorInNews = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  const slides = Array(6).fill(null);
  const OPTIONS = { slidesToScroll: "auto" };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex flex-column" : ""}>
          <div className="d-flex align-items-center gap-2">
            {isMobileView && <NewsIcon />}
            <h2 className="property_Details_heading">
              Umar Khan’s in the News
            </h2>
          </div>
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
            Umar Khan's 110k media updates showcase his real estate expertise,
            market insights, and achievements, highlighting his impact on the
            industry.
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-100 mt-3">
          <ArticleSlider slides={slides} options={OPTIONS} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(RealtorInNews);
