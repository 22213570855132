import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_TOKEN}`;


const RealtorPropertiesMap = ({isMobileView}) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  // Dummy data for 10 properties in Toronto
  const properties = [
    { id: 1, lat: 43.65107, lng: -79.347015 },
    { id: 2, lat: 43.653908, lng: -79.384293 },
    { id: 3, lat: 43.6441, lng: -79.3992 },
    { id: 4, lat: 43.6555, lng: -79.3786 },
    { id: 5, lat: 43.6572, lng: -79.3939 },
    { id: 6, lat: 43.6528, lng: -79.3849 },
    { id: 7, lat: 43.6548, lng: -79.3883 },
    { id: 8, lat: 43.6515, lng: -79.3792 },
    { id: 9, lat: 43.6561, lng: -79.3801 },
    { id: 10, lat: 43.6506, lng: -79.3903 },
  ];

  useEffect(() => {
    if (mapRef.current) return;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-79.3832, 43.6532],
      zoom: 12,
    });

    const bounds = new mapboxgl.LngLatBounds();

    properties.forEach((property) => {
      const markerElement = document.createElement("div");
      markerElement.innerHTML = `
       <svg xmlns="http://www.w3.org/2000/svg" width="30" height="38" viewBox="0 0 30 38" fill="none">
  <path d="M9.50065 20.8307H13.1673V15.7891H16.834V20.8307H20.5007V12.1224L15.0007 8.45573L9.50065 12.1224V20.8307ZM15.0007 37.3307C10.0812 33.1446 6.40721 29.2567 3.97865 25.6671C1.5501 22.0774 0.335207 18.7542 0.333984 15.6974C0.333984 11.1141 1.8086 7.46267 4.75782 4.74323C7.70704 2.02378 11.1213 0.664062 15.0007 0.664062C18.88 0.664062 22.2949 2.02378 25.2453 4.74323C28.1958 7.46267 29.6698 11.1141 29.6673 15.6974C29.6673 18.753 28.453 22.0762 26.0245 25.6671C23.5959 29.2579 19.9213 33.1458 15.0007 37.3307Z" fill="#007BFF"/>
</svg>`;

      new mapboxgl.Marker(markerElement)
        .setLngLat([property.lng, property.lat])
        .addTo(mapRef.current);

      bounds.extend([property.lng, property.lat]);
    });

    mapRef.current.fitBounds(bounds, { padding: 50 });

    return () => mapRef.current.remove();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: isMobileView ? "250px" : "390px",
        borderRadius: "16px",
        border: "1px solid var(--Accent-Medium-Gray, #E2E2E2)",
      }}
      ref={mapContainerRef}
    ></div>
  );
};

export default RealtorPropertiesMap;
