import "./listingtable.css";
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../Dashboard";
import { Modal } from "react-bootstrap";
// import { AiFillUnlock } from 'react-icons/ai';
import DeleteIcon from "@mui/icons-material/Delete";
import { Dropdown } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import Cookies from "universal-cookie";
import baseURL from "../../../config";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { Chip, IconButton, InputBase } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
// import { addListingsDataSlice } from '../../../ReduxStore/Slices/ListingsDataSlice';
// import { useDispatch } from 'react-redux';
import SearchIcon from "@mui/icons-material/Search";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { packageData } from "../../../Components/GetQuotes/PkgsData";
import CloseIcon from "@mui/icons-material/Close";
import PayModal from "./PayModal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import PriceChangingModal from "./PriceChangingModal";
import { useSelector } from "react-redux";

const MyListing = () => {
  const { id } = useParams();
  const [listingHasData, setListingHasData] = useState();
  const [initialLoad, setInitialLoad] = useState(true);
  const [data, setData] = useState([]);
  const userData = useSelector(state => state.userProfileDataSlice[0])
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const currentDate = new Date();
  const [loading, setLoading] = useState({});
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [selectedPackages, setSelectedPackages] = useState([]);
  // const listings = useSelector((state) => state.ListingsDataSlice[0]);
  const [showModal, setShowModal] = useState(false);
  const [showBumpModal, setShowBumpModal] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [showPriceChangeModal, setShowPriceChangeModal] = useState(false);
  const [showPackagesCard, setShowPackagesCard] = useState([]);
  const [userId, setuserId] = useState("");
  const [assignmentId, setAssignmentId] = useState("");
  const [propStatusBtn, setPropStatusBtn] = useState("");
  const [searchBy, setSearchBy] = useState("ProjectName");
  const [searchValue, setSearchValue] = useState("");
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [hasMoreContent, setHasMoreContent] = useState(false);
  const [contentPage, setContentPage] = useState(1);
  const [loadMore, setLoadMore] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    TopHomePageGallery: {
      days: 7,
      price: 5.5,
    },
    HomePageTicker: {
      days: 7,
      price: 7.0,
    },
    HighlightAdd: {
      days: 7,
      price: 8.0,
    },
    Tags: {
      days: 7,
      price: 10,
    },
  });

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (id !== "Active" && id !== "Pending" && id !== "Supspended") {
      navigate("/dashboard/assignments-listing/Active");
    }
  }, []);

  function FetchData() {
    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };
    const data = {
      Status: id,
      Term: searchValue,
      TermTitle: searchBy, //ProjectName,Id,AssignmentPrice,InteriorSize
      page: contentPage,
      UserId: userData?.id,
    };

    axios
      .post(`${baseURL}/api/User-Data/GetAssignment`, data, { headers })
      .then((response) => {
        if (response) {
          const ListingData = response?.data?.data?.assignments;
          if (contentPage === 1) {
            setData(ListingData);
          } else {
            setData((prevData) => [...prevData, ...ListingData]);
          }
          if (initialLoad) {
            setListingHasData(ListingData?.length > 0);
          }
          setTotalPageCount(Math.ceil(response?.data?.data?.count / 20));
          setLoadMore(false);
          setLoader(false);
          setInitialLoad(false);
        }
      })
      .catch((error) => {
        toast.error("error Fetching data");
        setLoader(false);
      });
  }

  useEffect(() => {
    if (!jwt_token) {
      navigate("/login", {
        state: { returnUrl: "dashboard/assignments-listing/Active" },
      });
      return;
    }
    if (contentPage === 1) {
      setLoader(true);
      setHasMoreContent(false);
    }
    setPropStatusBtn(id);
    FetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPage, id]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShowBumpModal = (id) => {
    setShowBumpModal(true);
    setAssignmentId(id);
  };

  const handleCloseBumpModal = () => {
    setShowBumpModal(false);
  };

  const handleClosePayModal = () => {
    setShowPayModal(false);
  };

  const handleShowPriceModal = (id) => {
    setShowPriceChangeModal(true);
    setAssignmentId(id);
  };

  const handleClosePriceModal = () => {
    setShowPriceChangeModal(false);
  };

  const handleAddToPackage = (category) => {
    const isCategorySelected = selectedPackages.some(
      (pkg) => pkg.category === category
    );

    if (isCategorySelected) {
      handleRemoveFromPackage(category);
    } else {
      const selectedPackage = {
        category,
        days: selectedOption[category].days,
        price: selectedOption[category].price,
      };
      setSelectedPackages((prevPackages) => [...prevPackages, selectedPackage]);
    }
  };

  const handleRemoveFromPackage = (category) => {
    const updatedPackages = selectedPackages.filter(
      (pkg) => pkg.category !== category
    );
    setSelectedPackages(updatedPackages);
  };

  const handleAddorRemovepkgIcon = (category) => {
    const isCategorySelected = selectedPackages.some(
      (pkg) => pkg.category === category
    );
    return isCategorySelected
      ? require("../../../Components/Images/MinusIcon.png")
      : require("../../../Components/Images/AddIcon.png");
  };

  const calculatePrice = (days, category) => {
    const categoryData = packageData[category];
    const selectedData = categoryData.find((data) => data.days === days);
    return selectedData ? selectedData.price : 0;
  };

  // const debounce = (func, delay) => {
  //     let timer;
  //     return function (...args) {
  //         clearTimeout(timer);
  //         timer = setTimeout(() => {
  //             func.apply(this, args);
  //         }, delay);
  //     };
  // };

  // const debouncedSearch = debounce((query) => {
  //     if (query.length > 0 || query === '') {
  //         FetchData(query)
  //     }
  //     else {
  //         setLoader(false)
  //     }
  // }, 1500);

  // const handleSearchInputChange = (event) => {
  //     if (!jwt_token) {
  //         navigate('/login');
  //         return;
  //     }
  //     setLoader(true)
  //     setContentPage(1)
  //     setSearchValue(event.target.value)
  //     debouncedSearch(event.target.value);
  // };

  // const handleSearchInputChange = (event) => {
  //     if (!jwt_token) {
  //         navigate('/login');
  //         return;
  //     }
  //     setLoader(true)
  //     setContentPage(1)
  //     setSearchValue(event.target.value)
  //     debouncedSearch(event.target.value);
  // };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchClick = () => {
    setContentPage(1);
    if (contentPage === 1) {
      setLoader(true);
      setHasMoreContent(false);
      FetchData();
    }
  };

  const HandleMoreContent = () => {
    setLoadMore(true);
    setContentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    if (contentPage === totalPageCount || totalPageCount === 0) {
      setHasMoreContent(false);
    } else {
      setHasMoreContent(true);
    }
  }, [contentPage, totalPageCount]);

  function PropertyStatusChange(row, selectedStatus) {
    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };

    // Set loading state for the specific row
    setLoading((prevLoading) => ({
      ...prevLoading,
      [row.uId]: true,
    }));

    axios
      .put(
        `${baseURL}/api/Assignment/ChangeStatus`,
        {
          uId: row?.uId,
          Status: selectedStatus,
          UserId: row?.userId,
        },
        { headers }
      )
      .then((response) => {
        const updatedData = data.map((item) => {
          if (item.uId === row?.uId) {
            return { ...item, propStatus: selectedStatus };
          }
          return item;
        });
        setData(updatedData);
        setLoading((prevLoading) => ({
          ...prevLoading,
          [row.uId]: false,
        }));
      })
      .catch((error) => {
        toast.error("Error:", error);
        // Clear loading state for the specific row
        setLoading((prevLoading) => ({
          ...prevLoading,
          [row.uId]: false,
        }));
      });
  }

  const ChipStyle = {
    backgroundColor: "deepskyblue",
    color: "white",
  };

  const StatusChipStyle = {
    Active: { background: "#84FAB5", color: "#005624" },
    Pending: { background: "#F8EFA3", color: "#6F6300" },
    Suspended: { background: "#FF4D6D", color: "#fff" },
  };

  function DateFormating(inputDate) {
    const dateStr = inputDate;
    const date = new Date(dateStr);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
  }

  const handleDeleteAssignment = () => {
    handleCloseModal();
    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };

    axios
      .delete(`${baseURL}/api/Assignment/DeleteAssignment`, {
        headers: headers,
        data: {
          uId: userId,
          Status: "Suspended",
        },
      })
      .then((response) => {
        setContentPage(1);
        setLoader(true);
        FetchData(searchValue);
        toast.success("Successfully Suspended");
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.message || "An error occurred");
      });
  };

  const handleSuspendAssignment = (row) => {
    handleShowModal();
    setuserId(row?.uId);
  };

  const handleDaysChange = (value, category) => {
    const selectedDays = parseInt(value, 10);
    const selectedPrice = calculatePrice(selectedDays, category);

    setSelectedOption((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        days: selectedDays,
        price: selectedPrice,
      },
    }));
  };

  const handlePayNowClick = () => {
    setShowPayModal(true);
    setShowBumpModal(false);
  };

  const toggleShowPackages = (index) => {
    const newIndices = [...showPackagesCard];
    if (newIndices.includes(index)) {
      newIndices.splice(newIndices.indexOf(index), 1);
    } else {
      newIndices.push(index);
    }
    setShowPackagesCard(newIndices);
  };

  const handleActiveBtn = () => {
    if (!loader && !loadMore) {
      setContentPage(1);
      setSearchValue("");
      navigate("/dashboard/assignments-listing/Active");
      setInitialLoad(true);
      setData([]);
    }
  };

  const handlePendingBtn = () => {
    if (!loader && !loadMore) {
      setContentPage(1);
      setSearchValue("");
      navigate("/dashboard/assignments-listing/Pending");
      setInitialLoad(true);
      setData([]);
    }
  };

  const handleSuspendedBtn = () => {
    if (!loader && !loadMore) {
      setContentPage(1);
      setSearchValue("");
      navigate("/dashboard/assignments-listing/Suspended");
      setInitialLoad(true);
      setData([]);
    }
  };

  const [openTooltipIndex, setOpenTooltipIndex] = useState(null);

  const handleTooltipToggle = (category) => {
    if (openTooltipIndex === category) {
      setOpenTooltipIndex(null);
    } else {
      setOpenTooltipIndex(category);
    }
  };

  return (
    <DashboardLayout>
      <div className="page-content my_listing_mobContent">
        <div className="br-container mb-3 myListing_main_hedaing orderSummary_headingSearch">
          <h3 className="MessagesFonts m-0" style={{ color: "black" }}>
            My Listing
          </h3>
          <div className="d-flex br-searchInputAndTitle">
            <form
              onSubmit={(e) => e.preventDefault()}
              className="border d-flex end_round2 br_serachInp w-100"
            >
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={handleSearchClick}
                disabled={loader || loadMore}
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                size="medium"
                sx={{ flex: 1, paddingLeft: 1 }}
                placeholder={"Search by title,id and price"}
                value={searchValue}
                onChange={handleSearchInputChange}
                disabled={!listingHasData}
              />
            </form>
            <select
              className="form-select TitleMenuWidth end_round m-0 br_serachSelect"
              aria-label="Default select example"
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value)}
            >
              <option value="ProjectName">Project Name</option>
              <option value="Id">ListingId</option>
              <option value="AssignmentPrice">Price</option>
              <option value="InteriorSize">Sqft</option>
            </select>
          </div>
        </div>
        <div className="br-Statusbuttons mt-3">
          <button
            className={`btn ${
              propStatusBtn === "Active" ? "br_ActivestatusBtn" : "br_statusBtn"
            }`}
            onClick={handleActiveBtn}
          >
            Active
          </button>
          <button
            className={`btn ${
              propStatusBtn === "Pending"
                ? "br_ActivestatusBtn"
                : "br_statusBtn"
            }`}
            onClick={handlePendingBtn}
          >
            Pending
          </button>
          <button
            className={`btn ${
              propStatusBtn === "Suspended"
                ? "br_ActivestatusBtn"
                : "br_statusBtn"
            }`}
            onClick={handleSuspendedBtn}
          >
            Suspended
          </button>
        </div>

        <br />
        {loader === true ? (
          <center className="mt-5 text-center">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="70"
              visible={loader}
            />
          </center>
        ) : (
          <>
            {listingHasData === false ? (
              <>
                <div>
                  {id === "Active" && (
                    <>
                      <p className="no_data_Text">
                        "Welcome to your Active Listings page! Here, you can add
                        new listings to increase your visibility and connect
                        with potential buyers. Start selling today by clicking
                        on the 'Add Listing' button."
                      </p>
                      <button
                        className="no_data_btn"
                        onClick={() => navigate("/sell")}
                      >
                        Add Listing
                      </button>
                    </>
                  )}
                  {id === "Pending" && (
                    <p className="no_data_Text">
                      "Welcome to your Pending Listings page!{" "}
                      <span className="fw-bold">
                        Currently, you don't have any pending listings.
                      </span>{" "}
                      Stay tuned for updates on new listings awaiting approval.
                      If you have any questions or need assistance, don't
                      hesitate to reach out to our{" "}
                      <NavLink to="/contact-us">support team.</NavLink>"
                    </p>
                  )}
                  {id === "Suspended" && (
                    <p className="no_data_Text">
                      "Welcome to your Suspended Listings page!{" "}
                      <span className="fw-bold">
                        Currently, you don't have any suspended listings.
                      </span>{" "}
                      If you have any questions or need assistance, don't
                      hesitate to reach out to our
                      <NavLink to="/contact-us">support team.</NavLink>"
                    </p>
                  )}
                </div>
                <div className="mt-4">
                  {isMobileView ? (
                    <img
                      style={{ width: "320px" }}
                      src={require(`../../../images/mobile-${id}.png`)}
                      alt="No Listing"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      style={{ width: "90%", minHeight: "350px" }}
                      src={require(`../../../images/${id}.jpg`)}
                      alt="No Listing"
                      loading="lazy"
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                {/* Map data to create cards */}
                {data?.length === 0 ? (
                  <>
                    <div className="no__FoundMain font">
                      <div className="no__Found__img">
                        <img
                          className="not_found_img_mobile"
                          src={require("../../../images/noResultFound.png")}
                          alt="Result not found"
                          loading="lazy"
                        />
                      </div>
                      <div className="no__FoundText font-family-regular not_found_Text_mobile">
                        <p>Result Not Found</p>
                      </div>
                    </div>
                  </>
                ) : (
                  data?.map((listing, index) => (
                    <div
                      key={index}
                      className="border border-1 shadow-sm mb-3 bg-white rounded px-2 py-3"
                    >
                      <div className="d-flex  justify-content-between w-100 px-3 aligncenter text-primary">
                        <span className="fw-bold  d-flex  Mobilefont13">
                          {listing?.projectName}{" "}
                          <Chip
                            size="small"
                            className=" ms-2 font10 myListingStatus_web"
                            label={listing?.status}
                            style={StatusChipStyle[listing?.status] || {}}
                          />
                        </span>
                        <div className="d-flex">
                          <span
                            onClick={() =>
                              navigate(
                                `/dashboard/assignments-listing/gallery/${listing?.id}`
                              )
                            }
                            className=" me-1 btn btn btn-primary Mobilefont13"
                          >
                            Media <MdEdit className="edit_icon_mobile" />
                          </span>
                          <span
                            onClick={() => handleSuspendAssignment(listing)}
                            className="d-inline-flex tabvisibility mobilevisibility mobileresponsive aligncenter  btn text-primary"
                          >
                            Delete{" "}
                            <strong className="ms-2">
                              {" "}
                              <DeleteIcon className="w-100" />
                            </strong>
                          </span>
                          <span
                            className="mobilevisibility_at_990 align-items-center ms-2"
                            onClick={() => handleSuspendAssignment(listing)}
                          >
                            {" "}
                            <DeleteIcon
                              className="text-primary"
                              style={{ fontSize: "28px" }}
                            />
                          </span>
                        </div>
                      </div>
                      <hr className="hr m-3 opacity-75" />
                      <div className="d-flex flex-wrap justify-content-between w-100 px-3">
                        <div className="d-flex aligncenter w-100">
                          <img
                            className="listingImg"
                            src={
                              listing.assignmentMedias[0]?.fileUrl
                                ? `${baseURL}/${listing?.assignmentMedias[0]?.fileUrl}`
                                : require("../../../images/no-image.png")
                            }
                            loading="lazy"
                            alt={listing?.projectName}
                          />
                          <div className="lh-1 ps-3 mt-2  w-100">
                            <div className="tab_justifyContent_between_at_990 w-100">
                              <div className="d-flex justify-content-between w-100">
                                <p className="text-success fw-bold d-flex align-items-center">
                                  {listing?.assignmentPrices?.length > 0 ? (
                                    <>{`$ ${new Intl.NumberFormat().format(
                                      listing?.assignmentPrices[
                                        listing?.assignmentPrices?.length - 1
                                      ].prices
                                    )}`}</>
                                  ) : (
                                    <>{`$ ${new Intl.NumberFormat().format(
                                      listing?.assignmentPrice
                                    )}`}</>
                                  )}{" "}
                                  {id === "Active" && (
                                    <MdEdit
                                      className="ms-1"
                                      style={{
                                        color: "#667085",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleShowPriceModal(listing?.id)
                                      }
                                    />
                                  )}
                                  <Chip
                                    size="small"
                                    className=" ms-2 font10 myListingStatus_mbl"
                                    label={listing?.status}
                                    style={
                                      StatusChipStyle[listing?.status] || {}
                                    }
                                  />
                                </p>
                                <div className="FavAndView_mobNone">
                                  <span className="px-1">
                                    Messages:{" "}
                                    <strong>
                                      {listing?.messages
                                        ? listing?.messages
                                        : 0}
                                    </strong>{" "}
                                  </span>
                                  <span>|</span>
                                  <span className="px-1">
                                    Favourite:{" "}
                                    <strong>
                                      {listing?.favourits
                                        ? listing?.favourits
                                        : 0}
                                    </strong>{" "}
                                  </span>
                                  <span> |</span>
                                  <span className="px-1">
                                    {" "}
                                    Views:{" "}
                                    <strong>
                                      {listing?.views ? listing?.views : 0}
                                    </strong>{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <p className="mobileresponsive font13">
                              {listing.address}
                            </p>
                            <span className="mobileresponsive font13">
                              {listing?.dailyUpdate && (
                                <Chip
                                  size="small"
                                  className=" me-2 font10 mobilevisibility"
                                  label={listing?.dailyUpdate}
                                  style={ChipStyle}
                                />
                              )}
                              {DateFormating(listing?.created)}
                            </span>
                          </div>
                        </div>

                        <div className="mob_favAndViews">
                          <span>
                            Favourite:{" "}
                            <strong>
                              {listing?.favourits ? listing?.favourits : 0}
                            </strong>{" "}
                          </span>
                          <span className="ps-2"> |</span>
                          <span className="px-2">
                            {" "}
                            Views:{" "}
                            <strong>
                              {listing?.views ? listing?.views : 0}
                            </strong>{" "}
                          </span>
                        </div>
                        <div className="mob_listingMessages text-center w-100">
                          <span>
                            Messages:{" "}
                            <strong>
                              {listing?.messages ? listing?.messages : 0}
                            </strong>{" "}
                          </span>
                        </div>
                        <div className="d-flex ms-auto listing_btns_mobileView">
                          <div className="d-flex mt-2 flex-wrap userPkgsListing_btn listing_btns">
                            <button
                              className="btn mobileresponsive ListingcardButton Mobilefont13"
                              onClick={() =>
                                navigate(`/submit-assignment/${listing.uId}`)
                              }
                            >
                              Edit <MdEdit />{" "}
                            </button>
                            <Dropdown className="fs-6 listing_status_btn">
                              <Dropdown.Toggle className="mobileresponsive ListingcardButton">
                                {listing?.propStatus}
                                {loading[listing.uId] && (
                                  <div
                                    className="spinner-border spinner-border-sm ms-2"
                                    role="status"
                                  ></div>
                                )}
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="DropDownMenuFont"
                                style={{ minWidth: "85%" }}
                              >
                                {listing?.propStatus !== "Available" && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      PropertyStatusChange(listing, "Available")
                                    }
                                  >
                                    Available
                                  </Dropdown.Item>
                                )}
                                {listing.propStatus !== "On Hold" && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      PropertyStatusChange(listing, "On Hold")
                                    }
                                  >
                                    On Hold
                                  </Dropdown.Item>
                                )}
                                {listing.propStatus !== "Sold" && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      PropertyStatusChange(listing, "Sold")
                                    }
                                  >
                                    Sold
                                  </Dropdown.Item>
                                )}
                                {listing.propStatus !== "Unavailable" && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      PropertyStatusChange(
                                        listing,
                                        "Unavailable"
                                      )
                                    }
                                  >
                                    Unavailable
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            {propStatusBtn === "Active" && (
                              <button
                                className="btn mobileresponsive  ListingcardButton Mobilefont13"
                                onClick={() => handleShowBumpModal(listing?.id)}
                              >
                                Get More Views{" "}
                                <AddIcon style={{ fontSize: "18px" }} />{" "}
                              </button>
                            )}
                            {propStatusBtn === "Active" &&
                              listing?.assignmentPkgs?.length > 0 && (
                                <button
                                  className="btn mobileresponsive  ListingcardButton Mobilefont13"
                                  onClick={() => toggleShowPackages(index)}
                                >
                                  Show packages
                                  <KeyboardArrowDownIcon
                                    style={{ fontSize: "18px" }}
                                  />{" "}
                                </button>
                              )}
                          </div>
                        </div>

                        {/* ------- Show Selected Packages in Card ------- */}
                        {showPackagesCard.includes(index) && (
                          <div className="d-flex flex-wrap w-100 mt-3 listing_pkgAllCard">
                            {listing?.assignmentPkgs?.map((item, index) => {
                              const targetDate = new Date(item?.endDate);
                              const timeDifference =
                                targetDate.getTime() - currentDate.getTime();
                              const daysDifference = Math.ceil(
                                timeDifference / (1000 * 3600 * 24)
                              );
                              return (
                                <div className="listing_card_pkg" key={index}>
                                  <img
                                    src={require(`../../../Components/Images/${item?.category
                                      .replace(/\b\w/g, (c) => c.toUpperCase())
                                      .replace(/\s/g, "")}Icon.png`)}
                                    alt="ticker-logo"
                                    style={{ height: "40px" }}
                                    loading="lazy"
                                  />
                                  <div className="ms-3">
                                    <p className="listPkg_name d-flex">
                                      {item?.category?.includes("Tags")
                                        ? "Hot Deal Tags" 
                                        : item?.category?.replace(
                                            /([a-z])([A-Z])/g,
                                            "$1 $2"
                                          )}
                                      {/* <Tooltip
                                        key={index}
                                        title={
                                          <div>
                                            <Typography
                                              style={{ fontSize: "12px" }}
                                            >
                                              Get on average of ${item?.price}
                                              impressions/{item?.days} days with
                                              a prime position on our homepage.{" "}
                                              <a
                                                href="https://assignmentsold.ca/tools-to-promote-ad"
                                                style={{
                                                  cursor: "pointer",
                                                  textDecoration: "underline",
                                                }}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={(e) =>
                                                  e.stopPropagation()
                                                }
                                              >
                                                Learn more
                                              </a>
                                            </Typography>
                                          </div>
                                        }
                                        arrow
                                        open={openTooltipIndex === index}
                                        onOpen={() =>
                                          handleTooltipToggle(index)
                                        }
                                        onClose={() =>
                                          handleTooltipToggle(index)
                                        }
                                      >
                                        <span
                                          className="info-icon"
                                          onClick={() =>
                                            handleTooltipToggle(index)
                                          }
                                        >
                                          <InfoOutlinedIcon />
                                        </span>
                                      </Tooltip> */}
                                    </p>
                                    <p className="listPkg_exp">
                                      Expires in {daysDifference} Days
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                )}

                {hasMoreContent ? (
                  <div className="justifycenter aligncenter mb-4">
                    {loadMore ? (
                      <RotatingLines width={50} strokeColor="grey" />
                    ) : (
                      <button
                        className="btn btn-primary font"
                        onClick={HandleMoreContent}
                        disabled={loadMore}
                      >
                        Load more
                      </button>
                    )}
                  </div>
                ) : null}
              </>
            )}
          </>
        )}

        <Modal show={showModal} centered onHide={handleCloseModal}>
          <Modal.Body>
            <div className="py-4">
              <p className="fw-semibold text-center">
                Are you sure want to Suspend this Assignment.
              </p>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn w-25 btn-outline-secondary mx-2"
                  onClick={handleCloseModal}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn w-25 btn-primary mx-2"
                  onClick={handleDeleteAssignment}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* ------- Bumper Offer Modal ------- */}
        <Modal
          show={showBumpModal}
          centered
          onHide={handleCloseBumpModal}
          className="BumpAdd_modal"
        >
          <Modal.Body className="Bump_modal_body">
            <div className="w-100">
              <div className="d-flex justify-content-between">
                <h5 className="BumpAdd_heading">Features to Promote Your Ad</h5>
                <span
                  className="bumpAdd_closeIcon ms-3"
                  onClick={() => setShowBumpModal(false)}
                >
                  <CloseIcon style={{ fontSize: "18px" }} />
                </span>
              </div>
              <hr className={`${isMobileView ? "d-none" : "my-2"} mt-2`} />
              <span className={isMobileView ? "d-none" : "d-flex"}>
                <p className="col-md-6 m-0 ms-1 text-start font fw-bold">
                  Category
                </p>
                <p className="col-md-2 m-0 text-start font fw-bold">Price</p>
                <p className="col-md-4 font m-0 text-start ps-3 fw-bold">
                  Days
                </p>
              </span>
              <hr className={isMobileView ? "d-none" : "my-2"} />

              {/* Render data dynamically for each category */}
              {Object.entries(packageData).map(([category, categoryData]) => {
                const isSelected = selectedPackages.some(
                  (pkg) => pkg.category === category
                );

                return (
                  <div
                    key={category}
                    className={`${
                      isMobileView ? "flex-column" : "d-flex"
                    } mt-4 HighlightselectedPkg ${
                      isSelected ? "selectedpkg_outline" : ""
                    }`}
                  >
                    <div
                      className={` ${
                        isMobileView ? "pkg_categorysMob" : "pkg_categorys"
                      }  `}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={require(`../../../Components/Images/${category}Icon.png`)}
                          alt="ticker-logo"
                          style={{ height: "40px" }}
                          className="ticker_mobn"
                          loading="lazy"
                        />
                        <p className="font ms-3 mb-0 align-center fontdesign-pkg BumpAdd_Text">
                          {category?.includes("Tags")
                            ? "Hot Deal Tags"
                            : category?.replace(/([a-z])([A-Z])/g, "$1 $2")}
                        </p>
                        <Tooltip
                          key={category}
                          sx={{ marginTop: "10px" }}
                          title={
                            <div>
                              <Typography style={{ fontSize: "12px" }}>
                                Get on average of ${categoryData[0].price}{" "}
                                impressions/{categoryData[0].days} days with a
                                prime position on our homepage.{" "}
                                <a
                                  href="https://assignmentsold.ca/tools-to-promote-ad"
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                >
                                  Learn more
                                </a>
                              </Typography>
                            </div>
                          }
                          arrow
                          open={openTooltipIndex === category}
                        >
                          <span
                            className="info-icon"
                            style={{cursor:'pointer'}}
                            onClick={(event) => {
                              handleTooltipToggle(category);
                              event.stopPropagation();
                            }}
                          >
                            <InfoOutlinedIcon />
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                    <div
                      className={` ${
                        isMobileView ? "pkg_categorysMob" : "pkg_daysn"
                      }   `}
                    >
                      <div className="d-flex align-center">
                        <p className="font ms-3 mb-0 fontdesign-pkg BumpAdd_Text">
                          From{" "}
                          <span
                            className="fw-bold"
                            style={{ color: "#4FB020" }}
                          >
                            ${selectedOption[category].price}
                          </span>
                        </p>
                      </div>
                      <div className=" d-flex align-center">
                        <select
                          className="font mb-0 fontdesign-pkg BumpAdd_Text border-0 bg-transparent"
                          aria-label="Default select example"
                          value={selectedOption[category].days}
                          onChange={(e) =>
                            handleDaysChange(e.target.value, category)
                          }
                          disabled={selectedPackages.some(
                            (pkg) => pkg.category === category
                          )}
                        >
                          {categoryData.map((item) => (
                            <option key={item.days} value={item.days}>
                              {item.days} days
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="align-center" style={{cursor: 'pointer'}}>
                        <img
                          src={handleAddorRemovepkgIcon(category)}
                          alt="add-Package"
                          width={"35"}
                          className="Add package"
                          onClick={() => handleAddToPackage(category)}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="w-100 d-flex justify-content-between align-items-center mt-4 btnand_TotalPrice">
                <p className="fontdesign-pkg m-0 pkg_totaln totalPrice_pkg">
                  Total Price:{" "}
                  <span style={{ color: "#4FB020", fontWeight: "700" }}>
                    {" "}
                    $
                    {selectedPackages
                      .reduce((total, pkg) => total + pkg.price, 0)
                      .toFixed(2)}
                  </span>
                </p>
                <button
                  className="payNow_btn"
                  disabled={selectedPackages.length === 0}
                  onClick={handlePayNowClick}
                >
                  Pay now
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ------- Bumper Offer Modal ------- */}
        {showPayModal && (
          <PayModal
            assignmentId={assignmentId}
            showPayModal={showPayModal}
            setShowPayModal={setShowPayModal}
            handleClosePayModal={handleClosePayModal}
            selectedPackages={selectedPackages}
            setSelectedPackages={setSelectedPackages}
            data={data}
            setData={setData}
          />
        )}

        {/* ------- Bumper Offer Modal ------- */}
        {showPriceChangeModal && (
          <PriceChangingModal
            assignmentId={assignmentId}
            showPriceChangeModal={showPriceChangeModal}
            setShowPriceChangeModal={setShowPriceChangeModal}
            handleClosePriceModal={handleClosePriceModal}
            cardsData={data}
            setData={setData}
          />
        )}
      </div>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default MyListing;
