import React, { memo } from "react";

const CotactCard = ({ handleInfoModalClick }) => {
  return (
    <div className="Prop_details_section_box Propert_detailRepresentatorCard">
      <div className="d-flex align-items-start">
        <div>
          <img
           src="https://config.assignmentsold.ca/Attachments/Newhomez-Agent-Profile.jpg"
            alt="Representator Profile"
            loading="lazy"
          />
        </div>
        <div className="ps-3">
          <h5>Umar Khan</h5>
          <p>Sales Representative</p>
          <p>AssignmentSold.ca Inc.</p>
          <p className="fw-bold">(647) 649 0400</p>
        </div>
      </div>
      <button
        className="mt-3 propertyDetails_moreInfo"
        style={{color: '#007BFF',background:'#E2F1FF'}}
        onClick={handleInfoModalClick}
      >
       Connect with Agent
      </button>
      <button
        className="mt-3 propertyDetails_moreInfo PropDetails_gradientBtn"
        onClick={handleInfoModalClick}
      >
       Book this Agent
      </button>
    </div>
  );
};

export default memo(CotactCard);
