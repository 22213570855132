import React, { useState } from 'react'
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TwoBarChart from './TwoBarChart';

const CommunityMediumChart = () => {

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  const tabLabels = ['For Sale', 'For Rent'];


  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const data = [
    { "year": "2019-06", "condo": 589555, "AllProperties": 671600 },
    { "year": "2019-10", "condo": 300560, "AllProperties": 235100 },
    { "year": "2020-02", "condo": 207800, "AllProperties": 478400 },
    { "year": "2020-06", "condo": 278078, "AllProperties": 365200 },
    { "year": "2020-10", "condo": 458890, "AllProperties": 625300 },
    { "year": "2021-02", "condo": 472390, "AllProperties": 658300 },
    { "year": "2021-06", "condo": 305490, "AllProperties": 478650 },
    { "year": "2021-10", "condo": 153490, "AllProperties": 478250 },
    { "year": "2022-02", "condo": 153490, "AllProperties": 689550 },
    { "year": "2022-06", "condo": 347890, "AllProperties": 485685 },
  ];

  return (
    <div className='w-100'>
      <h2 className='property_Details_heading'>Community Medium Price</h2>
      <p className='PropertyDetails_Buy_reason PropertyDetails_Mbl_f14'>Toronto / Waterfront Communities C8 / Condo Apt
        The Community Popularity Score is a measure of online activity, like listing views, in this community.
      </p>
      <Box className='mt-2'>
        <AppBar position="static" sx={{
          boxShadow: "none",
        }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            sx={{
              backgroundColor: "#fff",
            }}
            className='Property_details_tabs'
            elevation={0}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {tabLabels?.map((tab, index) => (
              <Tab
                key={index}
                label={`${tab}`}
                sx={{
                  border: "1px solid #CCCCCC",
                  fontWeight: "700",
                  padding: "15px 30px"
                }}
              />
            ))}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} className='PropertyDetail_tabPanel'>
          <TwoBarChart data={data} />
        </TabPanel>
        <TabPanel value={value} index={1} className='PropertyDetail_tabPanel'>
          <TwoBarChart data={data} />
        </TabPanel>
      </Box>
    </div>
  )
}

export default CommunityMediumChart
