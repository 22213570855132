import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import axios from "axios";
import baseURL from "../../../config";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";

const PaymentDetails = ({
  assignmentId,
  setShowPayModal,
  selectedPackages,
  setSelectedPackages,
  showPayLaterBtn,
  isLoading,
  setIsLoading,
  handlePayLater,
  setData,
  data,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userProfileDataSlice);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const totalAmount = selectedPackages
    ?.reduce((total, pkg) => total + pkg.price, 0)
    ?.toFixed(2);

  useEffect(() => {
    setName(userData[0]?.fullName);
    setEmail(userData[0]?.email);
  }, [userData]);

  const handleSubmit = async (event) => {
    event.preventDefault();


    if (!jwt_token) {
      navigate("/login", {
        state: { returnUrl: "dashboard/assignments-listing/Active" },
      });
      return;
    }

    if (!stripe || !PaymentElement) {
      // Stripe.js hasn't yet loaded.
      return;
    }
    setIsLoading(true);
    // const { token, error } = await stripe.createToken(cardNumberElement);
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: "if_required",
    });
    if (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
      console.log(error);
    } else {
      AddPaymentsAndPackages(paymentIntent?.id);
    }
  };

  const AddPaymentsAndPackages = async (paymentId) => {
    const packageDescriptions = selectedPackages
      .map((pkg) => packageNamesetting(pkg.category))
      .join(", ");
    const formData = {
      AssignmentPkgs: AssignmentPkgsData(selectedPackages),
      PaymentRequest: {
        InvoiceId: "",
        Amount: totalAmount,
        Email: email,
        CustomerName: name,
        DescriptionPayment: `Person has charged for the following packages: ${packageDescriptions}`,
        TokenId: paymentId,
      },
    };

    try {
      const response = await axios.post(
        `${baseURL}/api/stripe/receivepayment`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data?.result === 1) {
        updateAssignmentPackages();
        setIsLoading(false);
        setShowPayModal(false);
        setSelectedPackages([]);
      }
    } catch (error) {
      toast.error("Error making the API call:", error);
      setIsLoading(false);
    }
  };

  //To update real time state when payment received and pkg done
  const updateAssignmentPackages = () => {
    const updatedDataState = data.map((item) => {
      if (item?.id === assignmentId) {
        return {
          ...item,
          assignmentPkgs: selectedPackages.map((pkg) => ({
            ...pkg,
            category: packageNamesetting(pkg.category),
            endDate: dayjs()
              .add(pkg.days, "day")
              .format("YYYY-MM-DDTHH:mm:ss.SSS"),
            isExpired: false,
            isPaymentReceived: true,
          })),
        };
      }
      return item;
    });

    setData(updatedDataState);
  };

  //To set the name for sending to backend
  const packageNamesetting = (category) => {
    switch (category) {
      case "TopHomePageGallery":
        return "Top Home Page Gallery";
      case "HomePageTicker":
        return "Home Page Ticker";
      case "HighlightAdd":
        return "Highlight Add";
      case "Tags":
        return "Tags";
      default:
        return category;
    }
  };

  const AssignmentPkgsData = (selectedPackages) => {
    return selectedPackages?.map((pkg) => ({
      AssignmentId: assignmentId ? assignmentId : 0,
      Category: packageNamesetting(pkg.category),
      Price: pkg.price,
      Days: pkg.days,
      IsExpired: false,
    }));
  };

  return (
    <div className="w-100">
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between">
          <h5 className="BumpAdd_heading">Credit Card Payment</h5>
          <span
            className="bumpAdd_closeIcon ms-3"
            onClick={() => {
              setShowPayModal(false);
              setIsLoading(false);
            }}
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </span>
        </div>

        <p
          className="mb-0 fw-bold"
          style={{ color: "#02549E", fontFamily: "Montserrat" }}
        >
          Powered by Stripe
        </p>
        <div className="d-flex flex-wrap Pay_textAll_field">
          <div className="Pay_text_field">
            <label className="cardPay_head m-0">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="PayModal_StripeInput"
              placeholder="Name"
            />
          </div>

          <div className="Pay_text_field">
            <label className="cardPay_head m-0">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="PayModal_StripeInput"
              placeholder="Email Address"
            />
          </div>

          <div className="w-100">
            <PaymentElement />
          </div>

          {/* 
          <div className="Pay_text_field mb-3">
            <label
              className="cardPay_head m-0"
              style={{ fontFamily: "sans-serif", fontSize: "medium" }}
            >
              Card Number
            </label>
            <CardNumberElement
              className="PayModal_StripeInput"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#4A4A4A",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </div>

          <div className="Pay_text_field mb-3">
            <label
              className="cardPay_head m-0"
              style={{ fontFamily: "sans-serif", fontSize: "medium" }}
            >
              CVC
            </label>
            <CardCvcElement
              className="PayModal_StripeInput"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#4A4A4A",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </div>

          <div className="Pay_text_field mb-3">
            <label
              className="cardPay_head m-0"
              style={{ fontFamily: "sans-serif", fontSize: "medium" }}
            >
              Expiration Date
            </label>
            <CardExpiryElement
              className="PayModal_StripeInput"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#4A4A4A",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </div> */}

          {errorMessage && (
            <div className="text-danger w-100 text-center">{errorMessage}</div>
          )}
        </div>

        <div className="d-flex justify-content-center align-items-center mt-4">
          {showPayLaterBtn && (
            <button
              type="button"
              disabled={isLoading}
              onClick={() => {
                // setIsLoading(true);
                handlePayLater();
              }}
              className={`me-3 payNow_btn creditPay_btn ${
                isLoading ? "PayLater_cursorNone" : "cursor-pointer"
              }`}
            >
              Pay Later
            </button>
          )}
          {isLoading ? (
            <RotatingLines width={50} strokeColor="grey" />
          ) : (
            <button
              type="submit"
              disabled={isLoading}
              className={`payNow_btn creditPay_btn ${
                isLoading ? "PayLater_cursorNone" : "cursor-pointer"
              }`}
            >
              {`Pay $ ${totalAmount} now`}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default PaymentDetails;
