import React, { useState, memo } from "react";
import { PropertyData } from "../PropertData";
import ListingCarousel from "./ListingComparable/Carousel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { FeaturedListingIcon } from "../../../SvgIcons/Icons";

const FeaturedListing = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  const OPTIONS = { slidesToScroll: "auto" };
  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion Property_simpleAccordion PropertyDetails_MobileAccordion"
      sx={{ background: "#fff" }}
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <FeaturedListingIcon />}
          <h2 className="property_Details_heading">Featured Listing</h2>
          {!isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
              Explore our top picks—exclusive listings that stand out for their
              exceptional value and appeal.
            </p>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isMobileView && (
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
            Explore our top picks—exclusive listings that stand out for their
            exceptional value and appeal.
          </p>
        )}
        <div className="mt-3">
          {PropertyData?.featuredListing?.length > 0 ? (
            <ListingCarousel
              slides={PropertyData?.featuredListing}
              options={OPTIONS}
            />
          ) : (
            <div>No data available.</div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(FeaturedListing);
