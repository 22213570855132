import React from "react";
import { Box, Modal } from "@mui/material";
import { SuccesfullySubmitedIcon } from "../../../../SvgIcons/Icons";

const ListingSoldModal = ({ open, handleClose }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="Prop_detail_infoModal Prop_detailsValueModal WatchNeighbourHood_inputs">
          <div className="d-flex flex-column align-items-center">
            <div className="propertyDetail_homeValue_icon">
              <SuccesfullySubmitedIcon />
            </div>
            <h6 style={{ fontSize: "20px" }} className="text-center mt-3">
              Successfully Updated
            </h6>
            <p style={{ fontSize: "16px" }} className="mt-1 text-center">
              You will receive an email notification when the listing is sold.
            </p>

            <div className="mt-2">
                <button
                onClick={handleClose}
                >
                  Okay
                </button>
              </div>
          </div>
      </Box>
    </Modal>
  );
};

export default ListingSoldModal;
