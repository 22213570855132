import React, { useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ProfileIcon, TicWithkBorderIcon } from "../../../SvgIcons/Icons";

const AboutRealtor = ({ data, isMobileView }) => {
  const DummyData = [
    {
      educationData: [
        "Senior Specialization",
        "Multifamily",
        "Rentals",
        "Investment Properties",
        "First Time Home Buyes",
        "Fixer Uppers",
      ],
      designation: [
        "Buyer Representation",
        "Seller Representative",
        "Real Estate Specialist",
      ],
      servicesoffered: [
        "Senior Specialization",
        "Multifamily",
        "Rentals",
        "Investment Properties",
        "First Time Home Buyes",
        "Fixer Uppers",
      ],
      specialties: [
        "Property Buying",
        "Market Evaluation",
        "Legal Expertise",
        "Property Selling",
        "Negotiation",
        "Networking",
        "Property management",
        "Marketing Properties",
        "Client Representation",
        "Investment Advice",
        "Market Knowledge",
        "Relocation Services",
      ],
      homeType: ["House", "Townhouse", "Condo", "Other"],
      otherExperience: [
        "Luxury Homes",
        "Military Families",
        "New Construction",
        "Senior Communities",
      ],
    },
  ];

  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex flex-column gap-2" : ""}>
          <div className="d-flex">
            {isMobileView && <ProfileIcon />}
            <h2 className="property_Details_heading">Umar Khan</h2>
          </div>

          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
            As an Associate Broker of Real Property, Umar Khan understands that
            buyers and sellers are preparing for change in their daily way of
            life. You need an agent who understands yours. A home can help you
            navigate the daily tasks and errands, child rearing, entertaining,
            relaxation & family time. Our home forms our lifestyle. Sometimes
            those changes are a result of good things in our lives and sometimes
            they are sad. Either way, it's a stressful world to be in. We are
            here to help you navigate your life when it comes to where you are
            going to live. Let's talk about your lifestyle changes.
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="mt-4">
          <h4 className="realtor_about_subHeading">Education</h4>
          <div className="d-flex flex-wrap gap-2">
            {DummyData[0]?.educationData?.map((item, i) => {
              return (
                <div className="realtor_profile_eduInfo py-2" key={i}>
                  <p className="property_amenities color_Dark_Gray d-flex alig-items-start">
                    <span>
                      <TicWithkBorderIcon />
                    </span>
                    <span className="ps-2 pt-1 PropertyDetails_Mbl_f14">
                      {item}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-3">
          <h4 className="realtor_about_subHeading">Services Offered</h4>
          <div className="d-flex flex-wrap gap-2">
            {DummyData[0]?.servicesoffered?.map((item, i) => {
              return (
                <div className="realtor_profile_eduInfo py-2" key={i}>
                  <p className="property_amenities color_Dark_Gray d-flex alig-items-start">
                    <span>
                      <TicWithkBorderIcon />
                    </span>
                    <span className="ps-2 pt-1 PropertyDetails_Mbl_f14">
                      {item}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-3">
          <h4 className="realtor_about_subHeading">Designation</h4>
          <div className="d-flex flex-wrap gap-2">
            {DummyData[0]?.designation?.map((item, i) => {
              return (
                <div className="realtor_profile_eduInfo py-2" key={i}>
                  <p className="property_amenities color_Dark_Gray d-flex alig-items-start">
                    <span>
                      <TicWithkBorderIcon />
                    </span>
                    <span className="ps-2 pt-1 PropertyDetails_Mbl_f14">
                      {item}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-3">
          <h4 className="realtor_about_subHeading">Specialties</h4>
          <div className="d-flex flex-wrap gap-2">
            {DummyData[0]?.specialties?.map((item, i) => {
              return (
                <div className="realtor_profile_eduInfo py-2" key={i}>
                  <p className="property_amenities color_Dark_Gray d-flex alig-items-start">
                    <span>
                      <TicWithkBorderIcon />
                    </span>
                    <span className="ps-2 pt-1 PropertyDetails_Mbl_f14">
                      {item}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-3">
          <h4 className="realtor_about_subHeading">Home Type</h4>
          <div className="d-flex flex-wrap gap-2">
            {DummyData[0]?.homeType?.map((item, i) => {
              return (
                <div className="realtor_profile_eduInfo py-2" key={i}>
                  <p className="property_amenities color_Dark_Gray d-flex alig-items-start">
                    <span>
                      <TicWithkBorderIcon />
                    </span>
                    <span className="ps-2 pt-1 PropertyDetails_Mbl_f14">
                      {item}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-3">
          <h4 className="realtor_about_subHeading">Other Experience</h4>
          <div className="d-flex flex-wrap gap-2">
            {DummyData[0]?.otherExperience?.map((item, i) => {
              return (
                <div className="realtor_profile_eduInfo py-2" key={i}>
                  <p className="property_amenities color_Dark_Gray d-flex alig-items-start">
                    <span>
                      <TicWithkBorderIcon />
                    </span>
                    <span className="ps-2 pt-1 PropertyDetails_Mbl_f14">
                      {item}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(AboutRealtor);
