import React from "react";
import "../../App.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../Footer/Footer";

const faqData = [
  { question: "What is Assignment Sold?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { question: "What are assignment Sales?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { question: "Are Assignment Sales Legal?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { question: "Who is Assignor?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { question: "Who is Assignee?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { question: "What are benefits of the Assignor?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { question: "Will either the assignor or assignee's lawyer Service Required?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." },
  { question: "What is the process of Assignment?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget." }
];

export default function Faqs() {
  return (
    <div>
      <div className="text-center my-4">
        <h5 className="weightfont">FAQs</h5>
      </div>

      <div className="accor d-flex flex-column align-items-center px-3">
      
        {faqData.map((faq, index) => (
          <Accordion
            key={index}
            style={{
              border: "1px solid #CCCCCC",
              borderRadius: "4px",
              boxShadow: "4px 4px 4px 4px #FFFFFF",
              marginBottom: "10px", // Space between accordions
              width:'100%',
              maxWidth:'700px'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}a-content`}
              id={`panel${index + 1}a-header`}
            >
              <Typography sx={{ fontFamily: "Montserrat" }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontFamily: "Montserrat" }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
}
