import React, { useEffect } from "react";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./ListingComparable/Carousel/CarouselButon";
import useEmblaCarousel from "embla-carousel-react";
import baseURL from "../../../config";

const GalleryCarousel = ({ data, projectName, activeSlide }) => {
  const OPTIONS = {};
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(activeSlide ? activeSlide : 0, true);
    }
  }, [emblaApi, activeSlide]);

  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {data?.map((item, index) => (
            <div className="gallery_embla__slide" key={index}>
              <img
                src={`${baseURL}/${item?.fileUrl}`}
                alt={projectName}
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      </div>
    </section>
  );
};

export default GalleryCarousel;
