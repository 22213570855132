import React, { useState, memo } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ValuesAndTrendIcon } from "../../../SvgIcons/Icons";
import SimpleLineChart from "../../PropertyDetails/SubComponents/ValuesAndTrends/components/SimpleLineChart";

const ProductionOverTime = ({ isMobileView }) => {
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  };

  const tabLabels = [
    "For Sale",
    "Recently Sold",
    "For Rent",
    "Recently Rented",
  ];

  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const data = [
    { year: "2016", price: 4800, sales: 600 },
    { year: "2017", price: 3000, sales: 100 },
    { year: "2018", price: 2000, sales: 400 },
    { year: "2019", price: 2780, sales: 200 },
    { year: "2020", price: 1890, sales: 300 },
    { year: "2021", price: 2390, sales: 300 },
    { year: "2022", price: 3490, sales: 50 },
  ];

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div
          className={
            isMobileView ? "d-flex flex-column align-items-center" : ""
          }
        >
          <div className="d-flex align-items-center gap-2">
            {isMobileView && <ValuesAndTrendIcon />}
            <h2 className="property_Details_heading">Production Trends Over Time</h2>
          </div>
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
            Analysis of long-term production trends.
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Box className="mt-3">
          <AppBar
            position="static"
            sx={{
              boxShadow: "none",
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              textColor="inherit"
              sx={{
                backgroundColor: "#fff",
              }}
              className="Property_details_tabs"
              elevation={0}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {tabLabels?.map((tab, index) => (
                <Tab
                  key={index}
                  label={`${tab}`}
                  sx={{
                    border: "1px solid #CCCCCC",
                    fontWeight: "700",
                    padding: "15px 30px",
                  }}
                />
              ))}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} className="PropertyDetail_tabPanel">
            <SimpleLineChart data={data} />
          </TabPanel>
          <TabPanel value={value} index={1} className="PropertyDetail_tabPanel">
            <SimpleLineChart data={data} />
          </TabPanel>
          <TabPanel value={value} index={2} className="PropertyDetail_tabPanel">
            <SimpleLineChart data={data} />
          </TabPanel>
          <TabPanel value={value} index={3} className="PropertyDetail_tabPanel">
            <SimpleLineChart data={data} />
          </TabPanel>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(ProductionOverTime);
