import React, { memo } from "react";
import { PropertyCommuteIcon } from "../../../SvgIcons/Icons";

const FindNearby = ({ latitude, longitude, handleCommuteModalClick }) => {
  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank");
  };

  return (
    <div className="Prop_details_section_box Propert_detailRepresentatorCard Prop_nearByCard">
      <img
        src="/images/mapDirection.png"
        alt="MapLocationImg"
        loading="lazy"
        onClick={openGoogleMaps}
      />
      <button
        className="propertyDetails_moreInfo mt-3 d-flex justify-content-between"
        onClick={handleCommuteModalClick}
      >
        Add a commute
        <PropertyCommuteIcon color={"#fff"} />
      </button>
    </div>
  );
};

export default memo(FindNearby);
