import './InformationForSeller.css'
import SellingAdvantageCard from './SellingAdvantageCard';
import SellingWithUsAdvCard from './SellingWithUsAdvCard';
import { SellingAdvantageData, SellingWithUsAdvData } from './SellingAdvntageData';
import EstimateValueImg from '../../../images/estimateValue.png'
import NeighbourHoodImg from '../../../images/neighbourHoodValue.png'
import StayOrGoImg from '../../../images/SellerStayOrGo.png'
import SellingHomeImg from '../../../images/SellingHome.png'

const InformationForSeller = () => {


    return (
        <div>
            <div className="seller_inf_cardContainer">
                <div className="container text-center w-100">
                    <h3 className='seller_inf_mainHeading'>Discover a more Rewarding approach to selling your property.</h3>
                    <p className='seller_inf_des'>"When you promote your private sale through AssignmentSold.com, you cover all bases for maximum exposure!"</p>
                </div>

                <div className='container mt-4 d-flex flex-wrap selling_adv_cardCointainer'>
                    {SellingAdvantageData?.map((item, i) => {
                        return (
                            <SellingAdvantageCard data={item} key={i} cardIndex={i} />
                        )
                    })}
                </div>
            </div>


            <div id='valueHouse' className='container text-center py-5'>
                <h2 className='infSeller_ValueHeading'>"What is the Value of <br />My Property?"</h2>
                <h6 className='infSeller_ValueQuestion'>How does your home compare?</h6>
                <p className='infSeller_ValueDescription'>Know what your property’s worth, and how it compares with the market.</p>
            </div>

            <div className='d-flex align-items-center justify-content-center tools_grey_container'>
                <div className='toolTicker_container'>
                    <div className='toolAdtext_container'>
                        <h2 className='tools_ad_heading'>Discover the estimated value of your home</h2>
                        <p className='SellerInf_DesSelling'>Knowing your property value will help you keep up to date on market trends.</p>
                    </div>
                    <div className='toolAdImage_container'>
                        <img src={EstimateValueImg} alt='Estimated Value' loading="lazy"/>
                    </div>
                </div>
            </div>

            <div className='d-flex align-items-center justify-content-center'>
                <div className='toolTicker_container selling_value_mobile_view'>
                    <div className='toolAdImage_container'>
                        <img src={NeighbourHoodImg} alt='NeighborHood' loading="lazy"/>
                    </div>
                    <div className='toolAdtext_container'>
                        <h2 className='tools_ad_heading'>Learn more about your neighborhood values</h2>
                        <p className='SellerInf_DesSelling'>Understand how your value compares to that of your neighborhood..</p>
                    </div>
                </div>
            </div>

            <div className='d-flex align-items-center justify-content-center tools_grey_container'>
                <div className='toolTicker_container'>
                    <div className='toolAdtext_container'>
                        <h2 className='tools_ad_heading'>Should we stay or should we go?</h2>
                        <p className='SellerInf_DesSelling'>Our Free Instant Home Estimation can give you an idea of how much you can get for your home.</p>
                    </div>
                    <div className='toolAdImage_container'>
                        <img src={StayOrGoImg} alt='StayOrGo?'  loading="lazy"/>
                    </div>
                </div>
            </div>

            <div className='d-flex align-items-center justify-content-center'>
                <div className='toolTicker_container selling_value_mobile_view'>
                    <div className='toolAdImage_container'>
                        <img src={SellingHomeImg} alt='Selling home' loading="lazy" />
                    </div>
                    <div className='toolAdtext_container'>
                        <h2 className='tools_ad_heading'>Get started on selling your home</h2>
                        <p className='SellerInf_DesSelling'>An Instant Home Estimate is a great start to a conversation with one of our pricing experts, who can help you dig even deeper with a more hands-on approach.</p>
                    </div>
                </div>
            </div>


            <div className="seller_inf_cardContainer">
                <div id='whatsInclude' className="container text-center w-100">
                    <h3 className='seller_inf_mainHeading'>Discover What's Included When Selling Your Property With Us.</h3>
                    <p className='seller_inf_des'>Unlock the Full Spectrum of Services and Support for a Seamless Selling Experience</p>
                </div>

                <div className='container mt-4 d-flex flex-wrap selling_adv_cardCointainer'>
                    {SellingWithUsAdvData?.map((item, i) => {
                        return (
                            <SellingWithUsAdvCard data={item} key={i} />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}


export default InformationForSeller;