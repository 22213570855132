import { useState, useEffect } from "react";
import DashboardLayout from "../../../Dashboard";
import { toast, ToastContainer } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import { IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "../UserListings.css";
import baseURL from "../../../../config";
import UserListingCard from "./Cards/UserListingCards";
import Cookies from "universal-cookie";
import axios from "axios";
import UserSaveSearchesCard from "./Cards/UserSaveSearchesCard";
// import UserConversationCard from "./Cards/UsersConversationCard";
import { Helmet } from "react-helmet";

const UserAllListing = () => {
  const { search } = useLocation();
  const { id } = useParams();
  const params = new URLSearchParams(search);
  const ListingOwnerName = params.get("name");
  const ListingOwnerRole = params.get("role");
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const navigate = useNavigate();
  const [searchBy, setSearchBy] = useState("ProjectName");
  const [btnStatus, setBtnStatus] = useState("");
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCards, setSelectedCards] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState();
  const [hasMoreContent, setHasMoreContent] = useState(false);
  const [contentPage, setContentPage] = useState(1);
  const [loadMore, setLoadMore] = useState(false);

  const fetchUserListingData = async () => {
    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };
    const data = {
      Status: btnStatus,
      Term: searchValue,
      TermTitle: searchBy, //ProjectName,Id,AssignmentPrice,InteriorSize
      page: contentPage,
      UserId: id,
    };

    axios
      .post(`${baseURL}/api/Admin-User/GetUserAssignment`, data, { headers })
      .then((response) => {
        if (response) {
          const ListingData = response?.data?.data?.assignments;
          const totalPages = Math.ceil(response?.data?.data?.count / 20)
          if (contentPage === 1) {
            setData(ListingData);
          } else {
            setData((prevData) => [...prevData, ...ListingData]);
          }
          if (contentPage < totalPages) {
            setHasMoreContent(true);
            setHasMoreContent(true);
          } else{
            setHasMoreContent(false);
          }
          setLoadMore(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error("error Fetching data");
        setLoader(false);
      });
  };

  //Fetch UserFavourites

  const fetchUserFavourites = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const response = await axios.get(
        `${baseURL}/api/Admin-User/GetUserFavourite?UserId=${id}`,
        { headers }
      );
      if (response) {
        const responseData = response?.data?.data;
        const assignmentArrays = responseData.map(
          (item) => item.assignment || []
        );
        const allFavorites = [].concat(...assignmentArrays);
        setData(allFavorites);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      toast.error(error);
    }
  };

  //Fetch SaveSearches

  const fetchSaveSearches = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const response = await axios.get(
        `${baseURL}/api/Admin-User/GetUserSearches?UserId=${id}`,
        { headers }
      );
      if (response) {
        const searchesData = response?.data?.data;
        setData(searchesData);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      toast.error(error);
    }
  };


  useEffect(() => {
    if (ListingOwnerRole === "Buyer") {
      setBtnStatus("Favorite");
    } else {
      setBtnStatus("Active");
    }
    // eslint-disable-next-line
  }, []);

  const HandleMoreContent = () => {
    setLoadMore(true);
    setContentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    if (!jwt_token) {
      navigate("/login");
      return;
    }
    if (contentPage === 1) {
      setLoader(true);
      setHasMoreContent(false);
    }
    if (
      btnStatus === "Active" ||
      btnStatus === "Pending" ||
      btnStatus === "Suspended"
    ) {
      fetchUserListingData();
    } else if (btnStatus === "Favorite") {
      fetchUserFavourites();
    } else if (btnStatus === "Searches") {
      fetchSaveSearches();
    }
    // eslint-disable-next-line
  }, [btnStatus, contentPage]);

  //Change PropertyStatus

  const handleChangePropStatusBtn = (status) => {
    if (!jwt_token) {
      navigate("/login");
    }
    setBtnDisabled(true);
    setContentPage(1);
    ChangePropertyStauts(status);
  };
  const ChangePropertyStauts = async (status) => {
    try {
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };

      const payload = selectedCards.map((Uid) => ({
        Uid: Uid,
        status: status,
      }));

      const response = await axios.post(
        `${baseURL}/api/Admin-User/UpdateListing`,
        payload,
        { headers }
      );

      if (response) {
        setSelectedCards([]);
        setContentPage(1);
        setBtnDisabled(false);
        if (contentPage === 1) {
          fetchUserListingData();
        }
      }
    } catch (error) {
      setLoader(false);
      setSelectedCards([]);
      setBtnDisabled(false);
      toast.error(error);
    }
  };

  const handleChangeStatus = (status) => {
    if (!loader  && !loadMore) {
      setContentPage(1)
      setBtnStatus(status);
    }
  };

  //Searchbar work

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchClick = () => {
    setContentPage(1);
    if (contentPage === 1) {
      setLoader(true);
      setHasMoreContent(false);
      fetchUserListingData();
    }
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>AssignmentSold.ca</title>
      </Helmet>

      <div className="row"></div>
      <section className="page-content br-page-content fav_marginTop">
        <div className="br-container mb-3">
          <div className="headingtitlelister">
            <h3 className="Single_allListingHeading m-0 d-flex align-items-center">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/dashboard/users")}
              >
                User
              </span>
              <ArrowForwardIosRoundedIcon className="mx-1 singleUserBreadCrumbs_arrow" />
              <span className="singleUserBreadCrumbs_slash">/</span>
              <span>{ListingOwnerName}</span>
            </h3>
          </div>
          <div className="br-filterconntainer">
            <div className="br-datebtn">
              {(btnStatus === "Pending" || btnStatus === "Suspended") && (
                <button
                  className="UserSingleListing_statusBtn"
                  disabled={selectedCards.length === 0 || btnDisabled}
                  onClick={() => handleChangePropStatusBtn("Active")}
                >
                  Active
                </button>
              )}
              {(btnStatus === "Active" || btnStatus === "Suspended") && (
                <button
                  className="UserSingleListing_statusBtn"
                  disabled={selectedCards.length === 0 || btnDisabled}
                  onClick={() => handleChangePropStatusBtn("Pending")}
                >
                  Pending
                </button>
              )}
              {(btnStatus === "Pending" || btnStatus === "Active") && (
                <button
                  className="UserSingleListing_statusBtn"
                  disabled={selectedCards.length === 0 || btnDisabled}
                  onClick={() => handleChangePropStatusBtn("Suspended")}
                >
                  Suspended
                </button>
              )}
            </div>

            {(btnStatus === "Active" || btnStatus === "Pending" || btnStatus === "Suspended") && (
            <div className="d-flex br-searchInputAndTitle">
              <form
                onSubmit={(e) => e.preventDefault()}
                className="border d-flex end_round2 br_serachInp w-100"
              >
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={handleSearchClick}
                  disabled={loader || loadMore}
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  size="medium"
                  sx={{ flex: 1, paddingLeft: 1 }}
                  placeholder={"Search by title,id and price"}
                  value={searchValue}
                  onChange={handleSearchInputChange}
                />
              </form>
              <select
                className="form-select TitleMenuWidth end_round m-0 br_serachSelect"
                aria-label="Default select example"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
              >
                <option value="ListingId">ListingId</option>
                <option value="ProjectName">Project Name</option>
                <option value="Price">Price</option>
              </select>
            </div>
            )}
          </div>
        </div>

        {/* ======== Status Filter Section ======== */}

        <div className="br-Statusbuttons single_userListingStatusBtn">
          {ListingOwnerRole !== "Buyer" && (
            <>
              <button
                className={`btn ${
                  btnStatus === "Active" ? "br_ActivestatusBtn" : "br_statusBtn"
                }`}
                onClick={() => handleChangeStatus("Active")}
              >
                Active
              </button>
              <button
                className={`btn ${
                  btnStatus === "Pending"
                    ? "br_ActivestatusBtn"
                    : "br_statusBtn"
                }`}
                onClick={() => handleChangeStatus("Pending")}
              >
                Pending
              </button>
              <button
                className={`btn ${
                  btnStatus === "Suspended"
                    ? "br_ActivestatusBtn"
                    : "br_statusBtn"
                }`}
                onClick={() => handleChangeStatus("Suspended")}
              >
                Suspended
              </button>
            </>
          )}
          <button
            className={`btn ${
              btnStatus === "Favorite" ? "br_ActivestatusBtn" : "br_statusBtn"
            }`}
            onClick={() => handleChangeStatus("Favorite")}
          >
            Favorite
          </button>
          <button
            className={`btn ${
              btnStatus === "Searches" ? "br_ActivestatusBtn" : "br_statusBtn"
            }`}
            onClick={() => handleChangeStatus("Searches")}
          >
            Searches
          </button>
          {/* <button className={`btn ${btnStatus === 'Conversations' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => setBtnStatus('Conversations')}>
                        Conversations
                    </button> */}
        </div>

        {/* ======== Card Section ======== */}
        {loader ? (
          <center className="text-center mt-4">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="70"
              visible={loader}
            />
          </center>
        ) : data?.length === 0 ? (
          <>
            <div className="no__FoundMain font">
              <div className="no__Found__img">
                <img
                  className="not_found_img_mobile"
                  src={require("../../../../images/noResultFound.png")}
                  alt="Result not found"
                  loading="lazy"
                />
              </div>
              <div className="no__FoundText font-family-regular not_found_Text_mobile">
                <p>No RecordFound</p>
              </div>
            </div>
          </>
        ) : (
          <>
            {(btnStatus === "Active" ||
              btnStatus === "Pending" ||
              btnStatus === "Suspended" ||
              btnStatus === "Favorite") && (
              <div className="d-flex flex-wrap ActiveUserListing_card mt-4">
                {data?.map((item, index) => {
                  return (
                    <UserListingCard
                      key={index}
                      selectedCards={selectedCards}
                      setSelectedCards={setSelectedCards}
                      ListingOwnerName={ListingOwnerName}
                      item={item}
                    />
                  );
                })}
              </div>
            )}
            {btnStatus === "Searches" && (
              <div className="mt-4 d-flex flex-column user_savesearchesCardContainer">
                {data?.map((item, index) => {
                  return <UserSaveSearchesCard key={index} item={item} />;
                })}
              </div>
            )}
            {/* {(btnStatus === 'Conversations') && (
                                <div className="mt-4 d-flex flex-column user_savesearchesCardContainer">
                                    {filteredCardData?.map((item, index) => {
                                        return (
                                            <UserConversationCard key={index} ListingOwnerName={ListingOwnerName} item={item} />
                                        )
                                    })}
                                </div>
                            )} */}
          </>
        )}

        {hasMoreContent ? (
          <div className="justifycenter aligncenter my-4">
            {loadMore ? (
              <RotatingLines width={50} strokeColor="grey" />
            ) : (
              <button
                className="btn btn-primary font"
                onClick={HandleMoreContent}
                disabled={loadMore}
              >
                Load more
              </button>
            )}
          </div>
        ) : null}
      </section>

      <ToastContainer />
    </DashboardLayout>
  );
};

export default UserAllListing;
