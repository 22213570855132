import React from "react";
import {
  AvgSalePriceIcon,
  DealCloseIcon,
  DollarIcon,
  FacebookIcon,
  GoogleIcon,
  InstaIcon,
  OfficialTick,
  TikTokIcon,
  TwitterIcon,
  YearSaleCountIcon,
  YoutubeIcon,
} from "../../../SvgIcons/Icons";
import ReviewsRating from "../../PropertyDetails/SubComponents/PropertyNotes/RatingReviews";
import HeaderSlider from "./HeaderSlider";

const RealtorHeader = () => {
  return (
    <div className="row">
      <div className="col-lg-7 col-12">
        <div className="row d-flex">
          <div className="col-sm-5 col-12 realtor_profile_Image">
            <img
              src="https://imagescdn.homes.com/i2/qgM7CPO4MUx1QtQLxzboEFezPiK7WQENOqp1PI84TZQ/118/david-heine.jpg?p=1"
              alt="Realtor-Profile"
              loading="lazy"
            />
          </div>

          <div className="col-sm-7 col-12 realtor_profile_info">
     <div className='d-flex align-items-center gap-2'>
     <h2 className="mb-1">Umar Khan</h2>
     <OfficialTick />
     </div>
            <p>
              <strong className="pro_realtor_profile color_Primary_Natural_blue">
                Pro
              </strong>{" "}
              Realtor in Canada (100k Deals closed)
            </p>
            <p>Agent License #475125223</p>
            <p>Property.ca Inc., Brokerage</p>
            <p>Khan Team</p>
            <p>Mississauga Board</p>
            <p>
              <strong>100%</strong> Response Rate
            </p>
            <p className="color_Dark_Gray">
              <strong className="color_Accent_Black">17</strong> Years of
              experience
            </p>
            <p>English Speaking</p>
            <div className="mt-2 d-flex align-items-center gap-2">
              <span className="mt-1">
                <GoogleIcon />
              </span>
              <ReviewsRating
                isReadOnly={true}
                fillColor={"#222222"}
                emptyColor={"#222222"}
                value={2}
              />
              <p className="mb-0 mt-1 color_Dark_Gray">
                <strong className="color_Accent_Black">4.0</strong>(335 reviews)
              </p>
            </div>
            <div className="mt-3 d-flex align-items-center gap-2">
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstaIcon />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.tiktok.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TikTokIcon />
              </a>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeIcon />
              </a>
            </div>
          </div>
        </div>

        <div className="row mt-3 mx-0 w-100">
          <div className="col-md-4 col-6 py-2 ps-0 pe-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div className="propertyDetail_homeValue_icon">
                <DealCloseIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                  1245
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Total Deals Closed
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 py-2 px-2 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon PropertyDetails_Mbl_f14"
                style={{
                  borderColor: "#DFF4FF",
                  background: "var(--Light-blue, #BDDFFF)",
                }}
              >
                <YearSaleCountIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                  1245
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Annual Sales
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 py-2 pe-0 ps-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{ borderColor: "#F4ECFF", background: "#E4D2FF" }}
              >
                <DollarIcon color={"#653EBE"} />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                  $145K - $1.4M
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Price Range</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 py-2 pe-1 ps-0 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#FFFCDF",
                  background: "var(--Light-yellow, #FFF7A0)",
                }}
              >
                <DollarIcon color={"#713F12"} />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                  $37.3M
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Total Sales</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 py-2 px-2 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div className="propertyDetail_homeValue_icon">
                <AvgSalePriceIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                  $351.4k
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Average Price
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 py-2 pe-0 ps-1 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#DFF4FF",
                  background: "var(--Light-blue, #BDDFFF)",
                }}
              >
                <AvgSalePriceIcon color={'#0253A0'}/>
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                  $351.4K
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Median Price</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-5 col-12 d_none_990">
        <HeaderSlider slides={Array.from(Array(5).keys())} option={{}}/>
      </div>
    </div>
  );
};

export default RealtorHeader;
