import React, { useEffect, useState, memo } from "react";
import { PropertyData } from "../PropertData";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  GymIcon,
  PrivatePoolIcon,
  KitchenIcon,
  ElectricityIcon,
  BBQIcon,
  VentilationIcon,
  FirePlaceIcon,
  BalconyIcon,
  AirConditionIcon,
  AmenitiesIcon,
} from "../../../SvgIcons/Icons";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Amenities = ({ isMobileView }) => {
  const [showMore, setShowMore] = useState(true);
  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  const toggleExpand = () => {
    setShowMore(false);
  };

  const amenities = PropertyData?.Amenities || [];
  const amenitiesToShow = !showMore ? amenities : amenities.slice(0, 6);

  const renderIcons = {
    Gym: <GymIcon />,
    "Private Pool": <PrivatePoolIcon />,
    Kitchen: <KitchenIcon />,
    "Air Condition": <AirConditionIcon />,
    Balcony: <BalconyIcon />,
    Ventilation: <VentilationIcon />,
    BBQ: <BBQIcon />,
    Electricity: <ElectricityIcon />,
    "Fire Place": <FirePlaceIcon />,
  };

  return (
    <div className="">
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className="Property_details_Web_Accordion PropertyDetails_MobileAccordion Accordion_withBlueBackground py-2"
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon className="Property_minusIcon" />
            ) : (
              <AddIcon className="Property_plusIcon" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={isMobileView ? "d-flex align-items-center gap-2" : ""}
          >
            {isMobileView && <AmenitiesIcon />}
            <h2 className="property_Details_heading">Amenities</h2>
            {!isMobileView && (
              <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 m-0 mt-1">
                Enjoy unmatched comfort with our premium amenities.
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 mt-1">
              Enjoy unmatched comfort with our premium amenities.
            </p>
          )}
          <div className="row w-100 mt-3 mbl_marginTop0">
            {amenitiesToShow?.map((item, i) => {
              return (
                <div className="col-md-4 col-12 py-2" key={i}>
                  <p className="property_amenities">
                    {renderIcons[item]}
                    <span className="ps-3 PropertyDetails_Mbl_f14">{item}</span>
                  </p>
                </div>
              );
            })}
          </div>
          {amenities?.length > 6 && showMore && (
            <button onClick={toggleExpand} className="Detail_showMoreBtn">
              Show all
            </button>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(Amenities);
