import React, { useState, useEffect, memo } from "react";
import { PropertyData } from "../PropertData";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { RoomsIcon } from "../../../SvgIcons/Icons";

const Rooms = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <RemoveIcon className="Property_minusWebGreyIcon" />
          ) : (
            <AddIcon className="Property_plusWebGreyIcon" />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <RoomsIcon />}
          <h2 className="property_Details_heading">Rooms</h2>
          {!isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
              Explore the perfect spaces with detailed room layouts and
              dimensions to find your ideal home.
            </p>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isMobileView && (
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
            Explore the perfect spaces with detailed room layouts and dimensions
            to find your ideal home.
          </p>
        )}
        <div
          className="w-100 mt-3 PropDetails_HideScrollBar position-relative"
          style={{ overflowX: "auto" }}
        >
          <table className="Property_floorPlan_table Property_taxHistory_table w-100">
            <thead className="w-100">
              <tr className="w-100">
                <th style={{ width: "16%" }}>Lavel</th>
                <th style={{ width: "18%" }}>Name</th>
                <th style={{ width: "20/%" }}>Size</th>
                <th style={{ width: "46%" }}>Features</th>
              </tr>
            </thead>
            {PropertyData?.rooms?.map((item, index) => (
              <tbody key={index}>
                <tr
                  style={{
                    borderBottom:
                      index === PropertyData.rooms.length - 1
                        ? "none"
                        : "1px solid #EAECF0",
                  }}
                >
                  <td
                    style={{
                      paddingBottom:
                        index === PropertyData.rooms.length - 1 ? "0" : "10px",
                    }}
                  >
                    <span className="property_floorPlan_price fw-medium">
                      {item?.lavel}
                    </span>
                  </td>
                  <td
                    style={{
                      paddingBottom:
                        index === PropertyData.rooms.length - 1 ? "0" : "10px",
                    }}
                  >
                    <span className="property_floorPlan_price fw-medium">
                      {item?.name}
                    </span>
                  </td>
                  <td
                    style={{
                      paddingBottom:
                        index === PropertyData.rooms.length - 1 ? "0" : "10px",
                    }}
                  >
                    <span className="property_floorPlan_price fw-medium">{`${item?.size} ft`}</span>
                  </td>
                  <td
                    style={{
                      paddingBottom:
                        index === PropertyData.rooms.length - 1 ? "0" : "10px",
                    }}
                  >
                    <span className="property_floorPlan_price fw-medium">
                      {item?.features}
                    </span>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(Rooms);
