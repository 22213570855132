import { createSlice } from "@reduxjs/toolkit";

const MonthlyMortgagePaymentSlice = createSlice({
  name: "MonthlyMortgagePaymentSlice",
  initialState: "",
  reducers: {
    addMonthlyPayment: (state, action) => {
      return action.payload;
    },
    removeMonthlyPayment: () => {
      return "";
    },
  
  },
});
export const { addMonthlyPayment, removeMonthlyPayment} = MonthlyMortgagePaymentSlice.actions;
export default MonthlyMortgagePaymentSlice.reducer;