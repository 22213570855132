import React from "react";
import "../../App.css";
import "@fontsource/montserrat";
import axios from "axios";
import { useState, useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import baseURL from "../../config";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./FindDeals.css";
import CardMain from "../../HomePage/SubComponents/MapandListing/CardMain";
import HelmetLayout from "../../Utils/HelmetLayout/Helmet";
import CardMainSkeleton from "../../HomePage/SubComponents/MapandListing/CardMainSkeleton";
import Footer from "../Footer/Footer";

const  FindDeals = () =>{
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [btnStatus, setBtnStatus] = useState("justReached");
  const [isLoading, setIsLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMoreContent, setHasMoreContent] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState("");
  const [contentPage, setContentPage] = useState(1);

  // For Fetching the data From the api
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/data/deals?term=${id}&page=${contentPage}`
      );
      if (response?.data?.result === 1) {
        const newData = response?.data?.data?.assignments || [];
        setData((prevData) => [...prevData, ...newData]);
        setTotalPageCount(Math.ceil(response?.data?.data?.count / 20));
        setIsLoading(false);
        setLoadMore(false);
      } else {
        toast.error("There is problem in fetching data");
        setIsLoading(false);
        setLoadMore(false);
      }
    } catch (error) {
      toast.error("There is problem in fetching data");
      setIsLoading(false);
      setLoadMore(false);
    }
  };

  useEffect(() => {
    if (
      !id ||
      (id !== "justReached" &&
        id !== "hotDeals" &&
        id !== "fastMoving" &&
        id !== "priceChange" &&
        id !== "HighlightAdd")
    ) {
      navigate(`/find-deals/justReached`);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBtnStatus(id);
    if (contentPage === 1) {
      setIsLoading(true);
    }
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id, contentPage]);

  const handleDealStatusBtn = (deal) => {
    if (id !== deal) {
      setIsLoading(true);
      setData([]);
      setContentPage(1);
      navigate(`/find-deals/${deal}`);
    }
  };

  const HandleMoreContent = () => {
    setLoadMore(true);
    setContentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    if (contentPage === totalPageCount || totalPageCount === 0) {
      setHasMoreContent(false);
    } else {
      setHasMoreContent(true);
    }
  }, [contentPage, totalPageCount]);

  return (
    <div>
      <HelmetLayout
        pageName={`find-deals/${id}`}
        canonicalLink={`find-deals/${id}`}
      />
      <div className="container">
        <h1 className="font pt-4 pb-1">
          <strong style={{ fontFamily: "sans-serif", fontSize: "larger" }}>
            {" "}
            Find Best Deals Here.
          </strong>
        </h1>

        <div className="br-Statusbuttons single_userListingStatusBtn">
          <button
            className={`btn ${
              btnStatus === "justReached"
                ? "br_ActivestatusBtn"
                : "br_statusBtn"
            }`}
            disabled={isLoading && id !== "justReached"}
            onClick={() => handleDealStatusBtn("justReached")}
          >
            Just reached
          </button>
          <button
            className={`btn ${
              btnStatus === "hotDeals" ? "br_ActivestatusBtn" : "br_statusBtn"
            }`}
            disabled={isLoading && id !== "hotDeals"}
            onClick={() => handleDealStatusBtn("hotDeals")}
          >
            Hot deals
          </button>
          <button
            className={`btn ${
              btnStatus === "fastMoving" ? "br_ActivestatusBtn" : "br_statusBtn"
            }`}
            disabled={isLoading && id !== "fastMoving"}
            onClick={() => handleDealStatusBtn("fastMoving")}
          >
            Fast moving
          </button>
          <button
            className={`btn ${
              btnStatus === "priceChange"
                ? "br_ActivestatusBtn"
                : "br_statusBtn"
            }`}
            disabled={isLoading && id !== "priceChange"}
            onClick={() => handleDealStatusBtn("priceChange")}
          >
            Price change
          </button>
          <button
            className={`btn ${
              btnStatus === "HighlightAdd"
                ? "br_ActivestatusBtn"
                : "br_statusBtn"
            }`}
            disabled={isLoading && id !== "HighlightAdd"}
            onClick={() => handleDealStatusBtn("HighlightAdd")}
          >
            Highlighted Ad
          </button>
        </div>

        <div>
          {id === "justReached" && (
            <p className="find_dealsTagline">
              Discover the latest properties to hit the market with our 'Just
              Reached'
            </p>
          )}
          {id === "hotDeals" && (
            <p className="find_dealsTagline">
              Uncover exceptional value with our 'Hot Deals' section, where
              affordability meets quality.
            </p>
          )}
          {id === "fastMoving" && (
            <p className="find_dealsTagline">
              Navigate through our 'Fast Moving' properties, a curated selection
              of homes that are attracting attention and moving quickly.
            </p>
          )}
          {id === "priceChange" && (
            <p className="find_dealsTagline">
              Stay ahead with our 'Price Change' section, featuring properties
              with recently adjusted prices.
            </p>
          )}
          {id === "HighlightAdd" && (
            <p className="find_dealsTagline">
              Get noticed instantly with our 'Highlighted Ad'.
            </p>
          )}
        </div>

        {isLoading ? (
          <div className="d-flex flex-wrap ActiveUserListing_card mt-4 mb-4">
            {Array.from(new Array(8))?.map((_, index) => (
              <CardMainSkeleton key={index} />
            ))}
          </div>
        ) : data?.length === 0 ? (
          <>
            <div className="no__FoundMain font">
              <div className="no__Found__img">
                <img
                  className="not_found_img_mobile"
                  src={require("../../images/noResultFound.png")}
                  alt="Result not found"
                  loading="lazy"
                />
              </div>
              <div className="no__FoundText font-family-regular not_found_Text_mobile">
                <p>No RecordFound</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex flex-wrap ActiveUserListing_card mt-4 mb-4">
              {data?.map((item, index) => {
                return (
                  <CardMain
                    key={index}
                    item={item}
                  />
                );
              })}
            </div>
            {hasMoreContent ? (
              <div className="justifycenter aligncenter mb-4">
                {loadMore ? (
                  <RotatingLines width={50} strokeColor="grey" />
                ) : (
                  <button
                    className="btn btn-primary font"
                    onClick={HandleMoreContent}
                    disabled={loadMore}
                  >
                    Load more
                  </button>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>

      <div className="mt-5">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
}



export default FindDeals