import './MyOrderSummary.css'
import DashboardLayout from "../../Dashboard";
import { toast, ToastContainer } from 'react-toastify';
import OrderSummaryCard from "./MyOrderSummaryCard";
import { RotatingLines } from "react-loader-spinner";
import { IconButton, InputBase } from "@mui/material";
import Cookies from 'universal-cookie';
import baseURL from '../../../config';
import axios from 'axios';
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';



const MyOrder = () => {

    const { id } = useParams()
    const userDataSlice = useSelector(state => state.userProfileDataSlice[0])
    const cookies = new Cookies();
    const jwt_token = cookies.get('jwt_authorization');
    const navigate = useNavigate()
    const [filteredCardData, setFilteredCardData] = useState([]);
    const [data, setData] = useState('')
    const [btnStatus, setBtnStatus] = useState('');
    const [loader, setLoader] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [searchBy, setSearchBy] = useState('ProjectName')

    if (id !== 'Active' && id !== 'Pending' && id !== 'PreviousOrder') {
        navigate('/dashboard/order-summary/Active');
    }

    function FetchData(isExpired, isPaymentReceived) {
        setLoader(true)
        const data = {
            UserId: userDataSlice?.id,
            IsExpired: isExpired, //for (Active:False),(Pending:true)
            IsPaymentReceived: isPaymentReceived
        };

        const jsonData = JSON.stringify(data);

        const headers = {
            Authorization: `Bearer ${jwt_token}`,
            'Content-Type': 'application/json',
        };

        axios
            .post(`${baseURL}/api/User-Data/Myorders`, jsonData, { headers })
            .then((response) => {
                const allOrders = response?.data?.data;
                setData(allOrders);
                setFilteredCardData(allOrders);
                setLoader(false);
            })
            .catch((error) => {
                toast.error(error);
                setLoader(false);
            });

    }

    useEffect(() => {
        let isExpired;
        let isPaymentReceived;
        if (!jwt_token) {
            navigate('/login');
            return;
        }
        if (id === 'Active') {
            isExpired = false;
            isPaymentReceived = true
            setBtnStatus('Active')
        }
        if (id === 'Pending') {
            isExpired = true;
            isPaymentReceived = false
            setBtnStatus('Pending')
        }
        if (id === 'PreviousOrder') {
            isExpired = true;
            isPaymentReceived = true
            setBtnStatus('PreviousOrder')
        }
        if (userDataSlice?.id) {
            FetchData(isExpired, isPaymentReceived)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDataSlice, id])

    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const debouncedSearch = debounce((query) => {
        setLoader(false)
        if (query.length > 0) {
            const filteredData = data?.filter((item) => {
                switch (searchBy) {
                    case 'ListingId':
                        return item?.assignmentId?.toString() === query;
                    case 'ProjectName':
                        return item?.projectName?.toLowerCase().includes(query);
                    case 'Price':
                        return item?.price?.toString() === query;
                    default:
                        return true;
                }
            });
            setFilteredCardData(filteredData);
        } else {
            setFilteredCardData(data);
        }
    }, 500);

    const handleSearchInputChange = (event) => {
        setLoader(true)
        setSearchValue(event.target.value)
        debouncedSearch(event.target.value);
    };

    const HandleActiveBtn = () => {
        navigate('/dashboard/order-summary/Active')
    }

    const HandlePendingBtn = () => {
        navigate('/dashboard/order-summary/Pending')
    }

    const HandleOldDataBtn = () => {
        navigate('/dashboard/order-summary/PreviousOrder')
    }

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);

    useEffect(() => {
        function handleResize() {
            setIsMobileView(window.innerWidth <= 800);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <DashboardLayout>
            <ToastContainer />
            <div className="row br-mobile4remMarginTop"></div>
            <section className='page-content br-page-content'>
                <div className="br-container mb-3 orderSummary_headingSearch">
                    <h3 className="MessagesFonts m-0" style={{ color: "black" }}>
                        My Order
                    </h3>
                    <div className="d-flex br-searchInputAndTitle">
                        <form
                            onSubmit={(e) => e.preventDefault()}
                            className="border d-flex end_round2 br_serachInp"
                        >
                            <IconButton
                                type="button"
                                sx={{ p: "10px" }}
                                aria-label="search"
                            >
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                size="medium"
                                sx={{ flex: 1, paddingLeft: 1 }}
                                placeholder={"Search by title,id and price"}
                                value={searchValue}
                                disabled={data?.length === 0}
                                onChange={handleSearchInputChange}
                            />
                        </form>
                        <select
                            className="form-select TitleMenuWidth end_round m-0 br_serachSelect"
                            aria-label="Default select example"
                            value={searchBy}
                            onChange={(e) => setSearchBy(e.target.value)}
                        >

                            <option value="ListingId">ListingId</option>
                            <option value="ProjectName">Project Name</option>
                            <option value="Price">Price</option>
                        </select>
                    </div>
                </div>

                {/* ======== Status Filter Section ======== */}

                <div className="br-Statusbuttons">
                    <button className={`btn ${btnStatus === 'Active' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={HandleActiveBtn}>
                        Active
                    </button>
                    <button className={`btn ${btnStatus === 'Pending' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={HandlePendingBtn}>
                        Pending
                    </button>
                    <button className={`btn ${btnStatus === 'PreviousOrder' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={HandleOldDataBtn}>
                        Previous Orders
                    </button>
                </div>

                {/* ======== Card Section ======== */}
                {loader ?
                    <center className="text-center mt-4">
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="70"
                            visible={loader}
                        />
                    </center>
                    :
                    <>
                        {data?.length === 0 ?
                            <>
                                <div className='mt-4'>
                                    {id === 'Active' && (
                                        <p className='no_data_Text'>"Welcome to your Orders page! Currently, you don't have any orders yet. Let's boost your listings to attract more buyers and sell faster. Below are the reference images to guide you through purchasing promotional <a href='https://assignmentsold.ca/tools-to-promote-ad' target='_blank' rel="noopener noreferrer">tools for your ads</a>."</p>
                                    )}
                                    {id === 'Pending' && (
                                        <p className='no_data_Text'>"Welcome to your Orders page! <span className='fw-bold'>Currently, you don't have any pending orders awaiting approval.</span>  If you have made a purchase that is pending approval, please feel free to contact our <NavLink to='/contact-us'>support team</NavLink> for assistance"</p>
                                    )}
                                    {id === 'PreviousOrder' && (
                                        <p className='no_data_Text'>"<span className='fw-bold'>Currently, you don't have any suspended listings.</span> If you believe there may be an issue with your order, please reach out to our <NavLink to='/contact-us'>support team</NavLink> for further assistance."</p>
                                    )}
                                </div>
                                <div className='mt-4'>
                                    {isMobileView ?
                                        <img style={{ width: '320px' }} src={require(`../../../images/mobile-no-order-${id}.png`)} alt="No Order" loading="lazy"/>
                                        :
                                        <img style={{ width: '90%', minHeight: '350px' }} src={require(`../../../images/no-order-${id}.jpg`)} alt="No Order" loading="lazy"/>
                                    }
                                </div>
                            </>
                            :
                            <>
                                {filteredCardData?.length === 0 ?
                                    <>
                                        <div className="no__FoundMain font">
                                            <div className="no__Found__img">
                                                <img className='not_found_img_mobile' src={require("../../../images/noResultFound.png")} alt="Result not found" loading="lazy"/>
                                            </div>
                                            <div className="no__FoundText font-family-regular not_found_Text_mobile">
                                                <p>No Record Found</p>
                                            </div>

                                        </div>
                                    </>
                                    :
                                    <div className="br-CardContainer orderSummary_card mt-4">
                                        {filteredCardData?.map((item, index) => {
                                            return (
                                                <OrderSummaryCard key={index} data={item} status={btnStatus} />
                                            )
                                        })}
                                    </div>
                                }
                            </>
                        }
                    </>
                }
            </section>
        </DashboardLayout>
    )
}

export default MyOrder;