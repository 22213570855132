import React, { useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  FacebookColorIcon,
  InstaColorIcon,
  LinkedInColorIcon,
  OfficialTick,
  SnapChatColorIcon,
  SocialPresenceIcon,
  TiktokColorIcon,
  TwitterIcon,
  YoutubeColorIcon,
} from "../../../SvgIcons/Icons";

const SocialPresence = ({ data, isMobileView }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex flex-column gap-2" : ""}>
          <div className="d-flex align-items-center gap-2">
            {isMobileView && <SocialPresenceIcon />}
            <h2 className="property_Details_heading">
              Umar Khan Social Media Presence
            </h2>
          </div>

          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
            Achieved 20k successful deals through social media platforms,10k
            deals closed through Facebook, 5k deals closed through Instagram, 2k
            deals closed through You tube, 500 deals closed through TikTok, 200
            deals closed through Twitter, 100 deals closed through Snapchat, 50
            deals closed through LinkedIn, leveraging digital marketing
            strategies to engage and convert leads into clients.
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="row mx-0 mt-3 w-100 ">
          <div className="col-md-3 col-6 py-2 ps-0 pe-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  border: "none",
                  background: "#E2F1FF",
                }}
              >
                <FacebookColorIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-center gap-1 market_activityCardHeading PropertyDetails_Mbl_f18">
                  3.5M
                  <OfficialTick />
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Followers</p>

                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  23k
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Average Post Engagement
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 py-2 px-2 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  border: "none",
                  background: "#F4F4F5",
                }}
              >
                <InstaColorIcon color={"#713F12"} size={24} />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-center gap-1 market_activityCardHeading PropertyDetails_Mbl_f18">
                  3.5M
                  <OfficialTick />
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Followers</p>

                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  23k
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Average Post Engagement
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 py-2 px-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  border: "none",
                  background: "#F4F4F5",
                }}
              >
                <YoutubeColorIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-center gap-1 market_activityCardHeading PropertyDetails_Mbl_f18">
                  3.5M
                  <OfficialTick />
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Followers</p>

                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  23k
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Average Post Engagement
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 py-2 pe-0 ps-2 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex ">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  border: "none",
                  background: "#F4F4F5",
                }}
              >
                <TiktokColorIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-center gap-1 market_activityCardHeading PropertyDetails_Mbl_f18">
                  3.5M
                  <OfficialTick />
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Followers</p>

                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  23k
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Average Post Engagement
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-6 py-2 ps-0 pe-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  border: "none",
                  background: "#F4F4F5",
                }}
              >
                <TwitterIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-center gap-1 market_activityCardHeading PropertyDetails_Mbl_f18">
                  3.5M
                  <OfficialTick />
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Followers</p>

                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  23k
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Average Post Engagement
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-6 py-2 px-2 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex ">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  border: "none",
                  background: "#F4F4F5",
                }}
              >
                <SnapChatColorIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-center gap-1 market_activityCardHeading PropertyDetails_Mbl_f18">
                  3.5M
                  <OfficialTick />
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Followers</p>

                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  23k
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Average Post Engagement
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 py-2 ps-1 pe-0 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex ">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  border: "none",
                  background: "#E2F1FF",
                }}
              >
                <LinkedInColorIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-center gap-1 market_activityCardHeading PropertyDetails_Mbl_f18">
                  3.5M
                  <OfficialTick />
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Followers</p>

                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  23k
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Average Post Engagement
                </p>
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(SocialPresence);
