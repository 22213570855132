import React, { useState, memo, useEffect } from "react";
import { AeroplaneIcon, BusIcon, TransitIcon } from "../../../SvgIcons/Icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Transits = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion Property_simpleAccordion PropertyDetails_MobileAccordion py-2"
      sx={{ background: "#ffff" }}
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <RemoveIcon className="Property_minusWebGreyIcon" />
          ) : (
            <AddIcon className="Property_plusWebGreyIcon" />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <TransitIcon color={"#282828"} size={24} />}
          <h2 className="property_Details_heading">Transits</h2>
          {!isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
              Discover properties with high transit scores for easy, efficient
              commutes and seamless access to public transportation.
            </p>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isMobileView && (
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
            Discover properties with high transit scores for easy, efficient
            commutes and seamless access to public transportation.
          </p>
        )}

        <div className="row mx-0 mt-3 w-100 ">
          <div className="col-md-3 col-6 py-2 ps-0 pe-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#ECFDF3",
                  background: "var(--Light-green, #D1FADF",
                }}
              >
                <AeroplaneIcon color={"#008733"} size={24} />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  Airport
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Duboce And Noe
                </p>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  <strong>15 min</strong> drive
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 py-2 px-2 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#FFFCDF",
                  background: "var(--Light-yellow, #FFF7A0)",
                }}
              >
                <TransitIcon color={"#713F12"} size={24} />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  Subway
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Duboce And Noe
                </p>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  <strong>15 min</strong> drive
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 py-2 px-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#DFF4FF",
                  background: "var(--Light-blue, #BDDFFF)",
                }}
              >
                <BusIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  Bus
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Duboce And Noe
                </p>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  <strong>15 min</strong> drive
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 py-2 pe-0 ps-2 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex ">
            <div className="transitCard_value w-100">
            <img
                src="/images/AeroplaneCompanyLogo.png"
                alt="BerlinInternational"
                loading="lazy"
              />
            </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  Airport
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Duboce And Noe
                </p>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  <strong>15 min</strong> drive
                </p>
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(Transits);
