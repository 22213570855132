import React, { useEffect, useState } from "react";
import "./ComingSoon.css";
import { NavLink, useNavigate } from "react-router-dom";
import { FormControl, TextField } from "@mui/material";
import { PlayVideoIcon } from "../../SvgIcons/Icons";
import baseURL from "../../config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { ThreeCircles } from "react-loader-spinner";

const ComingSoon = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscribers, setSubscriber] = useState("");
  const [messages, setMessages] = useState([]);
  const [animationKey, setAnimationKey] = useState(0);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(newEmail)) {
      setEmailError(false);
    }
  };

  const calculateTimeLeft = () => {
    const targetDate = new Date("2024-12-31T23:59:59");
    const now = new Date();
    const difference = targetDate - now;

    if (difference > 0) {
      setTimeLeft({
        days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
          2,
          "0"
        ),
        hours: String(
          Math.floor((difference / (1000 * 60 * 60)) % 24)
        ).padStart(2, "0"),
        minutes: String(Math.floor((difference / (1000 * 60)) % 60)).padStart(
          2,
          "0"
        ),
        seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
      });
    } else {
      setTimeLeft({ days: "00", hours: "00", minutes: "00", seconds: "00" });
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return "Unavailable";
    }
  };

  const getUserLocation = async (ip) => {
    try {
      const response = await axios.get(`https://ipwhois.app/json/${ip}`);
      const { city, country } = response.data;
      return `${city} ${country}`;
    } catch (error) {
      console.error("Error fetching location data:", error);
      return "Unavailable";
    }
  };

  const addNotify = async () => {
    if (email.length === 0) {
      setEmailError(true);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      return;
    }

    setIsLoading(true);

    try {
      const ipAddress = await getIpAddress();
      const address = await getUserLocation(ipAddress);

      const data = {
        ID: 0,
        Email: email,
        IpAddress: ipAddress,
        Address: address,
      };

      const response = await axios.post(
        `${baseURL}/api/data/AddNewsLetter`,
        data
      );

      if (response?.data?.result === 1) {
        localStorage.setItem("subscriberData", JSON.stringify(data));
        setEmail("");
        navigate("/user-information");
      }
    } catch (e) {
      toast.error(e.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/data/GetNewsLetter`);
      if (response?.data?.result === 1) {
        setMessages(response?.data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    if (messages) {
      let index = 0;
      const interval = setInterval(() => {
        setSubscriber(messages[index]);
        setAnimationKey((prev) => prev + 1);
        index = (index + 1) % messages.length;
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [messages]);

  return (
    <div className="coming_soonMain position-relative z-1">
      <div className="comingSoon_container px-4 pt-4 pb-5">
        <div>
          <NavLink to="/">
            <img
              className="comingSoonLogo"
              src={require("../Images/Web_logo.png")}
              alt="AssignmentSold Logo"
              loading="lazy"
            />
          </NavLink>
        </div>

        <div className="row mt-3 align-items-center">
          <div className="col-md-7">
            {/* timer */}

            <div className="d-flex mt-4">
              <div className="coming_soonTimer">
                <h6>{timeLeft.days}</h6>
                <p>Days</p>
              </div>
              <div className="coming_soonTimer">
                <h6>{timeLeft.hours}</h6>
                <p>Hours</p>
              </div>
              <div className="coming_soonTimer">
                <h6>{timeLeft.minutes}</h6>
                <p>Minutes</p>
              </div>
              <div className="coming_soonTimer border-end-0">
                <h6>{timeLeft.seconds}</h6>
                <p>Seconds</p>
              </div>
            </div>

            <div>
              <h2 className="mt-4">Launching Soon!</h2>
              <h1 className="mt-3">
                Building Canada's largest marketplace for off‑market listings.
              </h1>
              <h3 className="mt-4">
                AssignmentSold is a powerful digital platform created for the
                forward-thinking real estate agents, brokerages, and investors
                providing access to Off-Market and Assignment Properties.
              </h3>
              <h3 className="mt-3">
                Bundled with the Latest Technology Innovations and Premium
                Marketing, AssignmentSold is going to be a Game Changer.
              </h3>
              <h3 className="mt-3">Sign up Now to Get first Access</h3>
            </div>

            <div className="mt-4 w-100 d-flex gap-2">
              <FormControl
                style={{ maxWidth: "380px", width: "calc(100% - 115px)" }}
              >
                <TextField
                  sx={{
                    borderRadius: "4px",
                  }}
                  size="small"
                  className="w-100 bg-light"
                  value={email}
                  onChange={handleEmailChange}
                  name="brokerageName"
                  id="brokerageNameF"
                  label={<span className="font">Enter your email</span>}
                  variant="outlined"
                />
                {emailError ? (
                  <span style={{ color: "red" }}>Enter valid email</span>
                ) : null}
              </FormControl>
              <button
                className="propertyDetails_moreInfo py-2 d-flex align-items-center justify-content-center PropDetails_gradientBtn"
                style={{ height: "42px", width: "110px" }}
                onClick={addNotify}
                disabled={isLoading}
              >
                {isLoading ? (
                  <ThreeCircles
                    visible={true}
                    height="30"
                    width="30"
                    color="#fff"
                    ariaLabel="three-circles-loading"
                  />
                ) : (
                  `Notify Me`
                )}
              </button>
            </div>

            <div className="d-flex align-items-center comingSoon_subscriber mt-4 pb-4">
              {messages?.map((item, i) => {
                const colors = [
                  { color: "#008733", background: "#D1FADF" },
                  { color: "#713F12", background: "#FFF7A0" },
                  { color: "#BC300A", background: "#FFDFC3" },
                  { color: "#E4D2FF", background: "#653EBE" },
                  { color: "#D1FADF", background: "#008733" },
                ];

                const { color, background } = colors[i % colors.length];
                return (
                  <div
                    key={i}
                    className="subscriberCount"
                    style={{
                      color: color,
                      backgroundColor: background,
                    }}
                  >
                    {item?.charAt(0)}
                  </div>
                );
              })}
              <div className="subscriberCount">
                <span>{500 + messages?.length}</span>
              </div>
              <div>
                <p className="ps-2 m-0">Subscribed</p>
              </div>
            </div>

            <h6 className="coming_soon_policy">
              By clicking <strong>Notify Me</strong> you acknowledge that you
              agree to being contacted by AssignmentSold and you have read and
              agree to the
              <a
                href="https://assignmentsold.ca/terms-and-conditions"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Services
              </a>{" "}
              &{" "}
              <a
                href="https://assignmentsold.ca/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </h6>
          </div>
          <div className="col-md-5">
            <div className="comingSoon_thumbnail">
              <img
                src="https://config.assignmentsold.ca/Attachments/Assignment/00168611-Screenshot%202024-10-22%20174649.png"
                alt="video-thumbnail"
                className="w-100"
                loading="lazy"
              />
              <button onClick={handleOpenModal}>
                <PlayVideoIcon />
              </button>
            </div>
          </div>
        </div>

        {subscribers?.length > 0 && (
          <div key={animationKey} className="subscriber_message">
            {`${subscribers} Subscribed`}
          </div>
        )}
      </div>

      <div className="position-absolute bottom-0 w-100" style={{ zIndex: -1 }}>
        <img
          src="/images/ComingSoonFooter.png"
          alt="comingSoon-footer"
          loading="lazy"
          width={"100%"}
        />
      </div>

      {/* Video Modal Work */}
      {isModalOpen && (
        <div className="video-modal">
          <div className="video-modal-overlay" onClick={handleCloseModal}></div>
          <div className="video-modal-content">
            <video
              className="video-player"
              controls
              controlsList="nodownload"
              playsInline
              autoPlay
              src="https://vapi.homes.com/video/play/4i9q3d?source=42&amp;size=1080"
              poster="http://cf.cdn.vid.ly/4i9q3d/poster.jpg"
            ></video>
            <button
              className="comingSoon_closebutton"
              onClick={handleCloseModal}
            >
              ✖
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ComingSoon;
