import React from "react";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import DownloadIcon from "@mui/icons-material/Download";
import { Chip } from "@mui/material";
import baseURL from "../../../config";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CottageIcon from "@mui/icons-material/Cottage";
import Carousel from "react-bootstrap/Carousel";
// import BalconyIcon from "../../../images/BalconyIcon.svg";
// import LockerIcon from '../../../images/LockerIcon.svg'
import { MdOutlineBalcony } from "react-icons/md";
import { GiLockers } from "react-icons/gi";
import EscalatorIcon from "@mui/icons-material/Escalator";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import "./ChildModalComparison.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BathtubIcon from "@mui/icons-material/Bathtub";
import BedIcon from "@mui/icons-material/Bed";
import { FaVectorSquare } from "react-icons/fa";
import { FaParking } from "react-icons/fa";
import { BsSunFill } from "react-icons/bs";
import GarageIcon from "@mui/icons-material/Garage";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { saveAs } from "file-saver";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function ChildModalComparison({ n }) {
  const navigate = useNavigate();
  const nowAssignmentPrice =
    n?.assignmentPrices?.length > 0
      ? n?.assignmentPrices[n?.assignmentPrices?.length - 1].prices
      : n?.assignmentPrice;
  const Now_Price_per =
    n?.assignmentPrices?.length > 0
      ? n?.assignmentPrices[n?.assignmentPrices?.length - 1].prices
      : n?.assignmentPrice;
  const Now_Price_per_Sqft = Math.ceil(Now_Price_per / n?.interiorSize);
  const WasSqftPrice = 12333;
  Math.ceil(n?.assignmentPrice / n?.interiorSize);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  const ChipStyle = {
    background: "rgb(108, 53, 163)",
    color: "white",
    borderRadius: "5px",
    // fontSize: "20px",
    // padding: "1rem"
  };

  //Calculation Work
  const totalToSeller = Math.ceil(
    nowAssignmentPrice - n?.originalPrice + n?.depositToBuilder
  );
  const depositPercentage = Math.ceil(nowAssignmentPrice * 0.05);
  const BalancePayment = Math.ceil(totalToSeller - depositPercentage);

  //For Floor Plan Image
  const floorPlanData = n?.assignmentMedias?.filter(
    (obj) => obj?.mediaCategory === "Floor Plan"
  );

  //for Download the Image
  const downloadImage = async () => {
    const imageId = floorPlanData[0]?.id;

    try {
      const response = await axios.get(
        `${baseURL}/api/data/ImageInByte/${imageId}`
      );
      const base64Data = response?.data?.data;
      if (base64Data) {
        const binaryData = Uint8Array.from(atob(base64Data), (c) =>
          c.charCodeAt(0)
        ).buffer;
        const imageData = new Blob([binaryData], { type: "image/jpeg" });
        saveAs(imageData, `${n?.projectName} Floor-Plan.jpeg`);
      }
    } catch (error) {
      toast.error("Error downloading image");
    }
  };

  const [bedValue, setBedValue] = useState();

  useEffect(() => {
    if (n?.bed === "0B") {
      setBedValue("Studio");
    } else if (n?.bed === "1B") {
      setBedValue("1 Bed");
    } else if (n?.bed === "1B+D") {
      setBedValue("1 Bed + D");
    } else if (n?.bed === "2B") {
      setBedValue("2 Bed");
    } else if (n?.bed === "2B+D") {
      setBedValue("2 Bed + D");
    } else if (n?.bed === "3B") {
      setBedValue("3 Bed");
    } else if (n?.bed === "3B+D") {
      setBedValue("3 Bed+D");
    } else if (n?.bed === "4B") {
      setBedValue("4 Bed");
    } else if (n?.bed === "4B+D") {
      setBedValue("4 Bed+D");
    } else if (n?.bed === "5B") {
      setBedValue("5 Bed");
    } else if (n?.bed === "5B+D") {
      setBedValue("5 Bed+D");
    } else if (n?.bed === "6B") {
      setBedValue("6 Bed");
    } else if (n?.bed === "6B+D") {
      setBedValue("6 Bed+D");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const MediaCategoryData = n?.assignmentMedias?.filter(
    (obj) => obj?.mediaCategory !== "Floor Plan"
  );

  function formatClosingDate(inputDate) {
    const date = new Date(inputDate);
    const options = { month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <>
      <div style={{ width: "32%" }}>
        {" "}
        <div className=" textwhite mb-4 p-0  font">
          <div className="childcompare_img">
            {n?.assignmentMedias &&
            n?.assignmentMedias.length > 0 &&
            n?.assignmentMedias.fileUrl !== "" ? (
              <Carousel>
                {MediaCategoryData.map((media, index) => (
                  <Carousel.Item key={index} style={{ height: "35vh" }}>
                    <div className="carousel-image-container">
                      <img
                        className="d-block w-100 car0__Image object-fit-cover"
                        src={`${baseURL}/${media.fileUrl}`}
                        alt={n?.projectName}
                        loading="lazy"
                      />
                    </div>
                    <Carousel.Caption>
                      <p>{`${index + 1}/${MediaCategoryData.length}`}</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <img
                src={require("../../../images/no-image.png")}
                alt={n?.projectName}
                style={{ width: "100%", height: "100%", borderRadius: 15 }}
                loading="lazy"
              />
            )}
          </div>
          <div className="container   mt-3">
            <div
              className="row shadow  cardmodal_ajustments"
              style={{ borderRadius: "13px" }}
            >
              <div className="d-flex justify-content-between w-100 flex-wrap">
                {n?.assignmentPrice && (
                  <>
                    <div className="justifyung">
                      {n?.assignmentPrice && (
                        <span>
                          <h4 className="textgreen childModal_heading">
                            <strong>
                              ${nowAssignmentPrice?.toLocaleString("en-US")}
                            </strong>
                            <span className="  mx-2 text-dark allchildModalText fw-bold">{`$${Now_Price_per_Sqft.toLocaleString(
                              "en-US"
                            )}/SQft`}</span>
                          </h4>
                        </span>
                      )}
                    </div>
                  </>
                )}

                <div className=" aligncenter justifications">
                  <button
                    className="Detailsbut "
                    onClick={() => navigate(`/assignment-sale/${n?.slug}`)}
                  >
                    Details
                  </button>{" "}
                  <button
                    className="Borderbut butnmargn"
                    onClick={() => navigate(`/assignment-sale/${n?.slug}`)}
                  >
                    {" "}
                    <RecentActorsIcon /> Contact Seller{" "}
                  </button>
                </div>
              </div>
              {n?.assignmentPrices.length > 0 ? (
                <div className="now_main d-flex flex-col">
                  {" "}
                  <p className="card_price2 was_mb">
                    {/* <span className="Was_hide">Was</span>{" "} */}
                    <span className=" fs-6 cutwas_price">
                      <> ${n?.assignmentPrice?.toLocaleString("en-US")}</>
                    </span>
                    <span className="waspricee_sqft dynamicFont14 mx-2">{`$${WasSqftPrice.toLocaleString(
                      "en-US"
                    )}/SQft`}</span>
                  </p>
                </div>
              ) : null}

              <div className=" col-12 pt-1">
                <p className="m-0  childModal_project">
                  <strong>{n?.projectName}</strong>
                </p>
              </div>

              <div className="d-flex w-100 position-relative">
                <div className="w-50">
                  {n?.type && (
                    <>
                      <div className="row">
                        <div className="col-12 m-1 ">
                          <Chip
                            className="font"
                            label={n?.type}
                            style={ChipStyle}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div
                  className="w-50  pe-2  position-absolute end-0"
                  style={{ marginTop: "0px" }}
                >
                  {" "}
                  <p className="m-0 text-end allchildModalText text-nowrap fw-bold ">
                    <strong className=" ">Posted:</strong>{" "}
                    <span className=" text-black">
                      {" "}
                      <strong>{formatDate(n?.created)}</strong>
                    </span>
                  </p>
                  <p className="fw-bold text-end allchildModalText text-nowrap  m-0">
                    <strong className="text-danger ">Closing:</strong>
                    <span>
                      {" "}
                      <strong className="text-black">
                        {formatClosingDate(n?.occupanycDate)}
                      </strong>
                    </span>
                  </p>
                </div>
              </div>

              <div className={n?.type ? "row mt-1  " : "row mt-5"}>
                <div className="col-12">
                  <p className="m-1">
                    {" "}
                    {n?.bed && (
                      <>
                        {" "}
                        <span className="paading2 webFont14  mobile_padding_Top0">
                          <BedIcon style={{ color: "#0253A0" }} />{" "}
                          <strong> {bedValue}</strong>
                        </span>{" "}
                        -{" "}
                      </>
                    )}
                    {n?.bath && (
                      <>
                        {" "}
                        <span className="paading  webFont14 mobile_padding_Top0">
                          <BathtubIcon style={{ color: "#0253A0" }} />{" "}
                          <strong>{n?.bath} Bath </strong>
                        </span>{" "}
                        -{" "}
                      </>
                    )}
                    {n?.interiorSize && (
                      <>
                        <span className="paading webFont14  mobile_padding_Top0">
                          <FaVectorSquare style={{ color: "#0253A0" }} />{" "}
                          <strong>
                            {n?.interiorSize?.toLocaleString("en-US")} SQft{" "}
                          </strong>
                        </span>{" "}
                      </>
                    )}
                  </p>
                </div>
              </div>

              <div className="row pt-2">
                {n?.address && (
                  <>
                    <div className="col-lg-12 ">
                      <LocationOnIcon
                        className="turningred"
                        style={{ color: "orange" }}
                      />{" "}
                      <span className="m-1   webFont14">{`${n?.address}${
                        n?.city ? `, ${n?.city}` : ""
                      }`}</span>
                    </div>
                  </>
                )}
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <p className="m-1">
                    {" "}
                    {n?.developer && (
                      <>
                        {" "}
                        <span className="paading2">
                          Developer :{" "}
                          <strong className="fw-bold font14">
                            {n?.developer}
                          </strong>
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12">
                  <p className="m-1">
                    {" "}
                    {n?.highlight && (
                      <>
                        <span
                          className="font font-italic webFont14"
                          dangerouslySetInnerHTML={{
                            __html: n.highlight,
                          }}
                        ></span>
                      </>
                    )}
                  </p>
                </div>
              </div>

              {/* <div className="">
                <p className="m-1 w-100 child_modalbedandBath">
                  {" "}
                  <span className="">
                    <BedIcon style={{ color: "#02549e" }} />{" "}
                    <strong style={{ fontWeight: 600 }}> {n.bed}</strong>
                  </span>{" "}
                  <span className="">
                    <BathtubIcon style={{ color: "#02549e" }} />{" "}
                    <strong style={{ fontWeight: 600 }}> {n.bath} Bath </strong>
                  </span>{" "}
                  <span className="">
                    <FaVectorSquare
                      style={{ color: "#02549e", fontSize: 16 }}
                    />{" "}
                    <strong style={{ fontWeight: 600 }}>
                      {" "}
                      {n?.interiorSize} SQft{" "}
                    </strong>{" "}
                  </span>
                </p>
              </div>
              <div className="row mt-1">
                <div className="col-lg-12 childModalAddress">
                  <LocationOnIcon style={{ color: "orange", fontSize: '18px' }} />
                  <span className="m-1 grey" style={{ fontSize: '14px' }}>{n?.address}</span>
                </div>
              </div> */}
            </div>
            {/* Second Card in the Search Popup */}
            <div
              className="row  mt-3 pt-2 shadow pb-2"
              style={{ color: "black" }}
            >
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="justifyung">
                  <h4 className="childModal_heading">
                    {" "}
                    <strong> Floor Plan </strong>{" "}
                  </h4>
                </div>
                {floorPlanData && floorPlanData.length > 0 && (
                  <div className="justifications">
                    <DownloadIcon style={{ cursor: "pointer" }} />
                    <span
                      className=" "
                      onClick={downloadImage}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      Download Floor Plan
                    </span>
                  </div>
                )}
              </div>
              <div className=" col-12 ">
                <button className="textgreen btn btan">
                  <strong>
                    <BedIcon />
                    {bedValue ? (
                      <span
                        className="padleft"
                        style={{ fontSize: "12px" }}
                      >{`${bedValue}`}</span>
                    ) : (
                      "0"
                    )}
                  </strong>
                </button>
              </div>

              <div className=" col-12">
                <p className="m-1">
                  <span className="paading">
                    <BathtubIcon style={{ color: "#02549e" }} />{" "}
                    <strong className=" " style={{ fontWeight: 600 }}>
                      {" "}
                      {n?.bath} Bath{" "}
                    </strong>
                  </span>{" "}
                  -{" "}
                  <span className="paading  ">
                    <FaVectorSquare style={{ color: "#02549e" }} />{" "}
                    <strong style={{ fontWeight: 600 }}>
                      {" "}
                      {n?.interiorSize.toLocaleString("en-US")}FT&sup2;{" "}
                    </strong>{" "}
                  </span>
                </p>
              </div>
            </div>
            {/* Property Details */}
            <div className="row mt-5 shadow cardajustments">
              <div className="cardlinebordder px-3 py-2 ">
                <div className="border_Section ">
                  <div className="col-lg-6 col-12 p-2 heading__grey_backgroud justifyung w-100">
                    <h4>
                      <strong className="m-v-blue-color childModal_heading">
                        {" "}
                        Property Details{" "}
                      </strong>{" "}
                    </h4>
                  </div>
                  <div className="row bottom_pad webFont14">
                    <div className="col-lg-6 col-12  ">Assignment ID:</div>
                    <div className="col-lg-6 col-12">
                      <strong className=" ">
                        {n?.id?.toLocaleString("en-US")}
                      </strong>
                    </div>
                  </div>
                  <div className="row bottom_pad webFont14 ">
                    <div className="col-lg-6 col-12 aligncenter">Address:</div>
                    <div className="col-lg-6 col-12">
                      <strong className="">{n?.address}</strong>
                    </div>
                  </div>

                  {n?.city && n?.city !== "NULL" ? (
                    <div className="row bottom_pad  webFont14">
                      <div className="col-lg-6 col-12">City:</div>
                      <div className="col-lg-6 col-12">
                        <strong>{n?.city}</strong>
                      </div>
                    </div>
                  ) : null}

                  {n?.postalcode && n?.postalcode !== "NULL" ? (
                    <div className="row bottom_pad webFont14 ">
                      <div className="col-lg-6 col-12">Postal Code / Zip:</div>
                      <div className="col-lg-6 col-12">
                        <strong>{n?.postalcode}</strong>
                      </div>
                    </div>
                  ) : null}

                  {n?.mainIntersection && n?.mainIntersection !== "NULL" ? (
                    <div className="row bottom_pad  webFont14">
                      <div className="col-lg-6 col-12">Main Intersection :</div>
                      <div className="col-lg-6 col-12">
                        <strong>{n?.mainIntersection}</strong>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Assignment Details */}
              <div className="cardlinebordder px-3 py-1">
                <div className="border_Section">
                  <div className="m-v-table-head heading__grey_backgroud p-2 justifyung mb-2">
                    <h4>
                      <strong className="m-v-blue-color childModal_heading">
                        {" "}
                        Assignment Details
                      </strong>{" "}
                    </h4>
                  </div>

                  {n?.occupanycDate && (
                    <>
                      <div className="ps-2 d-flex   webFont14 w-100  bottompad">
                        <div className="m-v-table-head w-50">
                          Occupancy Date :
                        </div>
                        <div className="m-v-table-head w-50">
                          <strong>
                            <InsertInvitationIcon
                              style={{ color: "#0253A0", fontSize: "22px" }}
                            />{" "}
                            {formatClosingDate(n?.occupanycDate)}
                          </strong>
                        </div>
                      </div>
                    </>
                  )}

                  {n?.style && (
                    <div className="d-flex ps-2   webFont14 w-100 bottompad">
                      <div className="m-v-table-head w-50">Style:</div>
                      <div className="m-v-table-head w-50">
                        <strong>
                          <CottageIcon
                            style={{ color: "#0253A0", fontSize: "20px" }}
                          />{" "}
                          {n?.style ?? "N/A"}
                        </strong>
                      </div>
                    </div>
                  )}
                  {n?.interiorSize ? (
                    <>
                      {n?.interiorSize && (
                        <>
                          <div className="d-flex ps-2   webFont14 w-100 bottompad">
                            <div className="m-v-table-head w-50 aligncenter">
                              Interior Size :{" "}
                            </div>
                            <div className="m-v-table-head w-50">
                              <strong>
                                <FaVectorSquare
                                  style={{ color: "#0253A0", fontSize: "18px" }}
                                />{" "}
                                {n?.interiorSize}
                                -Sqft
                              </strong>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}

                  {n?.exteriorSize ? (
                    <>
                      <div className="ps-2 d-flex   webFont14 w-100  bottompad">
                        <div className="m-v-table-head w-50">
                          Exterior Size :{" "}
                        </div>
                        <div className="m-v-table-head w-50">
                          <strong>
                            <MdOutlineBalcony
                              style={{ color: "#0253A0", fontSize: "20px" }}
                            />{" "}
                            {n?.exteriorSize}-Sqft
                          </strong>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {n?.floorRange ? (
                    <div className="d-flex ps-2   webFont14 w-100 bottompad">
                      <>
                        <div className="m-v-table-head w-50">Floor-Range:</div>
                        <div className="m-v-table-head w-50">
                          <strong>
                            <EscalatorIcon
                              style={{ color: "#0253A0", fontSize: "20px" }}
                            />{" "}
                            {n?.floorRange}
                          </strong>
                        </div>
                      </>
                    </div>
                  ) : null}
                  {n?.exposure ? (
                    <div className="d-flex ps-2   webFont14 w-100 bottompad">
                      <div className="m-v-table-head w-50">Exposure:</div>
                      <div className="m-v-table-head w-50">
                        <BsSunFill
                          style={{ color: "#f6941c", fontSize: "22px" }}
                        />{" "}
                        <strong>{n?.exposure}</strong>
                      </div>
                    </div>
                  ) : null}

                  {n?.parking ? (
                    <>
                      <div className="d-flex ps-2   webFont14 w-100 bottompad">
                        <div className="m-v-table-head w-50">Parking :</div>
                        <div className="m-v-table-head w-50">
                          <FaParking
                            style={{ color: "#0253A0", fontSize: "20px" }}
                          />{" "}
                          <strong>{n?.parking}</strong>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {n?.parkingType ? (
                    <>
                      <div className="d-flex ps-2   webFont14 w-100 bottompad">
                        <div className="m-v-table-head w-50">Parking Type:</div>
                        <div className="m-v-table-head w-50">
                          <FaParking
                            style={{ color: "#0253A0", fontSize: "20px" }}
                          />{" "}
                          <strong>{n?.parkingType}</strong>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {n?.garage ? (
                    <div className="d-flex ps-2   webFont14 w-100 bottompad">
                      <div className="m-v-table-head w-50">Garage:</div>
                      <div className="m-v-table-head w-50">
                        <GarageIcon style={{ color: "#0253A0" }} />{" "}
                        <strong>{n?.garage ?? "N/A"}</strong>
                      </div>
                    </div>
                  ) : null}

                  {n?.locker ? (
                    <>
                      <div className="d-flex ps-2   webFont14 w-100 bottompad">
                        <div className="m-v-table-head w-50">Locker:</div>
                        <div className="m-v-table-head w-50">
                          <GiLockers
                            style={{ color: "#0253A0", fontSize: "20px" }}
                          />{" "}
                          <strong>{n?.locker}</strong>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {n?.lotWidth && (
                    <>
                      <div className="ps-2 d-flex   webFont14 w-100  bottompad">
                        <div className="m-v-table-head w-50">Lot Width :</div>
                        <div className="m-v-table-head w-50">
                          <OpenWithIcon
                            style={{ color: "#0253A0", fontSize: "20px" }}
                          />{" "}
                          <strong>{n?.lotWidth}-feet</strong>
                        </div>
                      </div>
                    </>
                  )}

                  {n?.colorselectionchosen && (
                    <>
                      <div className="ps-2 d-flex   webFont14 w-100  bottompad">
                        <div className="m-v-table-head w-50">
                          Color Selection Chosen :
                        </div>
                        <div className="m-v-table-head w-50">
                          <strong>
                            {n?.colorselectionchosen === false ? "No" : "Yes"}
                          </strong>
                        </div>
                      </div>
                    </>
                  )}

                  {n?.leaseDuringOccupancy && (
                    <>
                      <div className="ps-2 d-flex   webFont14 w-100  bottompad">
                        <div className="m-v-table-head w-50">
                          Lease During Occupancy :
                        </div>
                        <div className="m-v-table-head w-50">
                          <strong>
                            {n?.leaseDuringOccupancy === false ? "No" : "Yes"}
                          </strong>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* Pricing Details */}
              <div className="cardlinebordder px-3 py-1">
                <div className="border_Section">
                  <div className="m-v-table-head heading__grey_backgroud p-2 justifyung ">
                    <h4>
                      <strong className="m-v-blue-color childModal_heading">
                        {" "}
                        Pricing Details{" "}
                      </strong>{" "}
                    </h4>
                  </div>
                  {n?.assignmentPrice ? (
                    <div className="row bottom_pad webFont14 ">
                      <div className="col-lg-6 col-12">Seller asking price</div>
                      <div className="col-lg-6 col-12">
                        <strong>
                          $ {n?.assignmentPrice.toLocaleString("en-US")}
                        </strong>
                      </div>
                    </div>
                  ) : null}

                  {n?.originalPrice ? (
                    <div className="row bottom_pad webFont14 ">
                      <div className="col-lg-6 col-12">
                        {" "}
                        Original Purchase Price :
                      </div>
                      <div className="col-lg-6 col-12">
                        <strong>
                          $ {n?.originalPrice?.toLocaleString("en-US")}
                        </strong>
                      </div>
                    </div>
                  ) : null}

                  {n?.depositToBuilder ? (
                    <div className="row bottom_pad webFont14 ">
                      <div className="col-lg-6 col-12 ">
                        Original Purchase Deposit :
                      </div>
                      <div className="col-lg-6 col-12">
                        <strong>
                          ${n?.depositToBuilder.toLocaleString("en-US")}
                        </strong>
                      </div>
                    </div>
                  ) : null}

                  <div className="row bottom_pad webFont14  ">
                    <div className="col-lg-6 col-12">
                      Total payment by buyer to seller :
                    </div>
                    <div className="col-lg-6 col-12">
                      <strong>$ {totalToSeller.toLocaleString("en-US")}</strong>
                    </div>
                  </div>

                  {depositPercentage ? (
                    <div className="row bottom_pad webFont14 ">
                      <div className="col-lg-6 col-12">
                        5% Deposit upon acceptance of assignment agreement :
                      </div>
                      <div className="col-lg-6 col-12">
                        <strong>
                          $ {depositPercentage.toLocaleString("en-US")}
                        </strong>
                      </div>
                    </div>
                  ) : null}

                  {
                    <div className="row bottom_pad webFont14 ">
                      <div className="col-lg-6 col-12">
                        Balance of the payment for this assignment agreement :
                      </div>
                      <div className="col-lg-6 col-12">
                        <strong>
                          $ {BalancePayment.toLocaleString("en-US")}
                        </strong>
                      </div>
                    </div>
                  }

                  {n?.occupancyDue ? (
                    <div className="row bottom_pad webFont14 ">
                      <div className="col-lg-6 col-12">Occupancy-Due :</div>
                      <div className="col-lg-6 col-12">
                        <strong>
                          $ {n?.occupancyDue.toLocaleString("en-US")}
                        </strong>
                      </div>
                    </div>
                  ) : null}

                  {n?.maintainenceFee ? (
                    <div className="row bottom_pad  webFont14">
                      <div className="col-lg-6 col-12">Maintainence Fee :</div>
                      <div className="col-lg-6 col-12">
                        <strong>
                          $ {n?.maintainenceFee.toLocaleString("en-US")}
                        </strong>
                      </div>
                    </div>
                  ) : null}

                  {n?.upgradeDepositPaid ? (
                    <div className="row bottom_pad  webFont14">
                      <div className="col-lg-6 col-12">
                        {" "}
                        Upgrade Deposite Paid :
                      </div>
                      <div className="col-lg-6 col-12">
                        <strong>
                          $ {n?.upgradeDepositPaid.toLocaleString("en-US")}
                        </strong>
                      </div>
                    </div>
                  ) : null}

                  {n?.upgradeBalance ? (
                    <div className="row bottom_pad  webFont14">
                      <div className="col-lg-6 col-12">
                        {" "}
                        Upgrade Balance(Text) :
                      </div>
                      <div className="col-lg-6 col-12">
                        <strong>
                          $ {n?.upgradeBalance.toLocaleString("en-US")}
                        </strong>
                      </div>
                    </div>
                  ) : null}

                  {n?.upgradeCharges ? (
                    <div className="row bottom_pad  webFont14">
                      <div className="col-lg-6 col-12">Upgrade Charges :</div>
                      <div className="col-lg-6 col-12">
                        <strong>
                          $ {n?.upgradeCharges.toLocaleString("en-US")}
                        </strong>
                      </div>
                    </div>
                  ) : null}

                  {n?.levyFee ? (
                    <div className="row bottom_pad webFont14 ">
                      <div className="col-lg-6 col-12">
                        Development / Levy Charges:
                      </div>
                      <div className="col-lg-6 col-12">
                        <strong>$ {n?.levyFee.toLocaleString("en-US")}</strong>
                      </div>
                    </div>
                  ) : null}

                  {n?.commission ? (
                    <div className="row bottom_pad webFont14 ">
                      <div className="col-lg-6 col-12">
                        Cooperating Commision% :
                      </div>
                      <div className="col-lg-6 col-12">
                        <strong>{n?.commission}%</strong>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
