import HelmetLayout from "../../Utils/HelmetLayout/Helmet";
import "../FinanceOption/FinanceOption.css";
import Footer from "../Footer/Footer";
import "./AdvertiseCompany.css";

const CompanyAdvertise = () => {
  return (
    <>
      <HelmetLayout
        pageName={"company-advertisement"}
        canonicalLink={"company-advertisement"}
      />

      <div className="finace_font">
        {/* ----------------- Advertise Main Headings Section--------------------- */}
        <div className="d-flex text-white w-100 p-4 finance_container">
          <div className="container finance_container">
            <h2 className="Finance_Heading">
              Advertise your company on AssignmentSold.ca
            </h2>
            <div>
              <p className="mt-4 text-center">
                "Is a platform that provides exceptional opportunities for
                advertisers."
              </p>

              <p className="Advertise_para mt-3">
                "Besides attracting a vast audience of internet users, our
                network caters to individuals in the process of moving, each
                with their unique set of needs. Seize this opportunity to
                showcase your products and services!
              </p>

              <p className="Advertise_para">
                Opportunity to showcase your products and services!
                assignmentsold.com is a versatile, user-friendly platform that
                offers unparalleled visibility to your potential customers. Get
                in touch with us to explore how we can assist you in achieving
                your business goals!"
              </p>
            </div>
          </div>
        </div>

        {/* ----------------- Advertise Access Section--------------------- */}

        <div className="container">
          <div className="row mt-5">
            <div className="col-12 Advertisement_terms">
              <h4>The AssignmentSold network gives you access to</h4>
              <ol className="mt-4 Advertise_access">
                <li> 5.1 million visits per month1</li>
                <li> Over 21 million page views per month2</li>
                <li> Innovative and effective content integration formats</li>
                <li> A suitable target market</li>
              </ol>
            </div>
          </div>
        </div>

        {/* ----------------- Advertise Information Section--------------------- */}

        <div className="Finance_getFinancing">
          <h2 className="mt-3">For more information Write to us at</h2>
          <p className="Advertise_Email">communications@AssignmentSold.com.</p>
          <ol className="mb-4">
            <li className="mt-2">
              {" "}
              Google Analytics, average number of monthly visits (Desktop,
              Mobile, Tablet), January 1st 2022 - December 31st 2022.2.{" "}
            </li>
            <li className="mt-2">
              {" "}
              Google Analytics, average number of monthly page views (Desktop,
              Mobile, Tablet), January 1st 2022 - December 31st 2022.
            </li>
          </ol>
        </div>

        {/* ----------------- Advertise Footer Section--------------------- */}

        <div className="mt-5">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CompanyAdvertise;
