import React from "react";

const ListingPopularity = () => {
  const data = {
    similarListing: ["40", "60", "80"],
    currentListing: "90",
  };
  return (
    <div className="w-100">
      <h2 className="property_Details_heading">Listing Popularity</h2>
      <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14">
        Popularity: 40/100
      </p>
      <div
        className="d-flex align-items-center justify-content-start my-3"
        style={{ gap: "20px" }}
      >
        <div className="d-flex align-items-center">
          <span
            className="composed_chart_legend_cirlce"
            style={{ backgroundColor: "#CA0024" }}
          ></span>
          <span className="ps-2 composed_chart_legend_text">
            Current Listing
          </span>
        </div>
        <div className="d-flex align-items-center">
          <span
            className="composed_chart_legend_cirlce"
            style={{ backgroundColor: "#F6941C" }}
          ></span>
          <span className="ps-2 composed_chart_legend_text">
            Similar Listing
          </span>
        </div>
      </div>

      <div className="position-relative w-100 mb-4">
        <img
          className="w-100"
          src="/images/MarketProgressBar.png"
          alt="MarketProgressBar"
          loading="lazy"
        />
        {data?.similarListing?.map((value, index) => (
          <div
            key={index}
            className="position-absolute "
            style={{
              left: `${value}%`,
              background: "#F6941C",
              borderRadius: "50%",
              top: "4px",
              width: "16px",
              height: "16px",
            }}
          >
            {" "}
          </div>
        ))}
        <div
          className="position-absolute "
          style={{
            left: `${data?.currentListing}%`,
            background: "#CA0024",
            borderRadius: "50%",
            top: "4px",
            width: "16px",
            height: "16px",
          }}
        >
          {" "}
        </div>
      </div>
    </div>
  );
};

export default ListingPopularity;
