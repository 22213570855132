import React from "react";
import Navbar from "../Components/Navbar/Navbar";

const WithNavbarLayout = ({ children }) => {
  return (
    <>
      <div style={{ position: "sticky", top: 0, zIndex: 1100 }}>
        <Navbar />
      </div>
      <div>{children}</div>
    </>
  );
};

export default WithNavbarLayout;
