import { useNavigate } from 'react-router-dom';
import '../../UserListings.css';
import dayjs from 'dayjs';
import { formatPhoneNumber } from '../../../../CustomHook/Formatter';


const UserAllListingDataCard = ({ item }) => {

    const navigate = useNavigate()

    return (
        <div className="UserAll_listingCard" onClick={() => navigate(`/dashboard/users/${item?.id}?name=${item?.firstName}&role=${item?.role}`)}>
           <div className='d-flex justify-content-between align-items-center'>
           <div className='UserAll_listingCardDateChiip'>
                <p className='m-0'>{dayjs(item?.created).format('DD-MM-YYYY')}</p>
            </div>
            <div className={`UserAll_listingCardDateChiip ${item?.emailConfirmed === true ? 'active_emailChip' : 'inactive_emailchip'}`}>
                <p className='m-0'>{item?.emailConfirmed === true ? 'Active' : 'Inactive'}</p>
            </div>
           </div>
            <div>
                <h2 className='UserAll_listingCardUserName mt-3'>{item?.firstName}</h2>
                <p className='UserAll_listingCardUserEmail m-0'>{item?.email}</p>
               {item?.phoneNumber ?
                <p className='UserAll_listingCardUserEmail '>{formatPhoneNumber(item?.phoneNumber)}</p>
                :
                <p className='UserAll_listingCardUserEmail '>No Phone number.</p>
            }
            </div>

            <div className='d-flex flex-column'>
                <div className='UserAll_listingCardStatusBtn'>
                   {item?.role !== 'Buyer' && (
                 <>
                     <button style={{ backgroundColor: '#84FAB5', color: '#005624' }}>{`Active(${item?.active})`}</button>
                     <button style={{ backgroundColor: '#F8EFA3', color: '#6F6300' }}>{`Pending(${item?.pending})`}</button>
                     <button style={{ backgroundColor: '#FF4D6D', color: '#fff' }}>{`Suspended(${item?.suspended})`}</button>
                     </>
                   )}
                    <button className={`UserAll_listingCardSearchStatusBtn ${item?.role === 'Buyer' ? 'favSearchcon_Margin' : ''}`}>{`Favorite(${item?.favourite})`}</button>
                    <button className={`UserAll_listingCardSearchStatusBtn ${item?.role === 'Buyer' ? 'favSearchcon_Margin' : ''}`}>{`Searches(${item?.saveSearch})`}</button>
                    {/* <button className={`UserAll_listingCardSearchStatusBtn ${item?.role === 'Buyer' ? 'favSearchcon_Margin' : ''}`}>{`Conversations(${item?.conversation ?? 0})`}</button> */}
                </div>
            </div>
        </div>
    )
}

export default UserAllListingDataCard;