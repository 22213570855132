import React, { useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { WatchingNeighbourIcon } from "../../../SvgIcons/Icons";
import MoreSimilarListing from "../../PropertyDetails/SubComponents/MoreSimilarListings";

const ViewMoreListings = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion PropertyDetails_MobileAccordion Property_simpleAccordion MobileAccordion_BottomBorder"
      sx={{ background: "#fff" }}
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && (
            <WatchingNeighbourIcon color={"#282828"} size={24} />
          )}
          <h2 className="property_Details_heading">View more</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-100">
          <div className="">
            <MoreSimilarListing />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(ViewMoreListings);
