import React, { useEffect, useState, memo } from "react";
import {
  DollarWithSquareIcon,
  ZigZagBorderIcon,
} from "../../../SvgIcons/Icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const PropertyPricing = ({
  data,
  isMobileView,
  setIsSignUpModalOpen,
  isUserlogin,
  setSignUpModalHeading,
}) => {
  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  const formattedDepositStructure = data?.depositStructureNH
    ? data?.depositStructureNH?.replace(/\n/g, "<br />")
    : data?.depositStructure?.replace(/\n/g, "<br />");

  return (
    <div className="w-100">
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className="Property_details_Web_Accordion PropertyDetails_MobileAccordion DetailsAccordion_withborder py-2"
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon className="Property_minusWebGreyIcon" />
            ) : (
              <AddIcon className="Property_plusWebGreyIcon" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={isMobileView ? "d-flex align-items-center gap-2" : ""}
          >
            {isMobileView && <DollarWithSquareIcon />}
            <h2 className="property_Details_heading">
              {data?.projectTitle} Pricing
            </h2>
            {!isMobileView && (
              <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
                Discover {data?.projectTitle} Pricing and Exclusive Incentives
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
              Discover {data?.projectTitle} Pricing and Exclusive Incentives
            </p>
          )}
          <hr
            className="my-3 PropDetailsMob_md_hide"
            style={{ background: "#676767" }}
          />
          <div className="row w-100 mt-3 position-relative">
            <div className="col-md-4 col-12 py-2">
              <div className="mb-4 Property_pricingIconHeading">
                <ZigZagBorderIcon />
                <h5 className="Mimico_pricing_subHeading"> Precon Pricing</h5>
              </div>
              <div className="Property_pricingMblPadding">
                <div>
                  <h6 className="Mimico_pricing_sub_Headings">
                    Minimum Deposit
                  </h6>
                  {isUserlogin ? (
                    <p className="Mimico_pricing_subContent PropertyDetails_Mbl_f14">
                      15%
                    </p>
                  ) : (
                    <img
                      src="/images/pricingBlur.png"
                      className="mb-2"
                      width={"84px"}
                      height={"100%"}
                      alt="blurPricing"
                      loading="lazy"
                    />
                  )}
                </div>
                {data?.lockerCost ? (
                  <div className="mt-2">
                    <h6 className="Mimico_pricing_sub_Headings">Locker</h6>
                    {isUserlogin ? (
                      <p className="Mimico_pricing_subContent PropertyDetails_Mbl_f14">
                        ${data?.lockerCost?.toLocaleString("en-Us")}
                      </p>
                    ) : (
                      <img
                        src="/images/pricingBlur.png"
                        className="mb-2"
                        width={"84px"}
                        height={"100%"}
                        alt="blurPricing"
                        loading="lazy"
                      />
                    )}
                  </div>
                ) : null}

                {data?.parkingPurchaseCost ? (
                  <div className="mt-2">
                    <h6 className="Mimico_pricing_sub_Headings">Parking</h6>
                    {isUserlogin ? (
                      <p className="Mimico_pricing_subContent PropertyDetails_Mbl_f14">
                        ${data?.parkingPurchaseCost?.toLocaleString("en-Us")}
                      </p>
                    ) : (
                      <img
                        src="/images/pricingBlur.png"
                        className="mb-2"
                        width={"84px"}
                        height={"100%"}
                        alt="blurPricing"
                        loading="lazy"
                      />
                    )}
                  </div>
                ) : null}
                {data?.ccMaint ? (
                  <div className="mt-2">
                    <h6 className="Mimico_pricing_sub_Headings">Maintenance</h6>
                    {isUserlogin ? (
                      <p className="Mimico_pricing_subContent PropertyDetails_Mbl_f14">
                        ${data?.ccMaint?.toLocaleString("en-US")}
                      </p>
                    ) : (
                      <img
                        src="/images/pricingBlur.png"
                        className="mb-2"
                        width={"84px"}
                        height={"100%"}
                        alt="blurPricing"
                        loading="lazy"
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-md-8 col-12 py-2">
              <div className="mb-4 Property_pricingIconHeading">
                <ZigZagBorderIcon />
                <h5 className="Mimico_pricing_subHeading">
                  {" "}
                  Deposit Structure
                </h5>
              </div>

              <div className="Property_pricingMblPadding">
                <h6 className="Mimico_pricing_sub_Headings">On signing</h6>
                {isUserlogin ? (
                  <>
                    {data?.depositStructure || data?.depositStructureNH ? (
                      <p
                        className="Mimico_pricing_subContent PropertyDetails_Mbl_f14"
                        dangerouslySetInnerHTML={{
                          __html: formattedDepositStructure,
                        }}
                      />
                    ) : null}
                  </>
                ) : (
                  <>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <div key={index}>
                        <img
                          src="/images/pricingBlur.png"
                          className="mb-2"
                          width="84px"
                          height="100%"
                          alt={`blurPricing-${index + 1}`}
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>

            {!isUserlogin && (
              <div className="position-absolute d-flex align-items-center justify-content-center w-100 h-100 top-0 left-0">
                <button
                  className="PropertyDetails_Mbl_f14 Prop_loginReqBtn"
                  onClick={() => {
                    setIsSignUpModalOpen(true);
                    setSignUpModalHeading(
                      "Join us to see our pricing structure!"
                    );
                  }}
                >
                  Login Required
                </button>
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(PropertyPricing);
