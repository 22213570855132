import React, { useEffect, useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { FeatureAndFinishesIcon } from "../../../SvgIcons/Icons";

const FeatureAndFinishes = ({ data, isMobileView }) => {
  const [showMore, setShowMore] = useState(true);
  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const toggleExpand = () => {
    setShowMore(false);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  const description = data?.featuresFinishes?.replace(/\n/g, "<br />") || "";
  const shouldShowButton = description.split(" ").length > 115;
  const displayedText = !showMore
    ? description
    : description.split(" ").slice(0, 112).join(" ");

  return (
    <div className="w-100">
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon className="Property_minusWebGreyIcon" />
            ) : (
              <AddIcon className="Property_plusWebGreyIcon" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={isMobileView ? "d-flex align-items-center gap-2" : ""}
          >
            {isMobileView && <FeatureAndFinishesIcon />}
            <h2 className="property_Details_heading">Feature And Finishes</h2>
            {!isMobileView && (
              <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
                {`Learn what sets ${data?.projectTitle} apart—where modern living
              meets exceptional convenience.`}
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
              {`Learn what sets ${data?.projectTitle} apart—where modern living
              meets exceptional convenience.`}
            </p>
          )}
          <div className="position-relative mt-3">
            <div
              className={
                showMore && shouldShowButton
                  ? "PropDetails_HideDescription"
                  : ""
              }
            >
              <p
                className={`PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 ${
                  shouldShowButton && !showMore ? "mb-0" : ""
                }`}
                dangerouslySetInnerHTML={{ __html: displayedText }}
              />
            </div>
          </div>

          {shouldShowButton && showMore && (
            <button onClick={toggleExpand} className="Detail_showMoreBtn">
              Show all
              {/* <span className="ps-1 Property_detail_more_icon">
                <AddIcon sx={{ color: "#676767" }} />
              </span> */}
            </button>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(FeatureAndFinishes);
