import React, { useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const PropertyFaqs = ({ data, isShowHeading }) => {
  const [expandedPanels, setExpandedPanels] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanels((prevPanels) =>
      isExpanded
        ? [...prevPanels, panel]
        : prevPanels.filter((p) => p !== panel)
    );
  };

  return (
    <div className="w-100">
      {!isShowHeading && (
        <h2 className="property_Details_heading Property_detailsMarginTop1 mb-3 PropDetails_textCenter">
          FAQs
        </h2>
      )}
      {data?.map((item, index) => {
        const panelId = `panel-${index}`;
        return (
          <Accordion
            className="Property_details_faq_box"
            key={index}
            expanded={expandedPanels.includes(panelId)}
            onChange={handleChange(panelId)}
          >
            <AccordionSummary
              expandIcon={
                expandedPanels.includes(panelId) ? (
                  <RemoveIcon className="Property_minusIcon" />
                ) : (
                  <AddIcon className="Property_plusIcon" />
                )
              }
              aria-controls={`${panelId}-content`}
              id={`${panelId}-header`}
            >
              <h3 className="Property_details_faq_question mb-0">
                {item?.name}
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <h4 className="font Property_details_faq_answer mb-0">
                {item?.content}
              </h4>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default memo(PropertyFaqs);
