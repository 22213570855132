import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Box, Modal } from "@mui/material";
import { CloseIcon, SuccesfullySubmitedIcon } from "../../../../SvgIcons/Icons";
import axios from "axios";
import { toast } from "react-toastify";
import baseURL from "../../../../config";

const WatchNeigbourHoodModal = ({
  data,
  isUserlogin,
  open,
  handleClose,
  setIsWatchingNeighbour,
  isWatchingNeighbour,
}) => {
  const jwt_token = isUserlogin;
  const [isLoading, setIsLoading] = useState(false);

  const WatchNeighBourData = data?.listingUserActivityDTO?.watchNeighbour;
  const [isNewListing, setIsNewListing] = useState(false);
  const [isSoldListing, setIsSoldListing] = useState(false);
  const [isDelistedListing, setIsDelistedListing] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isSuccessfullySubmited, setIsSuccessfullySubmited] = useState(false);

  useEffect(() => {
    const isNew = localStorage.getItem("isNewListing") === "true";
    const isSold = localStorage.getItem("isSoldListing") === "true";
    const isDelisted = localStorage.getItem("isDelistedListing") === "true";
    setIsNewListing(isNew);
    setIsSoldListing(isSold);
    setIsDelistedListing(isDelisted);
  }, []);

  const handleSubmit = async () => {
    let error = false;
    if (
      !isWatchingNeighbour &&
      !isNewListing &&
      !isDelistedListing &&
      !isSoldListing
    ) {
      error = true;
    }

    if (!error && isUserlogin) {
      setIsLoading(true);
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const Watchdata = {
        Id: WatchNeighBourData ? WatchNeighBourData?.id : "0",
        ProId: data?.uId, 
        Lat: data?.latitude,
        Lng: data?.longitude,
        NewListing: isNewListing ? isNewListing : false,
        SoldListing: isSoldListing ? isSoldListing : false,
        DeListed: isDelistedListing ? isDelistedListing : false,
      };
      try {
        const response = await axios.post(
          `${baseURL}/api/AuthData/AddWatchNeighbour`,
          Watchdata,
          {
            headers,
          }
        );
        if (response?.data?.result === 1) {
          setIsSuccessfullySubmited(true);
          setIsLoading(false);
          localStorage.setItem("isNewListing", isNewListing);
          localStorage.setItem("isSoldListing", isSoldListing);
          localStorage.setItem("isDelistedListing", isDelistedListing);
          setIsWatchingNeighbour(
            isNewListing || isDelistedListing || isSoldListing ? true : false
          );
        } else {
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="Prop_detail_infoModal Prop_detailsValueModal WatchNeighbourHood_inputs">
        {isSuccessfullySubmited ? (
          <div className="d-flex flex-column align-items-center">
            <div className="propertyDetail_homeValue_icon">
              <SuccesfullySubmitedIcon />
            </div>
            <h6 style={{ fontSize: "20px" }} className="text-center mt-3">
              Successfully Updated
            </h6>
            <p style={{ fontSize: "16px" }} className="mt-1 text-center">
              {isSoldListing || isDelistedListing || isNewListing
                ? "Thank you! You will receive an email with updates based on your selected listings."
                : "You have unselected all listing options. You will no longer receive updates for these listings. To receive updates, please select at least one option."}
            </p>

            <div className="mt-2">
              <button onClick={handleClose}>Okay</button>
            </div>
          </div>
        ) : (
          <>
            {/* <div className="propertyDetail_homeValue_icon">
              <WatchNeighbourHoodIcon />
            </div> */}
            <h6 style={{ fontSize: "25px" }} className="mt-2">
              Watch Neighborhood Listings
            </h6>
            <p style={{ fontSize: "16px" }} className="mt-2">
              Please select the listings you are interested in.
            </p>
            <div className="w-100 d-flex align-items-center mt-3">
              <input
                type="checkbox"
                checked={isNewListing}
                onChange={() => setIsNewListing(!isNewListing)}
              />
              <span className="ps-2">New Listings</span>
            </div>

            <div className="w-100 d-flex align-items-center mt-3">
              <input
                type="checkbox"
                checked={isSoldListing}
                onChange={() => setIsSoldListing(!isSoldListing)}
              />
              <span className="ps-2">Sold Listings</span>
            </div>

            <div className="w-100 d-flex align-items-center mt-3">
              <input
                type="checkbox"
                checked={isDelistedListing}
                onChange={() => setIsDelistedListing(!isDelistedListing)}
              />
              <span className="ps-2">Delisted Listings</span>
            </div>

            {isSubmited &&
              !isWatchingNeighbour &&
              !isSoldListing &&
              !isDelistedListing &&
              !isNewListing && (
                <div className="mt-3">
                  <span style={{ color: "red", marginTop: "10px" }}>
                    “Please select at least one listing option to receive
                    updates.”
                  </span>
                </div>
              )}

            {isLoading ? (
              <div className="aligncenter justifycenter mt-3">
                <RotatingLines width={50} strokeColor="grey" />
              </div>
            ) : (
              <div className="mt-3">
                <button
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                    setIsSubmited(true);
                  }}
                >
                  Submit
                </button>
              </div>
            )}
          </>
        )}
        <div
          className="position-absolute Property_detailsWeb_hide"
          style={{ top: "20px", right: "15px" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </div>
      </Box>
    </Modal>
  );
};

export default WatchNeigbourHoodModal;
