import { createSlice } from "@reduxjs/toolkit";

const heartDataSlice = createSlice({
  name: "heartDataSlice",
  initialState: {
    favItems: [],
  },
  reducers: {
    setFavItems: (state, action) => {
      state.favItems = action.payload;
    },
    addFavItem: (state, action) => {
      const {tableUId, uId } = action.payload;
      if (!state.favItems.some(item =>  item.uId === uId)) {
        state.favItems.push({tableUId, uId });
      }
    },
    removeFavItem: (state, action) => {
      const { uId } = action.payload;
      state.favItems = state.favItems.filter(item => item.uId !== uId);
    },
}});

export const { setFavItems, addFavItem, removeFavItem } = heartDataSlice.actions;
export default heartDataSlice.reducer;
