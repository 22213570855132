import { createSlice } from "@reduxjs/toolkit";

const ratingDataSlice = createSlice({
  name: "ratingDataSlice",
  initialState: {
    ratingData: [],
  },
  reducers: {
    addRatingData: (state, action) => {
        state.ratingData = action.payload;
    },
    updateRatingData: (state, action) => {
      const { id, values, content } = action.payload;
      const index = state.ratingData.findIndex((item) => item.id === id);
      if (index !== -1) {
        if (values) {
          state.ratingData[index].values = values;
        }
        if (content) {
          state.ratingData[index].content = content;
        }
      }else {
        state.ratingData.push({ id, values, content });
      }
    },
    removeRatingData: (state, action) => {
      state.ratingData = [];
    },
  },
});

export const { addRatingData, updateRatingData, removeRatingData } =
  ratingDataSlice.actions;
export default ratingDataSlice.reducer;
