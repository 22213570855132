import { useNavigate } from "react-router-dom";



const UserSaveSearchesCard = ({ item }) => {

    const navigate = useNavigate()

    const handleSeePropBtn = () => {
        const searchData = item?.searchparameters
        // navigate(`/assignment-sale/${item?.uId}`, { state: { searchData } });
        navigate('/', { state: { searchData } });
    }

    return (
        <div className="user_saveSearchCard">
            <h3>{item?.searchName}</h3>
            <div className="pt-2 user_saveSearchesList">
                <ul className="">
                    {(item?.searchparameters?.title) && (
                        <li>{item?.searchparameters?.title}</li>
                    )}
                    {(item?.searchparameters?.minPrice || item?.searchparameters?.maxPrice) && (
                        <li>{`$ ${item?.searchparameters?.minPrice?.toLocaleString("en-US")} - $ ${item?.searchparameters?.maxPrice?.toLocaleString("en-US")}`}</li>
                    )}
                    {(item?.searchparameters?.minSqft || item?.searchparameters?.maxSqft) && (
                        <li>{`${item?.searchparameters?.minSqft?.toLocaleString("en-US")} - ${item?.searchparameters?.maxSqft?.toLocaleString("en-US")} sqft`}</li>
                    )}
                    {(item?.searchparameters?.radius) && (
                        <li>{`${item?.searchparameters?.radius?.toLocaleString("en-US")} Km`}</li>
                    )}
                    {(item?.searchparameters?.searchparameters_Beds) && (
                        <>
                            {item?.searchparameters?.searchparameters_Beds?.map((item, index) => {
                                return (
                                    <li key={index}>{item?.parameter}</li>
                                )
                            })}
                        </>
                    )}
                    {(item?.searchparameters?.searchparameters_Baths) && (
                        <>
                            {item?.searchparameters?.searchparameters_Baths?.map((item, index) => {
                                return (
                                    <li key={index}>{item?.parameter} Baths</li>
                                )
                            })}
                        </>
                    )}
                    {(item?.searchparameters?.searchparameters_Types) && (
                        <>
                            {item?.searchparameters?.searchparameters_Types?.map((item, index) => {
                                return (
                                    <li key={index}>{item?.parameter}</li>
                                )
                            })}
                        </>
                    )}
                    {(item?.searchparameters?.searchparameters_Citys) && (
                        <>
                            {item?.searchparameters?.searchparameters_Citys?.map((item, index) => {
                                return (
                                    <li key={index}>{item?.parameter}</li>
                                )
                            })}
                        </>
                    )}
                    {(item?.searchparameters?.searchparameters_Parkings) && (
                        <>
                            {item?.searchparameters?.searchparameters_Parkings?.map((item, index) => {
                                return (
                                    <li key={index}>{item?.parameter} Parkings</li>
                                )
                            })}
                        </>
                    )}
                    {(item?.searchparameters?.status) && (
                        <li>{item?.searchparameters?.status}</li>
                    )}
                </ul>
            </div>
            <div className="User_saveSearchListBtn">
                <button>{`Results (${item?.searchresults})`}</button>
                <button onClick={() => handleSeePropBtn()}>See Properties</button>
            </div>
        </div>
    )
}


export default UserSaveSearchesCard;