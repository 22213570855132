import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiFillHeart } from "react-icons/ai";
import Cookies from "universal-cookie";
import baseURL from "../../../config";
import { AiOutlineHeart } from "react-icons/ai";
import "../CardListing/Card.css";
import "../../../../src/Components.css";
import "../../../../src/App.css";
import CloseIcon from "@mui/icons-material/Close";
import CardActions from "@mui/material/CardActions";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ChildModalComparison from "../ChildModalComparison/ChildModalComparison";
import CompareModal from "../CompareModal/CompareModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardMiddleSection from "./CardMiddleSection";
import {
  addFavItem,
  removeFavItem,
} from "../../../ReduxStore/Slices/HeartDataSlice";
import ShareFeature from "../../../Components/Share-Component/ShareFeature";
import "./CardMain.css";
import jwtDecode from "jwt-decode";
import {
  addCompareItem,
  clearCompareItems,
  removeCompareItem,
} from "../../../ReduxStore/Slices/CompareListing";
import Loader from "./Loader";
import { ThreeCircles } from "react-loader-spinner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "700px",
  width: "54%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 3,
  zIndex: 0,
  overflow: "auto",
};

const bigModalstyle = {
  position: "absolute",
  top: "12px",
  left: "49%",
  height: "97vh !important",
  transform: "translateX(-50%)",
  width: "100%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: 1,
  boxShadow: 24,
  zIndex: 0,
  overflow: "auto",
  paddingBottom: "3px",
};

export default function CardMain({
  cardPath,
  item,
  handleHoveredListCard,
  showOnhovered,
}) {
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const compareListingData = useSelector(
    (state) => state.CompareListings.compareListingData
  );
  const favouriteData = useSelector((state) => state.heartDataSlice);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [compareCompleteData, setCompareCompleteData] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Work for comparision
  const [open, setOpen] = useState(false);
  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false);

  const fetchComparisionData = async () => {
    const data = compareListingData.map((item) => item.uId);
    try {
      const response = await axios.post(
        `${baseURL}/api/Assignment/GetAssignmentsToCompare`,
        data
      );
      if (response?.data?.result === 1) {
        setCompareCompleteData(response?.data?.data?.propToCompare);
        setIsLoading(false);
        setOpen(false);
        setIsCompareModalOpen(true);
      }
    } catch (e) {
      setIsLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleCheckboxChange = (event, item) => {
    const { checked } = event.target;
    const selectedCheckboxesCount = compareListingData?.length;
    if (checked && selectedCheckboxesCount === 3) {
      toast.error("Maximum of three records can be selected");
      setOpen(true);
      return;
    }
    if (checked) {
      dispatch(addCompareItem(item));
      setOpen(true);
    } else {
      dispatch(removeCompareItem(item));
    }
  };

  const handleAddMore = () => {
    setOpen(false);
  };

  const handleCompareClick = () => {
    setIsLoading(true);
    fetchComparisionData();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCompareModalClose = () => {
    setIsCompareModalOpen(false);
  };

  const handleRemoveCompare = (item) => {
    dispatch(removeCompareItem(item));
    setOpen(false);
  };

  const handleAllClear = () => {
    dispatch(clearCompareItems());
    setOpen(false);
  };

  //handle Favourite Work
  const [cardIde, setCardIde] = useState([]);
  const [FavInProgress, setFavInProgress] = useState({});

  useEffect(() => {
    const cardIds = favouriteData?.favItems?.map((item) => item?.uId);
    setCardIde(cardIds);
  }, [favouriteData]);

  const handleHeartClick = async (uId) => {
    if (!jwt_token) {
      return navigate("/login", {
        state: { returnUrl: window.location.pathname },
      });
    }
    if (FavInProgress[uId]) {
      return;
    }

    setFavInProgress((prevState) => ({
      ...prevState,
      [uId]: true,
    }));

    const isLogedIn = localStorage.getItem("anonymous");
    const isUserAuthorize = jwt_token && isLogedIn === "1";
    let emailConfirmed = false;
    const decoded = jwtDecode(jwt_token);
    const emailConfirmedStr =
      decoded[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous"
      ];
    emailConfirmed = emailConfirmedStr === "True";

    if (isUserAuthorize && emailConfirmed) {
      const isAlreadyFavorited = cardIde.includes(uId);
      if (isAlreadyFavorited) {
        DeleteToFav(uId);
      } else {
        AddTofav(uId);
      }
    } else {
      navigate("/email-confirmation?isEmailConfirmed=false");
    }
  };

  const AddTofav = async (uId) => {
    try {
      const data = {
        Id: "0",
        ProId: uId,
      };

      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const formData = new FormData();
      formData.append("AddFavouriteRequest", JSON.stringify(data));
      const response = await axios.post(
        `${baseURL}/api/User-Data/AddFavourite`,
        data,
        { headers }
      );

      if (response?.data?.result === 1) {
        dispatch(addFavItem({ uId: uId, tableUId: response?.data?.data?.uId }));
        setFavInProgress((prevState) => ({
          ...prevState,
          [uId]: false,
        }));
      } else {
        toast.error("Try Again!");
        setFavInProgress((prevState) => ({
          ...prevState,
          [uId]: false,
        }));
      }
    } catch (error) {
      toast.error("Try Again!");
      setFavInProgress((prevState) => ({
        ...prevState,
        [uId]: false,
      }));
    }
  };

  const DeleteToFav = async (uId) => {
    const tableUId = favouriteData?.favItems?.find((item) => item?.uId === uId);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
      };

      const response = await axios.delete(
        `${baseURL}/api/User-Data/DeleteFavourite/${tableUId?.tableUId}`,
        config
      );

      if (response?.data?.result === 1) {
        dispatch(removeFavItem({ uId: uId }));
        setFavInProgress((prevState) => ({
          ...prevState,
          [uId]: false,
        }));
      } else {
        toast.error("Delete Again!");
        setFavInProgress((prevState) => ({
          ...prevState,
          [uId]: false,
        }));
      }
    } catch (error) {
      toast.error("Delete Again!");
      setFavInProgress((prevState) => ({
        ...prevState,
        [uId]: false,
      }));
    }
  };

  //format date
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const ModalComponent = ({
    open,
    handleClose,
    handleAddMore,
    handleAllClear,
  }) => {
    if (!open) {
      return null;
    }
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="row">
            <div className="col-sm-6 col-12 font recordheading">
              {compareListingData?.length >= 1 &&
                `${compareListingData?.length} Records Selected`}
            </div>

            <div className="col-sm-6 col-12 alignright">
              {" "}
              <Button onClick={handleClose}>
                <CloseIcon style={{ color: "black" }} />
              </Button>{" "}
            </div>
          </div>
          <div className="row">
            {compareListingData?.map((item, index) => (
              <div key={item.id} className="col-sm-4 col-12">
                <CompareModal
                  n={item}
                  handleRemoveCompare={handleRemoveCompare}
                  i={index}
                />
              </div>
            ))}
            {compareListingData?.length < 3 && (
              <div
                className="col-sm-4 col-12 boxupload2"
                onClick={() => handleAddMore()}
              >
                <div className="x">
                  <AddIcon className="AddIcon" />
                </div>
                <p className="m-0 pt-2 uploadfile">
                  <strong>Add More </strong>
                </p>
                <p className="m-0 pt-2 uploadfiletxt">Add More To Compare</p>
              </div>
            )}
            {compareListingData?.length < 2 && (
              <div
                className="col-sm-4 col-12 boxupload2"
                onClick={() => handleAddMore()}
              >
                <div className="x">
                  <AddIcon className="AddIcon" />
                </div>
                <p className="m-0 pt-2 uploadfile">
                  <strong>Add More </strong>
                </p>
                <p className="m-0 pt-2 uploadfiletxt">Add More To Compare</p>
              </div>
            )}
          </div>
          <React.Fragment>
            <div className="col-12 mt-3">
              <button
                onClick={handleCompareClick}
                disabled={compareListingData?.length < 2 || isLoading}
                className="Comparebutton w-100 font d-flex justify-content-center"
              >
                {isLoading ? (
                  <ThreeCircles
                    visible={true}
                    height="30"
                    width="30"
                    color="#fff"
                    ariaLabel="three-circles-loading"
                  />
                ) : (
                  `Compare`
                )}
              </button>

              <button
                onClick={handleAllClear}
                className="EraseComparison mt-2 w-100 font"
              >
                Remove Current Selection
              </button>
            </div>
            {/* <div className="row pt-3">
            <div className="col-12 p-0">
              <button className="BorderBluebutton w-100  font">
                Upload 2 Flyers
              </button>
            </div>
          </div> */}
          </React.Fragment>
        </Box>
      </Modal>
    );
  };

  const CompareItemModal = ({ open, handleClose }) => {
    if (!open) {
      return null;
    }
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...bigModalstyle, width: "98%", marginLeft: "1%" }}>
          <div>
            <div className="row p-2 mx-0 modaldisplay">
              <div className="col p-0">
                {" "}
                <p className="m-0 textcompare">Compare</p>
              </div>
              <div className="col p-0 modaldisplay">
                {" "}
                <Button onClick={handleClose}>
                  <CloseIcon style={{ color: "white" }} />
                </Button>
              </div>
            </div>
            <div className="row compareModal_main p-3">
              {compareCompleteData?.map((n, i) => (
                <ChildModalComparison key={i} n={n} />
              ))}
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        handleAddMore={handleAddMore}
        handleAllClear={handleAllClear}
      />

      <CompareItemModal
        open={isCompareModalOpen}
        handleClose={handleCompareModalClose}
      />

      <div className="mapandListing_card">
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <div className="p-0">
              <Card
                className={`main_cardmi ${
                  item?.assignmentPkgs?.some(
                    (pkg) =>
                      pkg.category === "Highlight Add" &&
                      !pkg.isExpired &&
                      pkg.isPaymentReceived
                  )
                    ? "HighlightCard_bg"
                    : ""
                }`}
                onMouseEnter={
                  showOnhovered
                    ? () => handleHoveredListCard(item?.slug)
                    : undefined
                }
                onMouseLeave={
                  showOnhovered ? () => handleHoveredListCard("") : undefined
                }
              >
                {/* <NavLink className="linkline" to={`/${cardPath}${item.slug}`}> */}
                <NavLink
                  className="linkline"
                  to={`/assignment-sale/${item.slug}`}
                >
                  <CardMiddleSection item={item} />
                </NavLink>
                <div className="heart_compareMain">
                  <div className="one">
                    {" "}
                    <CardActions>
                      <div
                        className={
                          isMobileView
                            ? "grey_card m-v-item-for-hide"
                            : "grey_card "
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event) =>
                                handleCheckboxChange(event, item)
                              }
                              checked={compareListingData.some(
                                (compareItem) => compareItem.id === item.id
                              )}
                              className="compare_box"
                              style={{
                                color: "#676767",
                              }}
                            />
                          }
                          label={
                            <span className="cardMain_compare">Compare</span>
                          }
                        />
                      </div>
                    </CardActions>
                  </div>
                  <div className="two heartandShare aligncenter justifycenter">
                    {" "}
                    <CardActions disableSpacing>
                      <div className="p-2 heart_Iconmb aaligner">
                        {FavInProgress[item.uId] ? (
                          <Loader size="24" />
                        ) : cardIde.includes(item.uId) ? (
                          <AiFillHeart
                            className="heartIcon outlined"
                            onClick={() => handleHeartClick(item?.uId)}
                          />
                        ) : (
                          <AiOutlineHeart
                            className="heartIcon filled"
                            onClick={() => handleHeartClick(item?.uId)}
                          />
                        )}
                      </div>

                      <ShareFeature
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        linkSlug={item?.slug}
                      />
                    </CardActions>
                  </div>
                </div>
                <p className="cardMain_date web_closehide m-0">
                  <strong className="text-danger">Closing:</strong>
                  <span>
                    {" "}
                    <strong className="dated text-black">
                      {formatDate(item?.occupanycDate)}
                    </strong>
                  </span>
                </p>
              </Card>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
