import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";

export default function Marquees({webHighlight}) {

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={isMobileView ? "pb-2" : "pb-4"}>
        <Marquee direction="left" className="marqmarquez">
          {webHighlight}
        </Marquee>
    </div>
  );
}
