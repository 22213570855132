import React, { useState, useEffect, useRef } from "react";
import { Box, Modal } from "@mui/material";
import PropertyNotes from "../PropertyNotes";
import { CloseIcon } from "../../../../SvgIcons/Icons";

const RatingModal = ({
  data,
  isUserlogin,
  open,
  handleClose,
  setIsSignUpModalOpen,
  setSignUpModalHeading
}) => {
  const [fadeOut, setFadeOut] = useState(false);
  const modalRef = useRef(null);

  const closeModal = () => {
    setFadeOut(true);
    setTimeout(() => {
      handleClose();
      setFadeOut(false);
    }, 300);
  };

  useEffect(() => {
    if (open && modalRef.current) {
      modalRef.current.classList.remove("Prop_detail_infoModal_fadeOut");
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={`Prop_detail_infoModal ${
          fadeOut ? "Prop_detail_infoModal_fadeOut" : ""
        }`}
        ref={modalRef}
      >
        <div className="w-100">
          <PropertyNotes
            isUserlogin={isUserlogin}
            setIsSignUpModalOpen={setIsSignUpModalOpen}
            onClose={closeModal}
            setSignUpModalHeading={setSignUpModalHeading}
            isModal={true}
            data={data}
          />
        </div>
        <div
          className="position-absolute Property_detailsWeb_hide"
          style={{ top: "20px", right: "15px" }}
          onClick={closeModal}
        >
          <CloseIcon />
        </div>
      </Box>
    </Modal>
  );
};

export default RatingModal;
