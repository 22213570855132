import React, { useEffect, useState,memo } from "react";
import { PropertyData } from "../PropertData";
import { NavLink } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const PopularSearches = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  return (
    <>
      <Accordion
        className="Property_details_Web_Accordion py-2 Accordion_withBlueBackground PropertyDetails_MobileAccordion"
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon className="Property_minusIcon" />
            ) : (
              <AddIcon className="Property_plusIcon" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>
            <h2 className="property_Details_heading">Popular Searches</h2>
            {!isMobileView && (
              <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
                Find what others are looking for—explore the most sought-after
                properties and trends.
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
              Find what others are looking for—explore the most sought-after
              properties and trends.
            </p>
          )}
          <div className="row mt-3">
            {PropertyData?.popularSearches?.map((item, index) => {
              return (
                <div className="col-md-4 col-12" key={index}>
                  <NavLink className="Details_SearchesLink">
                    <p className="PropertyDetails_Mbl_f14">{item}</p>
                  </NavLink>
                </div>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(PopularSearches);
