import React, { useEffect, useState,memo } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import PieStatisticsChart from "./PieStatisticsChart";
import RemoveIcon from "@mui/icons-material/Remove";
import { PropertyData } from "../PropertData";
import { AreaStatisticsIcon } from "../../../SvgIcons/Icons";

const AreaStatistics = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(isMobileView ? false : true);
  const [value, setValue] = useState(0);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  };

  return (
    <div className="w-100">
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className="Property_details_Web_Accordion Property_simpleAccordion PropertyDetails_MobileAccordion py-2"
        sx={{ background: "#ffff" }}
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon className="Property_minusWebGreyIcon" />
            ) : (
              <AddIcon className="Property_plusWebGreyIcon" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={isMobileView ? "d-flex align-items-center gap-2" : ""}
          >
            {isMobileView && <AreaStatisticsIcon />}
            <h2 className="property_Details_heading">Statics of this area</h2>
            {!isMobileView && (
              <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
                Statistics of this area provide a clear snapshot of local
                conditions, guiding informed decisions and effective community
                planning.
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
              Statistics of this area provide a clear snapshot of local
              conditions, guiding informed decisions and effective community
              planning.
            </p>
          )}
          <Box className="mt-3">
            <AppBar
              position="static"
              sx={{
                boxShadow: "none",
              }}
            >
              <Tabs
                value={value}
                onChange={handleTabChange}
                textColor="inherit"
                sx={{
                  backgroundColor: "#fff",
                }}
                className="Property_details_tabs"
                elevation={0}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                {PropertyData?.statics?.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab.label}
                    sx={{
                      fontSize: tab.fontSize,
                      border: "1px solid #CCCCCC",
                      fontWeight: "700",
                      padding: "15px 30px",
                    }}
                  />
                ))}
              </Tabs>
            </AppBar>
            {PropertyData?.statics?.map((tab, index) => (
              <TabPanel
                key={index}
                value={value}
                index={index}
                className="PropertyDetail_tabPanel"
              >
                <div className="mt-3">
                  <PieStatisticsChart data={tab?.content} />
                </div>
              </TabPanel>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(AreaStatistics);
