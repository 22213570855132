import React, { useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ArticleIcon } from "../../../../SvgIcons/Icons";
import ArticleCard from "./ArticleCard";
import ArticleSlider from "./ArticleSlider";

const RealtorArticles = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  const slides = Array(6).fill(null);
  const OPTIONS = { slidesToScroll: "auto" };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div
          className={
            isMobileView ? "d-flex flex-column" : ""
          }
        >
          <div className="d-flex align-items-center gap-2">
            {isMobileView && <ArticleIcon />}
            <h2 className="property_Details_heading">Umar Khan's Article’s</h2>
          </div>
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
            Explore articles by Umar Khan, providing expert insights on
            assignment sales, and neighborhood overviews. Each article is
            crafted to offer valuable information for both buyers and sellers,
            covering tips, market updates, and detailed guides to help you make
            informed property decisions
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-100 mt-3">
          <ArticleCard isMainCard={true}/>
        </div>
        <div className="w-100 mt-3">
            <ArticleSlider slides={slides} options={OPTIONS}/>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(RealtorArticles);
