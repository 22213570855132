import React from 'react'
import CommunityComposedChart from './CommunityComposedChart'

const CommunityPopularityScore = () => {

  const data = [
    { "year": "2016-02", "sold": 600, "popularity": '580' },
    { "year": "2017-10", "sold": 100, "popularity": '520' },
    { "year": "2018", "sold": 400, "popularity": '200' },
    { "year": "2019", "sold": 200, "popularity": '50' },
    { "year": "2020", "sold": 300, "popularity": '100' },
    { "year": "2021-10", "sold": 300, "popularity": '150' },
    { "year": "2022", "sold": 50, "popularity": '200' }
  ];

  return (
    <div className='w-100'>
      <h2 className='property_Details_heading'>Community Popularity Score and Sold Listings</h2>
      <p className='PropertyDetails_Buy_reason PropertyDetails_Mbl_f14'>Toronto / Waterfront Communities C8 / Condo Apt
        The Community Popularity Score is a measure of online activity, like listing views, in this community.</p>

      <div>
        <CommunityComposedChart data={data} />
      </div>
    </div>
  )
}

export default CommunityPopularityScore
