import "./ResultNotFound.css";

const ResultNotFound = (props) => {
  return (
    <div className="no__FoundMain font px-2">
      <div className="no__Found__img">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="73"
          height="72"
          viewBox="0 0 73 72"
          fill="none"
        >
          <path
            d="M63.5005 36.0001L36.5005 9.00006L9.50049 36.0001H15.5005V57.0001C15.5005 58.5914 16.1326 60.1175 17.2578 61.2427C18.3831 62.3679 19.9092 63.0001 21.5005 63.0001H35.6005"
            stroke="#676767"
             strokeWidth="4"
             strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.5005 63.0001V45.0001C27.5005 43.4088 28.1326 41.8826 29.2578 40.7574C30.3831 39.6322 31.9092 39.0001 33.5005 39.0001H39.5005M61.1005 60.6001L66.5005 66.0001M45.5005 54.0001C45.5005 56.387 46.4487 58.6762 48.1365 60.364C49.8244 62.0519 52.1135 63.0001 54.5005 63.0001C56.8874 63.0001 59.1766 62.0519 60.8645 60.364C62.5523 58.6762 63.5005 56.387 63.5005 54.0001C63.5005 51.6131 62.5523 49.3239 60.8645 47.6361C59.1766 45.9483 56.8874 45.0001 54.5005 45.0001C52.1135 45.0001 49.8244 45.9483 48.1365 47.6361C46.4487 49.3239 45.5005 51.6131 45.5005 54.0001Z"
            stroke="#676767"
             strokeWidth="4"
             strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
        <p className="text-center pt-3" style={{fontFamily: 'Open Sans' , color:'#282828' , fontWeight: '600'}}>
          We appologize, but it seems that no result were found for your search
        </p>
        <p className="text-center pt-3"  style={{fontFamily: 'Open Sans' , color:'#676767'}}>
          Try expanding your search by exploring nearby cities, moving the map,
          or adjusting your filters.
        </p>
      {/* <div>
            <button className="no__FoundButton" onClick={props.onClick}>
                Refine Your Search
            </button>
        </div> */}
    </div>
  );
};

export default ResultNotFound;
