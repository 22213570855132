import React from "react";

const SimpleMapCard = ({ data, showMore, setShowMore, setHoveredCardId }) => {
  const toggleExpand = () => {
    setShowMore(!showMore);
  };

  const CardData = data || [];
  const CardDataToShow = showMore ? CardData : CardData.slice(0, 6);

  const handleMouseEnter = (placeId) => {
    setHoveredCardId(placeId);
  };

  const handleMouseLeave = () => {
    setHoveredCardId(null);
  };

  return (
    <>
      <div className="row mx-0 mt-1 g-3">
        {CardDataToShow?.map((item, i) => {
          return (
            <div className="col-lg-6 col-12" key={i}>
              <div
                style={{
                  border: "1px solid #E2E2E2",
                  borderRadius: "4px",
                  padding: "16px",
                }}
                className="explore_propertyCard h-100"
                onMouseEnter={() => handleMouseEnter(item?.id)}
                onMouseLeave={handleMouseLeave}
              >
                <h5>{item?.name}</h5>
                <h6
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {item?.distance >= 1000
                    ? `${(item?.distance / 1000).toFixed(1)} km`
                    : `${Math.round(item?.distance)} m`}
                </h6>
                <h6
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {item?.duration} mins walk
                </h6>
              </div>
            </div>
          );
        })}
      </div>

      {CardData?.length > 6 && !showMore ? (
        <button onClick={toggleExpand} className="Detail_showMoreBtn p-0 mt-2">
          Show all
        </button>
      ) : null}
    </>
  );
};

export default SimpleMapCard;
