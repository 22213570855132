import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import './SubmitAssignment.css'
// import PricingCards from '../Pricing/pricingCards'
import { FiUpload } from 'react-icons/fi'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material'
import dayjs from 'dayjs';
import { BiBed } from 'react-icons/bi'
import BathtubIcon from '@mui/icons-material/Bathtub'
import HouseIcon from '@mui/icons-material/House'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import { SiGooglephotos } from 'react-icons/si'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'universal-cookie'
import { useFormik } from 'formik'
import { SubmissionSchema } from './SubmissionSchema'
import Autocomplete from '@mui/material/Autocomplete'
import Loader from '../Loader/Loader'
import baseURL from '../../config'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {
  addImageData,
  removeImageData,
  clearImageData
} from '../../ReduxStore/Slices/ImageUploadSlice'
import Footer from '../Footer/Footer'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import FormattedField from './Formating'
import starterbadge from '../../images/StarterBadge.png'
// import elitebadge from '../../images/ElitteBadge.svg'
// import premiumbadge from '../../images/PremiumBadge.png'
import ExtraPkg from '../GetQuotes/ExtraPkg'
import PayModal from '../../Dashboard/Components/Listing/PayModal'
import jwtDecode from 'jwt-decode'

// Modal Css Start
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  border: '1px solid #FFFFFF',
  borderRadius: '12px',
  p: 2
}

// Modal css end
export default function SubmitAssignment() {

  const location = useLocation()
  // const NavigationData = location.state 
  const { assignmentId } = useParams()
  const inputRef = useRef(null)

  const cookies = new Cookies()
  const jwt_token = cookies.get('jwt_authorization')
  const [selectedImage, setSelectedImage] = useState(null)
  const [images, setImages] = useState([])
  // eslint-disable-next-line
  const [selectedOption, setSelectedOption] = useState('')
  const [, setSelectedOption2] = useState('')
  const [mediaCat, setMediaCat] = useState('')
  const [selectedPackages, setSelectedPackages] = useState([]);
  const dispatch = useDispatch()
  // Upload image Modal states
  const [open, setOpen] = React.useState(false)
  const [editOpen, setEditOpen] = React.useState(false)
  // Popup select choices
  const [clabel, setCLabel] = useState('Select')
  const [llabel, setLLabel] = useState('Select')
  const [caption, setCaption] = useState('')
  // For the Typing Input
  const [inputValue, setInputValue] = useState('')
  const [newFilteredCities, setNewFilteredCities] = useState([])
  // For the Second Input
  const [inputValue2, setInputValue2] = useState('')
  const [developerName, setDeveloperName] = useState('')
  const [submissionLoader, setSubmissionLoader] = useState(false)
  const [newFilteredCities2, setNewFilteredCities2] = useState([])
  const [developerNameOpt, setDeveloperNameOpt] = useState([])
  const [imgName, setImgName] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isfieldfilled, setisfieldfilled] = useState(false)
  const [isdeveloperfilled, setissdeveloperFilled] = useState(false)
  const [isAddressFilled, setisAddressfilled] = useState(false)
  const [AssignmentMediaID, SetAssignmentMediaID] = useState(1)
  const [PrimaryError, setPrimaryError] = useState(false)
  const [showPayModal, setShowPayModal] = useState(false);
  const [showPayLaterBtn, setShowPayLaterBtn] = useState(true)

  let UserRole = ''
  if (jwt_token) {
    let token = cookies.get('jwt_authorization')
    const decode = jwtDecode(token)
    UserRole =
      decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
  }

  const [, setUpdatedAssignment] = useState({
    slug: '',
    created: '',
    dailyUpdate: '',
    isFeatured: '',
    isAssignmentSoldData: '',
    other: '',
    uId: '',
    verify: ''
  })
  const [
    inputValue2ChangedByAutocomplete,
    setInputValue2ChangedByAutocomplete
  ] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  // The data Coming from the Redux Store

  const handleClosePayModal = () => {
    setShowPayModal(false)
  }

  const imageData = useSelector(state => state.imageUploadSlice)
  const formInitialValues = {
    agentName: '',
    BrokerageName: '',
    Developer: '',
    PropStatus: '',
    OccupanycDate: '',
    OccupancyDue: '',
    UpgradeDepositPaid: '',
    UpgradeBalance: '',
    TotalToSeller: '',
    LevyFee: '',
    MaintainenceFee: '',
    ParkingType: '',
    LotWidth: '',
    Garage: '',
    levyCharges: '',
    Colorselectionchosen: '',
    LeaseDuringOccupancy: '',
    MainIntersection: '',
    Highlight: '',
    // unitNo: '',
    assigntype: '',
    listPrice: '',
    originalPrice: '',
    originalDeposit: '',
    CooperatingCommission: '',
    styl: '',
    exactSize: '',
    floor: '',
    exposure: '',
    noOfBeds: '',
    noOfWash: '',
    balcony: '',
    parking: '',
    Locker: '',
    // occupancyDate: "",
    // upgradeCharges: '',
    mediaCategory: '',
    city: '',
    postalcode: '',
    region: '',
    latitude: '',
    longitude: '',
    country: '',
    source: 'Facebook',
    sourceName: '',
    sourceNumber: '',
    sourceEmail: '',
    sourceId: '',
    sourceUrl: '',
    listType: 'Assignment',
    showTo: null,
  }

  useEffect(() => {
    if (assignmentId) {
      dispatch(clearImageData())

      const headers = {
        Authorization: `Bearer ${jwt_token}`
      }

      axios
        .put(`${baseURL}/api/Assignment/GetAssignmentbyId/${assignmentId}`, {
          headers
        })
        .then(response => {
          const apiData = response?.data?.data
          setUpdatedAssignment(apiData)
          // apiData.assignmentMedias.forEach((media) => {
          //   const imageData = {
          //     imgName: media.fileName,
          //     caption: "Hello",
          //     logo: "Yes",
          //     category: media.mediaCategory,
          //     imageURL: `${baseURL}/${media.fileUrl}`,
          //   };

          //   dispatch(addImageData(imageData));
          // });

          SetAssignmentMediaID(apiData.id)
          setInputValue(apiData.projectName)
          setInputValue2(apiData.address)
          setDeveloperName(apiData.developer)
          const apiInitialValues = {
            agentName: apiData?.agent === false ? 'No' : 'Yes',
            Colorselectionchosen: apiData?.colorselectionchosen,
            LeaseDuringOccupancy: apiData?.leaseDuringOccupancy,
            BrokerageName: apiData?.brokerageName || '',
            PropStatus: apiData?.propStatus || '',
            LotWidth: apiData?.lotWidth || '',
            MainIntersection: apiData?.mainIntersection || '',
            assigntype: apiData?.type || '',
            OccupancyDue: apiData?.occupancyDue || '',
            listPrice: apiData?.assignmentPrice || '',
            originalPrice: apiData?.originalPrice || '',
            originalDeposit: apiData?.depositToBuilder || '',
            CooperatingCommission: apiData?.commission || '',
            UpgradeBalance: apiData?.upgradeBalance || '',
            LevyFee: apiData?.levyFee || '',
            MaintainenceFee: apiData?.maintainenceFee || '',
            styl: apiData?.style || '',
            exactSize: apiData?.interiorSize || '',
            floor: apiData?.floorRange || '',
            exposure: apiData?.exposure || '',
            noOfBeds: apiData?.bed || '',
            noOfWash: apiData?.bath || '',
            parking: apiData?.parking || '',
            ParkingType: apiData?.parkingType || '',
            Locker: apiData?.locker || '',
            // levyCharges: apiData.levyCharges || "",
            balcony: apiData?.exteriorSize || '',
            UpgradeDepositPaid: apiData?.upgradeDepositPaid || '',
            city: apiData?.city || '',
            country: apiData?.country || '',
            region: apiData?.region || '',
            postalcode: apiData?.postalcode || '',
            latitude: apiData?.latitude || '',
            longitude: apiData?.longitude || '',
            Highlight: apiData?.highlight || '',
            source: apiData?.source || '',
            sourceName: apiData?.sourceName || '',
            sourceNumber: apiData?.sourceNumber || '',
            sourceEmail: apiData?.sourceEmail || '',
            sourceId: apiData?.sourceId || '',
            sourceUrl: apiData?.sourceUrl || '',
            listType: apiData?.listType || '',
            showTo: apiData?.showTo || null,
            OccupanycDate: dayjs(apiData?.occupanycDate).format('YYYY-MM-DD') || '' || '',
          }

          formik.setValues(apiInitialValues)
        })
        .catch(error => {
          console.error(error)
          navigate('/submit-assignment')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt_token])

  useEffect(() => { }, [imageData])


  const packageNamesetting = (category) => {
    switch (category) {
      case "TopHomePageGallery":
        return "Top Home Page Gallery";
      case "HomePageTicker":
        return "Home Page Ticker";
      case "HighlightAdd":
        return "Highlight Add";
      case "Tags":
        return "Tags";
      default:
        return category;
    }
  };

  const AssignmentPkgsData = (selectedPackages) => {
    return selectedPackages?.map(pkg => ({
      AssignmentId: 0,
      Category: packageNamesetting(pkg.category),
      Price: pkg.price,
      Days: pkg.days,
      IsExpired: true,
      IsPaymentReceived: false
    }));
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: SubmissionSchema,
    onSubmit: async (values, { resetForm }) => {
      if (inputValue === '' && inputValue2 === '' && developerName === '') {
        // toast.error('Project Name, Developer Name and Address is also Required')
        return
      } else if (inputValue.length < 3) {
        // toast.error('Project Name must be atleast 3 character')
        return
      } else if (
        inputValue2.length === 0 ||
        formik.values.city.length === 0 ||
        formik.values.country.length === 0 ||
        formik.values.region.length === 0 ||
        formik.values.postalcode.length === 0 ||
        formik.values.latitude === null || formik.values.latitude === '' ||
        formik.values.longitude === null || formik.values.longitude === ''
      ) {
        // toast.error('Kindly Fill the complete Address')
        return
      } else if (developerName.length < 3) {
        // toast.error('Developer Name must be atleast 3 character')
        return
      }
      setIsSubmitted(true)

      // if (selectedPackages.length > 0) {
      //   setShowPayModal(true);
      //   return;
      // }

      await SubmitFormData(values, resetForm);
    }
  })

  const SubmitFormData = async (values, resetForm) => {
    setSubmissionLoader(true)
    const assignmentMedias = imageData?.map((image, index) => {
      const AssignmentId = assignmentId ? AssignmentMediaID : index
      return {
        AssignmentId,
        PrimaryImage: image.logo === 'true' ? true : false,
        MediaCategory: image.category,
        Verify: false,
        FileName: image.imgName,
        Extension: 'jpeg',
        FileId: index + 1
      }
    })
    const userid = assignmentId ? AssignmentMediaID : 0
    const data = {
      id: userid,
      Agent: values.agentName === 'Yes',
      BrokerageName: values.BrokerageName,
      ProjectName: inputValue.trim(),
      Developer: developerName,
      MainIntersection: values.MainIntersection,
      Address: inputValue2,
      // UnitNo: '1',
      City: values.city,
      Country: values.country,
      Region: values.region,
      Postalcode: values.postalcode,
      Latitude: values.latitude,
      Longitude: values.longitude,
      Type: values.assigntype,
      PropStatus: values.PropStatus,
      LotWidth: values.LotWidth,
      AssignmentPrice: values.listPrice,
      OriginalPrice: values.originalPrice,
      DepositToBuilder: values.originalDeposit,
      TotalToSeller: values.TotalToSeller,
      OccupancyDue: values.OccupancyDue,
      UpgradeDepositPaid: values.UpgradeDepositPaid,
      // UpgradeCharges: values.UpgradeCharges,
      UpgradeBalance: values.UpgradeBalance,
      LevyFee: values.LevyFee,
      MaintainenceFee: values.MaintainenceFee,
      Commission: values.CooperatingCommission,
      Style: values.styl,
      InteriorSize: values.exactSize,
      FloorRange: values.floor,
      Exposure: values.exposure,
      Bed: values.noOfBeds,
      Bath: values.noOfWash,
      ExteriorSize: values.balcony,
      colorselectionchosen: values.Colorselectionchosen === 'Yes',
      ParkingType: values.ParkingType,
      Parking: values.parking,
      LeaseDuringOccupancy: values.LeaseDuringOccupancy === 'Yes',
      Locker: values.Locker,
      OccupanycDate: values.OccupanycDate,
      Highlight: values.Highlight,
      // levyCharges: values.levyCharges,
      // Garage: values.Garage,
      // UnitLotNumber: values.unitNo,
      Source: values.source,
      SourceName: values.sourceName,
      SourceNumber: values.sourceNumber,
      SourceEmail: values.sourceEmail,
      SourceId: values.sourceId,
      SourceUrl: values.sourceUrl,
      ListType: values.listType,
      ShowTo: values.showTo,

    }
    if (!assignmentId) {
      data.AssignmentMedias = assignmentMedias;
      data.AssignmentPkgs = AssignmentPkgsData(selectedPackages);
    }

    const config = {
      headers: {
        Authorization: `Bearer ${jwt_token}`
      }
    }
    let imageCounter = 1
    const formData = new FormData()
    formData.append('AssignmentRequest', JSON.stringify(data))
    images.forEach((image, index) => {
      const imageName = `${imageCounter}_${image.name}.jpeg`
      formData.append(imageName, image)
      imageCounter++
    })

    await axios
      .post(`${baseURL}/api/Assignment/AddUpdate`, formData, config)
      .then((response) => {
        if (response.data.result === 1) {
          setSelectedPackages([])
          setSubmissionLoader(false)
          setIsSubmitted(false)
          // if (assignmentId) {
          //   // toast.success("Assignment Updated Successfully!");
          // } else {
          //   // toast.success("Assignment Submitted Successfully!");
          // }
          dispatch(clearImageData())
          setInputValue('')
          setInputValue2('')
          setDeveloperName('')
          resetForm()
          localStorage.removeItem('formValues');
          localStorage.removeItem('address');
          localStorage.removeItem('developerName');
          localStorage.removeItem('projectName');
          navigate('/dashboard/assignments-listing/Pending');
          setShowPayModal(false)
          // if (assignmentId) {
          //   navigate(-1);
          // }
          // else {
          //     navigate('/get-quotes', {
          //       state: {
          //         price: NavigationData.price,
          //         border: NavigationData.border,
          //         color: NavigationData.color,
          //         name: NavigationData.name
          //       }
          //   })
          //   setShowPayModal(true)
          // }
        } else {
          setSubmissionLoader(false)
          toast.error('Data Not Sent!')
        }
      })
      .catch((error) => {
        setSubmissionLoader(false)
        toast.error('There was a problem sending Data, Please Try Again!')
      })
  }


  const handlePayLater = async () => {
    setShowPayModal(false)
    await SubmitFormData(formik?.values , formik?.resetForm)
  };



  const isOptionEqualToValue = (option, value) => {
    return option.code === value.code && option.label === value.label
  }

  // const handleCaptionChange = (event) => {
  //   setCaption(event.target.value);
  // };
  // const handleChange2 = (event) => {
  //   setSelectedOption2(event.target.value);
  // };
  const handleMediaCatChange = event => {
    setMediaCat(event.target.value)
  }
  const [modalData, setModalData] = useState({
    category: '',
    setAsLogo: 'true',
    caption: '',
    imageURL: ''
  })
  const handleEditOpen = item => {
    setModalData({
      category: item.category || '',
      setAsLogo: item.logo || 'false',
      caption: item.caption || '',
      imageURL: item.imageURL || ''
    })
    setEditOpen(true)
  }

  const reduxData = {
    imgName: imgName,
    // extension: ,
    caption: caption,
    logo: modalData.setAsLogo,
    category: mediaCat,
    imageURL: selectedImage
  }

  const edittedData = {
    caption: modalData.caption,
    logo: modalData.setAsLogo,
    category: modalData.category,
    imageURL: modalData.imageURL
  }

  const handleUploadClick = e => {
    e.preventDefault() // Prevents the default behavior (form submission or link navigation)
    // Check if any element in imageData has a logo and reduxData also has a logo
    const hasExistingLogo = imageData.some(
      e => e.logo === 'true' && reduxData.logo === 'true'
    )
    if (hasExistingLogo) {
      setPrimaryError(true)
      return
    }

    dispatch(addImageData(reduxData))
    setOpen(false)
    setCaption('')
    setMediaCat('')
    setSelectedOption('')
    setSelectedOption2('')
    setSelectedImage('')
    setPrimaryError(false)
  }

  const handleEditUploadClick = () => {
    // Check if any element in imageData has a logo and edittedData also has a logo
    const hasExistingLogo = imageData.some(
      e =>
        e.logo === 'true' &&
        edittedData.logo === 'true' &&
        e.imageURL !== edittedData.imageURL
    )
    if (hasExistingLogo) {
      setPrimaryError(true)
      return
    }

    dispatch(removeImageData(edittedData.imageURL))
    dispatch(addImageData(edittedData))
    setEditOpen(false)
    setPrimaryError(false)
  }

  // code for image remove when change the component
  useEffect(() => {
    dispatch(clearImageData())
  }, [dispatch])

  // eslint-disable-next-line
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setPrimaryError(false)
  }
  const handleEditClose = () => {
    setEditOpen(false)
    setPrimaryError(false)
  }

  const fileInputRef = useRef(null)

  const handleImageOpen = () => {
    setSelectedImage('')
    fileInputRef.current.click()
  }

  const handleImageChange = evt => {
    const file = evt.target.files[0]
    if (file) {
      // const newImages = [...images, file];
      setImgName(file.name)
      setSelectedImage(URL.createObjectURL(file))
      setImages([...images, file])
      setOpen(true)
    }
  }

  const handleBoxDelete = url => {
    dispatch(removeImageData(url))
  }
  // Function to calculate the TotalToSeller value
  const calculateTotalToSeller = () => {
    const listPrice = parseInt(formik.values.listPrice) || 0
    const originalPrice = parseInt(formik.values.originalPrice) || 0
    const originalDeposit = parseInt(formik.values.originalDeposit) || 0

    // Perform your calculation here
    return listPrice - originalPrice + originalDeposit
  }

  // Calculate TotalToSeller and update the state
  const updatedTotalToSeller = calculateTotalToSeller()
  if (formik.values.TotalToSeller !== updatedTotalToSeller) {
    formik.setFieldValue('TotalToSeller', updatedTotalToSeller)
  }

  // For the Typing Input Change
  const typingTimeout = useRef()
  const handleInputChange = async (event, value) => {
    let inputValue = value
    // setisfieldfilled(false);
    // var trimmedInput = inputValue.trim();
    // var words = trimmedInput.split(/\s+/);
    // if (words.length > 4) {
    //   inputValue = trimmedInput.split(/\s+/).slice(0, 4).join(" ");
    // }

    setInputValue(inputValue)

    if (inputValue.trim() === '') {
      setNewFilteredCities([])
      return
    }

    if (inputValue.length >= 3) {
      setisfieldfilled(false)
      if (typingTimeout.current) {
        clearTimeout(typingTimeout.current)
      }
      typingTimeout.current = setTimeout(async () => {
        try {
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt_token}`
            }
          }
          const response = await axios.get(
            `https://config.assignmentsold.ca/api/User-Data/GetProjectNameResponse/${inputValue}`,
            config
          )
          setNewFilteredCities(response.data?.data.map(n => n.responseTitle))
        } catch (error) { }
      }, 500)
    }
  }

  const handleOptionSelect = async selectedOption => {
    if (!selectedOption) {
      formik.setFieldValue('city', '')
      formik.setFieldValue('postalcode', '')
      formik.setFieldValue('country', '')
      formik.setFieldValue('region', '')
      formik.setFieldValue('latitude', '')
      formik.setFieldValue('longitude', '')
      return // Exit the function early
    }
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${selectedOption?.responseDesc}.json?proximity=ip&access_token=pk.eyJ1IjoiY2hpc2h0aS1hZG1pbiIsImEiOiJjbGw1N2FsNTcwYzFsM2ttazRzcnlhanpsIn0.h1YsNrhMozZkeWn6jcYTwg`
      )
      if (response?.data?.features[0]) {
        formik.setFieldValue('longitude', response.data.features[0].center[0])
        formik.setFieldValue('latitude', response.data.features[0].center[1])
        response.data.features[0].context.forEach((e, index) => {
          if (e?.id.includes('place')) {
            formik.setFieldValue('city', e.text)
          } else if (e?.id.includes('postcode')) {
            formik.setFieldValue('postalcode', e.text)
          } else if (e?.id.includes('country')) {
            formik.setFieldValue('country', e.text)
          } else if (e?.id.includes('region')) {
            formik.setFieldValue('region', e.text)
          }
        })
      }
    } catch (error) {
      toast.error('Something wrong to fill the fields')
    }
  }

  const handleInputChange2 = async (event, value) => {
    const inputValue2 = value
    if (!inputValue2ChangedByAutocomplete) {
      setInputValue2(inputValue2)
    }

    if (inputValue2.trim() === '') {
      setNewFilteredCities2([])
      formik.setFieldValue('country', '')
      formik.setFieldValue('city', '')
      formik.setFieldValue('region', '')
      formik.setFieldValue('postalcode', '')
      formik.setFieldValue('latitude', '')
      formik.setFieldValue('longitude', '')
      return
    }

    if (inputValue2.length >= 3) {
      if (typingTimeout.current) {
        clearTimeout(typingTimeout.current)
      }

      typingTimeout.current = setTimeout(async () => {
        try {
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt_token}`
            }
          }
          const response = await axios.get(
            `https://config.assignmentsold.ca/api/User-Data/GetAddressResponse/${inputValue2}`,
            config
          )
          const filteredCities = response.data?.data.map(item => {
            return {
              responseTitle: item.responseTitle,
              responseDesc: item.responseDesc
            }
          })
          // response2?.data?.features[0]?.context.map((e) => {
          //   e?.id.includes("place") && formik.setFieldValue("city", e.text);
          //   e?.id.includes("postcode") && formik.setFieldValue("postalcode", e.text);
          //   e?.id.includes("country") && formik.setFieldValue("country", e.text);
          //   e?.id.includes("region") && formik.setFieldValue("region", e.text);
          // })
          setNewFilteredCities2(filteredCities)
        } catch (error) { }
      }, 300)
    }
  }

  useEffect(() => {
    if (inputValue2.length !== 0 &&
      formik.values.city.length !== 0 &&
      formik.values.country.length !== 0 &&
      formik.values.region.length !== 0 &&
      formik.values.postalcode.length !== 0 && formik.values.latitude !== '' && formik.values.latitude !== ''
    ) {
      setisAddressfilled(false)
    }
  }, [
    formik.values.city,
    formik.values.country,
    formik.values.region,
    formik.values.postalcode,
    formik.values.latitude,
    formik.values.longitude
  ])
  const handleDevelopernameChange = (event, value) => {
    const inputValue = value
    setDeveloperName(inputValue)

    if (inputValue.trim() === '') {
      setDeveloperNameOpt([])
      return
    }
    if (inputValue.length >= 3) {
      setissdeveloperFilled(false)

      if (typingTimeout.current) {
        clearTimeout(typingTimeout.current)
      }

      typingTimeout.current = setTimeout(async () => {
        try {
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt_token}`
            }
          }
          const response = await axios.get(
            `${baseURL}/api/User-Data/GetDeveloperResponse/${inputValue}`,
            config
          )
          setDeveloperNameOpt(response?.data?.data.map(n => n?.responseTitle))
        } catch (error) {
          // Handle error
        }
      }, 300)
    }
  }
  useEffect(() => {
    // get the form values From local storage
    const storedFormValues = localStorage.getItem('formValues')
    const address = localStorage.getItem('address')
    const developer = localStorage.getItem('developerName')
    const project = localStorage.getItem('projectName')
    if (storedFormValues && address && developer && project) {
      formik.setValues(JSON.parse(storedFormValues))
      setInputValue2(JSON.parse(address))
      setDeveloperName(JSON.parse(developer))
      setInputValue(JSON.parse(project))
    }
  }, [])

  useEffect(() => {
    // Store the form values in local storage
    if (!assignmentId) {
      localStorage.setItem('formValues', JSON.stringify(formik.values))
      localStorage.setItem('address', JSON.stringify(inputValue2))
      localStorage.setItem('developerName', JSON.stringify(developerName))
      localStorage.setItem('projectName', JSON.stringify(inputValue))
    }
  }, [formik.values, inputValue2, developerName, inputValue])

  //Auto Focus on Field which is required and not fill by user
  const inputRefs = useRef({});
  const developerRef = useRef();
  const ProjectNameRef = useRef();
  const AddressRef = useRef();

  const AutoFocustoField = () => {
    if (inputValue.length < 3) {
      ProjectNameRef.current.focus();
    } else if (developerName.length < 3) {
      developerRef.current.focus();
    } else if (inputValue2.length === 0 ||
      formik.values.city.length === 0 ||
      formik.values.country.length === 0 ||
      formik.values.region.length === 0 ||
      formik.values.postalcode.length === 0 ||
      formik.values.latitude === null || formik.values.latitude === '' ||
      formik.values.longitude === null || formik.values.latitude === '') {
      AddressRef.current.focus();
    } else {
      const emptyRequiredField = Object.keys(SubmissionSchema.fields).find(
        (fieldName) => !formik.values[fieldName]
      );
      if (emptyRequiredField) {
        inputRefs.current[emptyRequiredField].focus();
      }
    }
  }

  // handle form reset
  const handleFormReset = () => {
    const confirmed = window.confirm('Are you sure you want to reset the form?');
    if (confirmed) {
      formik.resetForm();
      setInputValue('');
      setInputValue2('');
      setDeveloperName('');
    }
  };

  useEffect(() => {
    if (formik.values.ParkingType === 'None') {
      formik.setFieldValue('parking', 0);
    }
  }, [formik.values.ParkingType])

  return (
    <div style={{ background: '#fafafa' }}>
      {/* Edit Modal */}
      <ToastContainer />

      <Modal
        open={editOpen}
        onClose={handleEditClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} className='mob_ImageBox'>
          <div className='font'>
            {/* Row for delete button and heading */}
            <div className='row'>
              <div className='col-lg-6 col-12 align-center'>
                <strong>Add Media</strong>{' '}
              </div>
            </div>
            {/* Row for image icon */}
            <div className='row p-2 d-flex'>
              <div className='col-lg-3 col-12'>
                <img
                  className='randomimages2'
                  src={modalData.imageURL}
                  alt='Listing Img'
                  loading="lazy"
                />
              </div>

              <div className='col-lg-4'>
                <p className='font  m-0'>
                  <strong>Set as Primary</strong>
                </p>
                <FormControl component='fieldset' className='mt-1'>
                  <RadioGroup
                    aria-label='isPrimary'
                    name='isPrimary'
                    value={modalData.setAsLogo}
                    onChange={e =>
                      setModalData({ ...modalData, setAsLogo: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value='true'
                      control={<Radio />}
                      label='Yes'
                    />
                    <FormControlLabel
                      value='false'
                      control={<Radio />}
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {PrimaryError === true ? (
                <div className='col-lg-5 text-danger text-center align-center'>
                  <p>Primary Image Already Selected</p>
                </div>
              ) : null}
            </div>
            {/* Row for inputfields */}
            <div className='row w-100 mob-ImgField'>
              <div className='col-12 mob-ImgField'>
                {/* <p className="font fieldhead m-0">
                  <strong> Set As Logo</strong>
                </p>
                <FormControl fullWidth>
                  <Select
                    id="demo-simple-select"
                    value={modalData.setAsLogo}
                    onChange={(e) =>
                      setModalData({ ...modalData, setAsLogo: e.target.value })
                    }
                    displayEmpty
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl> */}
                <p className='font fieldhead m-0'>
                  <strong> Media Category</strong>
                </p>
                <FormControl fullWidth>
                  <Select
                    id='demo-simple-select'
                    value={modalData.category}
                    onChange={e =>
                      setModalData({ ...modalData, category: e.target.value })
                    }
                    name='mediaCategory'
                    displayEmpty
                  >
                    <MenuItem value='Exterior'>Exterior</MenuItem>
                    <MenuItem value='Interior'>Interior</MenuItem>
                    <MenuItem value='Floor Plan'>Floor Plan</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='col-12 floatright mt-2'>
                <button
                  onClick={handleEditUploadClick}
                  className='btn btn-primary'
                >
                  Upload
                </button>
                <div className='p-1'></div>
                <button onClick={handleEditClose} className='btn btn-danger'>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} className='mob_ImageBox'>
          <div className='font'>
            {/* Row for delete button and heading */}
            <div className='row'>
              <div className='col-lg-6 col-12 align-center'>
                <strong>Add Media</strong>{' '}
              </div>
            </div>
            {/* Row for image icon */}
            <div className='row p-2 d-flex'>
              <div className='col-lg-3 col-12'>
                <img
                  className='randomimages2'
                  src={selectedImage}
                  alt='Listing Img'
                  loading="lazy"
                />
              </div>
              <div className='col-lg-4'>
                <p className='font  m-0'>
                  <strong>Set as Primary</strong>
                </p>
                <FormControl component='fieldset' className='mt-1'>
                  <RadioGroup
                    aria-label='isPrimary'
                    name='isPrimary'
                    value={modalData.setAsLogo}
                    onChange={e =>
                      setModalData({ ...modalData, setAsLogo: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value='true'
                      control={<Radio />}
                      label='Yes'
                    />
                    <FormControlLabel
                      value='false'
                      control={<Radio />}
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              {PrimaryError === true ? (
                <div className='col-lg-5 text-danger text-center align-self-end'>
                  <p>Primary Image Already Selected</p>
                </div>
              ) : null}
            </div>
            {/* Row for inputfields */}
            <div className='row w-100 mob-ImgField'>
              <form action='' onSubmit={handleUploadClick}>
                <div className='col-12 mob-ImgField'>
                  {/* <p className="font fieldhead m-0">
                  <strong> Set As Logo</strong>
                </p>
                <FormControl fullWidth>
                  <Select
                    id="demo-simple-select"
                    value={selectedOption2}
                    onChange={handleChange2}
                    displayEmpty
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl> */}
                  <p className='font fieldhead m-0'>
                    <strong> Media Category</strong>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      id='demo-simple-select'
                      value={mediaCat}
                      onChange={handleMediaCatChange}
                      name='mediaCategory'
                      displayEmpty
                      required
                    >
                      <MenuItem value='Exterior'>Exterior</MenuItem>
                      <MenuItem value='Interior'>Interior</MenuItem>
                      <MenuItem value='Floor Plan'>Floor Plan</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className='col-12 mt-2 floatright'>
                  <button type='submit' className='btn btn-primary'>
                    Upload
                  </button>
                  <div className='p-1'></div>
                  <button onClick={handleClose} className='btn btn-danger'>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
      <div className='container submit_mobContainer font'>
        <div className='main_headings'>
          {' '}
          <div className='d-flex w-100 justify-content-between align-center'>
            <p className='submitheading m-0'> Submit New Assignment</p>
            <img
              style={{ width: '85px', marginTop: '30px' }}
              className='margin_auto_at_760'
              // src={
              //   NavigationData?.name === 'Starter'
              //     ? starterbadge
              //     : NavigationData?.name === 'Premium'
              //       ? premiumbadge
              //       : NavigationData?.name === 'Elite'
              //         ? elitebadge
              //         : null
              // }
              src={starterbadge}
              alt={'starter logo'}
              loading="lazy"
            />
          </div>
          <div className='d-flex justify-content-between align-items-center mt-4 w-100'>
            <p className='infoheading p-0 m-0'>Basic Information</p>
            <button onClick={handleFormReset} className=' Submitbutton p-2'>Reset Form</button>
          </div>
        </div>
        {/* Div for input fields */}
        <div className='py-5'>
          <div className='row'>
            <div className='col-lg-3 col-12'>
              <p className='font fieldheadings m-0'>
                <strong>Are you an Agent?</strong>
              </p>
              <FormControl
                fullWidth
                size='small'
                className='bg-white mob_filedsettings'
              >
                <InputLabel id='demo-simple-select-label'>
                  <span className='font textinfield'>
                    {' '}
                    <AccountCircleOutlinedIcon className='icoonfont' />{' '}
                    {formik.values.agentName === ''
                      ? 'Select'
                      : formik.values.agentName}
                  </span>
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={formik.values.agentName}
                  onChange={formik.handleChange}
                  label='agentName'
                  name='agentName'
                  inputRef={(input) => {
                    inputRefs.current['agentName'] = input;
                  }}
                  sx={{
                    color: '#0253A0',
                    fontWeight: '600'
                  }}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value={'Yes'}>
                    <span className='font '>Yes</span>
                  </MenuItem>
                  <MenuItem value={'No'}>
                    <span className='font'>No</span>
                  </MenuItem>
                </Select>
              </FormControl>
              {isSubmitted ? (
                <span style={{ color: 'red' }}>{formik.errors.agentName}</span>
              ) : null}
            </div>
            <div className='col-lg-3 col-12'>
              {' '}
              <p className='font fieldheadings m-0'>
                <strong>Brokerage Name</strong>
              </p>
              <TextField
                size='small'
                sx={{
                  width: '100%',
                  height: '40px',
                  borderRadius: '4px'
                }}
                className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                value={formik.values.BrokerageName}
                onChange={formik.handleChange}
                id='outlined-basic'
                inputProps={{
                  maxLength: 28
                }}
                label={
                  <span className='font textinfield'>Enter Brokerage Name</span>
                }
                variant='outlined'
                name='BrokerageName'
              />
              {isSubmitted ? (
                <span style={{ color: 'red' }}>
                  {formik.errors.BrokerageName}
                </span>
              ) : null}
            </div>

            <div className='col-lg-3 col-12'>
              <p className='font fieldheadings m-0'>
                <strong> Enter Project Name</strong>
              </p>
              <Autocomplete
                id='country-select-demo'
                sx={{ width: '100%' }}
                size='small'
                freeSolo
                options={newFilteredCities}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                autoHighlight={false}
                getOptionLabel={option => option}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <SiGooglephotos color='black' fontSize='20px' />{' '}
                    <span className='px-1'></span>
                    {option}
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    sx={{
                      width: '100%',
                      height: '40px',
                      borderRadius: '4px'
                    }}
                    className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                    // inputRef={inputRef}
                    inputRef={ProjectNameRef}
                    value={inputValue}
                    // onChange={handlePChange}
                    id='outlined-basic'
                    label={
                      <span className='font textinfield'>
                        {' '}
                        <MapsHomeWorkOutlinedIcon className='icoonfont' />{' '}
                        {inputValue === '' ? 'Select' : inputValue}
                      </span>
                    }
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                      maxLength: 30
                    }}
                  />
                )}
                isOptionEqualToValue={isOptionEqualToValue}
              />
              {isfieldfilled && (
                <div>
                  <span style={{ color: 'red' }}>This field is Required</span>
                </div>
              )}
            </div>
            <div className='col-lg-3 col-12'>
              {' '}
              <p className='font fieldheadings m-0'>
                <strong>Developer</strong>
              </p>
              <Autocomplete
                id='country-select-demo'
                sx={{ width: '100%' }}
                size='small'
                freeSolo
                options={developerNameOpt}
                inputValue={developerName}
                onInputChange={handleDevelopernameChange}
                autoHighlight={false}
                getOptionLabel={option => option}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option}
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    sx={{
                      width: '100%',
                      height: '40px',
                      borderRadius: '4px'
                    }}
                    className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                    // inputRef={inputRef}
                    inputRef={developerRef}
                    value={developerName}
                    // onChange={handlePChange}
                    id='outlined-basic'
                    label={
                      <span className='font textinfield'>
                        {' '}
                        {developerName === '' ? 'Select' : developerName}
                      </span>
                    }
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      maxLength: 28,
                      autoComplete: 'new-password' // disable autocomplete and autofill
                    }}
                  />
                )}
                isOptionEqualToValue={isOptionEqualToValue}
              />
              {isdeveloperfilled && (
                <div>
                  <span style={{ color: 'red' }}>This field is Required</span>
                </div>
              )}
            </div>
          </div>
          {/* Assignment Location */}
          <div className='row'>
            <p className='infoheading'>Assignment Location</p>
            <div className='col-lg-6 col-12'>
              {' '}
              <p className='font fieldheadings m-0'>
                <strong>Address</strong>
              </p>
              <Autocomplete
                size='small'
                id='country-select-demo'
                sx={{ width: '100%' }}
                freeSolo
                options={newFilteredCities2}
                inputValue={inputValue2}
                onInputChange={(event, value) => {
                  handleInputChange2(event, value)
                  setInputValue2ChangedByAutocomplete(false)
                }}
                autoHighlight={false}
                // getOptionLabel={option => option.responseTitle}
                getOptionLabel={option =>
                  `${option.responseTitle}-${option.responseDesc}`
                }
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    key={`${option.responseTitle}-${option.responseDesc}`}
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <div className='fd-col'>
                      <div>
                        <FmdGoodOutlinedIcon className='locaicon' />
                        {`${option.responseTitle}`}
                      </div>
                      <div className='respDesc'>{`${option.responseDesc}`}</div>
                    </div>
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    sx={{
                      width: '100%',
                      height: '40px',
                      borderRadius: '4px'
                    }}
                    className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                    value={inputValue2}
                    inputRef={AddressRef}
                    id='outlined-basic'
                    label={
                      <span className='font textinfield'>
                        {' '}
                        <FmdGoodOutlinedIcon className='icoonfont' />{' '}
                        {inputValue2 === '' ? 'Select' : inputValue2}
                      </span>
                    }
                    name='Location'
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                      maxLength: 55
                    }}
                  />
                )}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setInputValue2(newValue.responseDesc)
                    setInputValue2ChangedByAutocomplete(true)
                    handleOptionSelect(newValue)
                  }
                }}
              />
              {isAddressFilled && (
                <div>
                  <span style={{ color: 'red' }}>
                    Kindly fill the complete address
                  </span>
                </div>
              )}
            </div>

            <div className='col-lg-3 col-12 '>
              {' '}
              <p className='font fieldheadings m-0'>
                <strong>City</strong>
              </p>
              <TextField
                size='small'
                sx={{
                  width: '100%',
                  '.Mui-disabled': {
                    textShadow: '0 0 black' // Change the color as needed
                  },
                  height: '40px',
                  borderRadius: '4px'
                }}
                onChange={formik.handleChange}
                value={formik.values.city}
                className='fieldsettings comWidth_Field mob_filedsettings bg-white bg-white'
                id='outlined-basic'
                variant='outlined'
                name='city'
                disabled={UserRole !== 'Marketing'}
              // label={<span className="font textinfield">$</span>}
              />
              {/* {isSubmitted ? (
                <span style={{ color: "red" }}>
                  {formik.errors.city}
                </span>
              ) : null} */}
            </div>
            <div className='col-lg-3 col-12'>
              {' '}
              <p className='font fieldheadings m-0'>
                <strong>Country</strong>
              </p>
              <TextField
                size='small'
                sx={{
                  width: '100%',
                  height: '40px',
                  borderRadius: '4px',
                  '.Mui-disabled': {
                    textShadow: '0 0 black' // Change the color as needed
                  }
                }}
                onChange={formik.handleChange}
                value={formik.values.country}
                className='fieldsettings comWidth_Field mob_filedsettings bg-white bg-white'
                id='outlined-basic'
                variant='outlined'
                name='country'
                disabled={UserRole !== 'Marketing'}
              // label={<span className="font textinfield">$</span>}
              />
              {/* {isSubmitted ? (
                <span style={{ color: "red" }}>
                  {formik.errors.country}
                </span>
              ) : null} */}
            </div>

            <div className='row pt-4'>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Region</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    '.Mui-disabled': {
                      textShadow: '0 0 black' // Change the color as needed
                    },
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.region}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white bg-white'
                  id='outlined-basic'
                  variant='outlined'
                  name='region'
                  disabled={UserRole !== 'Marketing'}
                // label={<span className="font textinfield">$</span>}
                />
                {/* {isSubmitted ? (
                <span style={{ color: "red" }}>
                  {formik.errors.region}
                </span>
              ) : null} */}
              </div>

              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Postal Code</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    '.Mui-disabled': {
                      textShadow: '0 0 black' // Change the color as needed
                    },
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.postalcode}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white bg-white'
                  id='outlined-basic'
                  variant='outlined'
                  disabled={UserRole !== 'Marketing'}
                  name='postalcode'
                // label={<span className="font textinfield">$</span>}
                />
                {/* {isSubmitted ? (
                <span style={{ color: "red" }}>
                  {formik.errors.postalcode}
                </span>
              ) : null} */}
              </div>

              {UserRole === "Marketing" && (
                <>
                  <div className='col-lg-3 col-12 '>
                    {' '}
                    <p className='font fieldheadings m-0'>
                      <strong>Latitude</strong>
                    </p>
                    <TextField
                      size='small'
                      type='number'
                      sx={{
                        width: '100%',
                        height: '40px',
                        borderRadius: '4px'
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.latitude}
                      className='fieldsettings comWidth_Field mob_filedsettings bg-white bg-white'
                      id='outlined-basic'
                      variant='outlined'
                      name='latitude'
                    // label={<span className="font textinfield">$</span>}
                    />
                  </div>

                  <div className='col-lg-3 col-12 '>
                    {' '}
                    <p className='font fieldheadings m-0'>
                      <strong>Longitude</strong>
                    </p>
                    <TextField
                      size='small'
                      type='number'
                      sx={{
                        width: '100%',
                        height: '40px',
                        borderRadius: '4px'
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.longitude}
                      className='fieldsettings comWidth_Field mob_filedsettings bg-white bg-white'
                      id='outlined-basic'
                      variant='outlined'
                      name='longitude'
                    // label={<span className="font textinfield">$</span>}
                    />
                  </div>
                </>
              )}

              <div className='col-lg-3 col-12 '>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Main Intersection</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  inputRef={(input) => {
                    inputRefs.current['MainIntersection'] = input;
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.MainIntersection}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white bg-white'
                  id='outlined-basic'
                  variant='outlined'
                  name='MainIntersection'
                // label={<span className="font textinfield">$</span>}
                />
              </div>
            </div>

            {/* <div className='col-lg-3 col-12 my-3'>
              {' '}
              <p className='font fieldheadings m-0'>
                <strong>Unit # (Not Publicly Displayed)</strong>
              </p>
              <TextField
                size='small'
                sx={{
                  width: '100%',
                  height: '40px',
                  borderRadius: '4px'
                }}
                className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                value={formik.values.unitNo}
                onChange={formik.handleChange}
                id='outlined-basic'
                label={<span className='font textinfield'>#</span>}
                variant='outlined'
                name='unitNo'
                inputProps={{
                  maxLength: 4
                }}
              />
              {isSubmitted ? (
                <span style={{ color: 'red' }}>{formik.errors.unitNo}</span>
              ) : null}
            </div> */}

          </div>
          {/* Assignment Type */}
          <div className='row'>
            <p className='infoheading'>Assignment Type</p>
            <div className='col-lg-3 col-12'>
              <p className='font fieldheadings m-0'>
                <strong>Type</strong>
              </p>
              <FormControl
                fullWidth
                size='small'
                className='mob_filedsettings bg-white'
              >
                <InputLabel id='demo-simple-select-label'>
                  {isSubmitted ? (
                    <span className='font textinfield'>
                      {formik.values.assigntype === ''
                        ? 'Select'
                        : formik.values.assigntype}
                    </span>
                  ) : null}
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={formik.values.assigntype}
                  onChange={formik.handleChange}
                  label='assigntype'
                  name='assigntype'
                  sx={{
                    color: '#0253A0',
                    fontWeight: '600'
                  }}
                  inputRef={(input) => {
                    inputRefs.current['assigntype'] = input;
                  }}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value={'Condo'}>
                    <span className='font '>Condo</span>
                  </MenuItem>
                  <MenuItem value={'Condo Town'}>
                    <span className='font'>Condo Town</span>
                  </MenuItem>
                  <MenuItem value={'TownHouse'}>
                    <span className='font '>TownHouse</span>
                  </MenuItem>
                  <MenuItem value={'Semi-Detached'}>
                    <span className='font '>Semi-Detached</span>
                  </MenuItem>
                  <MenuItem value={'Detached'}>
                    <span className='font '>Detached</span>
                  </MenuItem>
                </Select>
              </FormControl>
              {isSubmitted ? (
                <span style={{ color: 'red' }}>{formik.errors.assigntype}</span>
              ) : null}
            </div>
            <div className='col-lg-3 col-12'>
              <p className='font fieldheadings m-0'>
                <strong>Status</strong>
              </p>
              <FormControl
                fullWidth
                size='small'
                className='mob_filedsettings bg-white'
              >
                <InputLabel id='demo-simple-select-label'>
                  {isSubmitted ? (
                    <span className='font textinfield'>
                      {formik.values.PropStatus === ''
                        ? 'Select'
                        : formik.values.PropStatus}
                    </span>
                  ) : null}
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={formik.values.PropStatus}
                  onChange={formik.handleChange}
                  label='PropStatus'
                  name='PropStatus'
                  sx={{
                    color: '#0253A0',
                    fontWeight: '600'
                  }}
                  inputRef={(input) => {
                    inputRefs.current['PropStatus'] = input;
                  }}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value={'Available'}>
                    <span className='font '>Available</span>
                  </MenuItem>
                  {assignmentId && (
                    <MenuItem value={'Unavailable'}>
                      <span className='font'>Unavailable</span>
                    </MenuItem>
                  )}
                  <MenuItem value={'On Hold'}>
                    <span className='font '>On-hold</span>
                  </MenuItem>
                  {assignmentId && (
                    <MenuItem value={'Sold'}>
                      <span className='font '>Sold</span>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              {isSubmitted ? (
                <span style={{ color: 'red' }}>{formik.errors.PropStatus}</span>
              ) : null}
            </div>
            <div className='col-lg-3 col-12'>
              <p className='font fieldheadings m-0'>
                <strong>Lot Width (feet)</strong>
              </p>

              <FormattedField
                formik={formik}
                name='LotWidth'
                selectedStyle='decimal'
                label={'feet'}
              />

              {isSubmitted ? (
                <span style={{ color: 'red' }}>{formik.errors.LotWidth}</span>
              ) : null}
            </div>
          </div>
          {/* Assignment Pricing */}
          <div>
            <div className='row'>
              <p className='infoheading'>Assignment Pricing</p>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>List Price</strong>
                </p>
                <FormattedField
                  formik={formik}
                  name='listPrice'
                  selectedStyle='currency'
                  inputRef={(input) => {
                    inputRefs.current['listPrice'] = input;
                  }}
                />
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.listPrice}
                  </span>
                ) : null}
              </div>
              <div className='col-lg-3 col-12'>
                <p className='font fieldheadings m-0'>
                  <strong>Original Purchase Price</strong>
                </p>

                <FormattedField
                  formik={formik}
                  name='originalPrice'
                  selectedStyle='currency'
                  inputRef={(input) => {
                    inputRefs.current['originalPrice'] = input;
                  }}
                />
              </div>
              <div className='col-lg-3 col-12'>
                <p className='font fieldheadings m-0'>
                  <strong>Original Purchase Deposit</strong>
                </p>

                <FormattedField
                  formik={formik}
                  name='originalDeposit'
                  selectedStyle='currency'
                  inputRef={(input) => {
                    inputRefs.current['originalDeposit'] = input;
                  }}
                />
              </div>

              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Total Pay (Buyer to Seller)</strong>
                </p>
                <FormattedField
                  isdisable={true}
                  formik={formik}
                  name='TotalToSeller'
                  selectedStyle='currency'
                />
                {/* <TextField
                  size="small"
                  sx={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "4px",
                        
                  }}
                  type="number"
                  label={<span className="font textinfield">$</span>}
                  value={formik.values.TotalToSeller}
                  className="fieldsettings mob_filedsettings bg-white"
                  id="outlined-basic"
                  disabled
                  variant="outlined"
                  name="TotalToSeller"
                />
                {isSubmitted ? (
                  <span style={{ color: "red" }}>
                    {formik.errors.TotalToSeller}
                  </span>
                ) : null} */}
              </div>
            </div>
            <div className='row pt-4'>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Occupancy Due</strong>
                </p>
                <FormattedField
                  formik={formik}
                  name='OccupancyDue'
                  selectedStyle='currency'
                />
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.OccupancyDue}
                  </span>
                ) : null}
              </div>
              <div className='col-lg-3 col-12'>
                <p className='font fieldheadings m-0'>
                  <strong>Upgrade Deposit Paid</strong>
                </p>

                <FormattedField
                  formik={formik}
                  name='UpgradeDepositPaid'
                  selectedStyle='currency'
                />
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.UpgradeDepositPaid}
                  </span>
                ) : null}
              </div>

              {/* <div className='col-lg-3 col-12 my-3'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Upgrade Charges</strong>
                </p>
                <FormattedField
                  formik={formik}
                  name='upgradeCharges'
                  selectedStyle='currency'
                />
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.upgradeCharges}
                  </span>
                ) : null}
              </div> */}

              <div className='col-lg-3 col-12'>
                <p className='font fieldheadings m-0'>
                  <strong>Upgrade Balance (Text)</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  // label={<span className="font textinfield">$</span>}
                  value={formik.values.UpgradeBalance}
                  onChange={formik.handleChange}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                  id='outlined-basic'
                  variant='outlined'
                  name='UpgradeBalance'
                />
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.UpgradeBalance}
                  </span>
                ) : null}
              </div>

              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Levy Fee</strong>
                </p>
                <FormattedField
                  formik={formik}
                  name='LevyFee'
                  selectedStyle='currency'
                />
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>{formik.errors.LevyFee}</span>
                ) : null}
              </div>
            </div>

            <div className='row pt-4'>
              <div className='col-lg-3 col-12'>
                <p className='font fieldheadings m-0'>
                  <strong>Maintenance Fee</strong>
                </p>

                <FormattedField
                  formik={formik}
                  name='MaintainenceFee'
                  selectedStyle='currency'
                />

                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.MaintainenceFee}
                  </span>
                ) : null}
              </div>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Cooperating Commission</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  label={<span className='font textinfield'>%</span>}
                  value={formik.values.CooperatingCommission}
                  onChange={e => {
                    let value = e.target.value.replace(/%/g, '') // Remove any '%' character

                    // Remove non-numeric characters except for the first decimal point
                    value = value
                      .replace(/[^0-9.]/g, '')
                      .replace(/(\..*)\./g, '$1')

                    // Format input as a percentage
                    if (value.length <= 2) {
                      // For the first two digits, display them as is
                      formik.setFieldValue('CooperatingCommission', value)
                    } else if (value.length > 2 && value.length <= 5) {
                      // Insert a decimal point after the first two digits if not already present
                      if (!value.includes('.')) {
                        value = value.slice(0, 2) + '.' + value.slice(2)
                      }
                      formik.setFieldValue('CooperatingCommission', value)
                    }
                  }}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                  id='outlined-basic'
                  variant='outlined'
                  name='CooperatingCommission'
                  type='text' // Changed from 'tel' to 'text' to handle custom formatting
                />
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.CooperatingCommission}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          {/* Assignment Details */}
          <div className=''>
            <div className='row'>
              <p className='infoheading'>Assignment Details</p>
              <div className='col-lg-3 col-12 my-1'>
                <p className='font fieldheadings m-0'>
                  <strong>Style</strong>
                </p>
                <FormControl
                  fullWidth
                  size='small'
                  className='mob_filedsettings bg-white'
                >
                  <InputLabel id='demo-simple-select-label'>
                    <span className='font textinfield'>
                      {formik.values.styl === ''
                        ? 'Select'
                        : formik.values.styl}
                    </span>
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={formik.values.styl}
                    onChange={formik.handleChange}
                    label='Select'
                    name='styl'
                    sx={{
                      color: '#0253A0',
                      fontWeight: '600'
                    }}
                    inputRef={(input) => {
                      inputRefs.current['styl'] = input;
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={'2 Storey'}>
                      {' '}
                      <span className='font'>2 Storey</span>
                    </MenuItem>
                    <MenuItem value={'3 Storey'}>
                      <span className='font '>3 Storey</span>
                    </MenuItem>
                    <MenuItem value={'Apartment'}>
                      <span className='font '>Apartment</span>
                    </MenuItem>
                    <MenuItem value={'Bachelor/Studio'}>
                      <span className='font '>Bachelor/Studio</span>
                    </MenuItem>
                    <MenuItem value={'Bugoloft'}>
                      <span className='font '>Bugoloft</span>
                    </MenuItem>
                    <MenuItem value={'Bungalow'}>
                      <span className='font '>Bungalow</span>
                    </MenuItem>
                    <MenuItem value={'Stacked Townhouse'}>
                      <span className='font '>Stacked Townhouse</span>
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='col-lg-3 col-12 my-1'>
                <p className='font fieldheadings m-0'>
                  <strong>Exact Size (Sqft)</strong>
                </p>

                <FormattedField
                  formik={formik}
                  name='exactSize'
                  selectedStyle='decimal'
                  label={'sqft'}
                  inputRef={(input) => {
                    inputRefs.current['exactSize'] = input;
                  }}
                />

                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.exactSize}
                  </span>
                ) : null}
              </div>
              <div className='col-lg-3 col-12 my-1'>
                <p className='font fieldheadings m-0'>
                  <strong>Floor</strong>
                </p>

                <FormControl
                  fullWidth
                  size='small'
                  className='mob_filedsettings bg-white'
                >
                  <InputLabel id='demo-simple-select-label'>
                    <span className='font textinfield'>
                      {' '}
                      <span className='font textinfield'>
                        {' '}
                        <HouseIcon className='icoonfont' />{' '}
                        {formik.values.floor === ''
                          ? 'Select'
                          : formik.values.floor}
                      </span>
                    </span>
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={formik.values.floor}
                    onChange={formik.handleChange}
                    label='floorisdirty'
                    name='floor'
                    sx={{
                      color: '#0253A0',
                      fontWeight: '600'
                    }}
                    inputRef={(input) => {
                      inputRefs.current['floor'] = input;
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={'1st-10th'}>
                      {' '}
                      <span className='font'>1st-10th</span>
                    </MenuItem>
                    <MenuItem value={'11th-20th'}>
                      <span className='font '>11th-20th</span>
                    </MenuItem>
                    <MenuItem value={'21st-30th'}>
                      <span className='font '>21st-30th</span>
                    </MenuItem>
                    <MenuItem value={'31st-40th'}>
                      <span className='font '>31st-40th</span>
                    </MenuItem>
                    <MenuItem value={'41st-50th'}>
                      <span className='font '>41st-50th</span>
                    </MenuItem>
                    <MenuItem value={'51st+'}>
                      <span className='font '>51st+</span>
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='col-lg-3 col-12 my-1'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Exposure</strong>
                </p>
                <FormControl
                  fullWidth
                  size='small'
                  className='mob_filedsettings bg-white'
                >
                  <InputLabel id='demo-simple-select-label'>
                    <span className='font textinfield'>
                      {' '}
                      {formik.values.exposure === ''
                        ? 'Select'
                        : formik.values.exposure}
                    </span>
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={formik.values.exposure}
                    onChange={formik.handleChange}
                    label='exposure'
                    name='exposure'
                    sx={{
                      color: '#0253A0',
                      fontWeight: '600'
                    }}
                    inputRef={(input) => {
                      inputRefs.current['exposure'] = input;
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={'North'}>
                      {' '}
                      <span className='font'>North</span>
                    </MenuItem>
                    <MenuItem value={'East'}>
                      {' '}
                      <span className='font'>East</span>
                    </MenuItem>
                    <MenuItem value={'South'}>
                      {' '}
                      <span className='font'>South</span>
                    </MenuItem>
                    <MenuItem value={'West'}>
                      {' '}
                      <span className='font'>West</span>
                    </MenuItem>
                    <MenuItem value={'NE'}>
                      {' '}
                      <span className='font'>NE</span>
                    </MenuItem>
                    <MenuItem value={'NW'}>
                      {' '}
                      <span className='font'>NW</span>
                    </MenuItem>
                    <MenuItem value={'SE'}>
                      {' '}
                      <span className='font'>SE</span>
                    </MenuItem>
                    <MenuItem value={'SW'}>
                      {' '}
                      <span className='font'>SW</span>
                    </MenuItem>
                    <MenuItem value={'Unknown'}>
                      {' '}
                      <span className='font'>Unknown</span>
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className='row pt-4'>
              <div className='col-lg-3 col-12 '>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Bedrooms</strong>
                </p>
                <FormControl
                  fullWidth
                  size='small'
                  className='mob_filedsettings bg-white'
                >
                  <InputLabel id='demo-simple-select-label'>
                    <span className='font textinfield'>
                      <BiBed className='icoonfont' />
                      {formik.values.noOfBeds === ''
                        ? ''
                        : formik.values.noOfBeds}
                    </span>
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={formik.values.noOfBeds}
                    onChange={formik.handleChange}
                    label='noOfBeds'
                    name='noOfBeds'
                    sx={{
                      color: '#0253A0',
                      fontWeight: '600'
                    }}
                    inputRef={(input) => {
                      inputRefs.current['noOfBeds'] = input;
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={'0B'}>
                      <span className='font'>Studio</span>
                    </MenuItem>
                    <MenuItem value={'1B'}>
                      <span className='font'>1 Bed</span>
                    </MenuItem>
                    <MenuItem value={'1B+D'}>
                      <span className='font'>1 Bed + Den</span>
                    </MenuItem>
                    <MenuItem value={'2B'}>
                      <span className='font'>2 Bed</span>
                    </MenuItem>
                    <MenuItem value={'2B+D'}>
                      <span className='font'>2 Bed + Den</span>
                    </MenuItem>
                    <MenuItem value={'3B'}>
                      <span className='font'>3 Bed</span>
                    </MenuItem>
                    <MenuItem value={'3B+D'}>
                      <span className='font'>3 Bed + Den</span>
                    </MenuItem>
                    <MenuItem value={'4B'}>
                      <span className='font'>4 Bed</span>
                    </MenuItem>
                    <MenuItem value={'4B+D'}>
                      <span className='font'>4 Bed + Den</span>
                    </MenuItem>
                    <MenuItem value={'5B'}>
                      <span className='font'>5 Bed</span>
                    </MenuItem>
                    <MenuItem value={'5B+D'}>
                      <span className='font'>5 Bed + Den</span>
                    </MenuItem>
                    <MenuItem value={'6B+'}>
                      <span className='font'>6 Bed+</span>
                    </MenuItem>
                  </Select>
                </FormControl>
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {' '}
                    {formik.errors.noOfBeds}
                  </span>
                ) : null}
              </div>
              <div className='col-lg-3 col-12'>
                <p className='font fieldheadings m-0'>
                  <strong>Washrooms</strong>
                </p>
                <FormControl
                  fullWidth
                  size='small'
                  className='mob_filedsettings bg-white'
                >
                  <InputLabel id='demo-simple-select-label'>
                    <span className='font textinfield'>
                      <BathtubIcon className='icoonfont' />{' '}
                      {formik.values.noOfWash === ''
                        ? ''
                        : formik.values.noOfWash}
                    </span>
                  </InputLabel>
                  <Select
                    type='number'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={formik.values.noOfWash}
                    onChange={formik.handleChange}
                    label='noOfWash'
                    name='noOfWash'
                    sx={{
                      color: '#0253A0',
                      fontWeight: '600'
                    }}
                    inputRef={(input) => {
                      inputRefs.current['noOfWash'] = input;
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={1}>
                      <span className='font'>1</span>
                    </MenuItem>
                    <MenuItem value={1.5}>
                      <span className='font'>1.5</span>
                    </MenuItem>
                    <MenuItem value={2}>
                      <span className='font'>2</span>
                    </MenuItem>
                    <MenuItem value={2.5}>
                      <span className='font'>2.5</span>
                    </MenuItem>
                    <MenuItem value={3}>
                      <span className='font'>3</span>
                    </MenuItem>
                    <MenuItem value={3.5}>
                      <span className='font'>3.5</span>
                    </MenuItem>
                    <MenuItem value={4}>
                      <span className='font'>4</span>
                    </MenuItem>
                    <MenuItem value={5}>
                      <span className='font'>4+</span>
                    </MenuItem>
                  </Select>
                </FormControl>
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>{formik.errors.noOfWash}</span>
                ) : null}
              </div>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Balcony (SQFT)</strong>
                </p>
                <FormattedField
                  formik={formik}
                  name='balcony'
                  selectedStyle='decimal'
                  label={'sqft'}
                />
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>{formik.errors.balcony}</span>
                ) : null}
              </div>
              <div className='col-lg-3 col-12'>
                <p className='font fieldheadings m-0'>
                  <strong>Color Selection Chosen</strong>
                </p>
                <FormControl
                  fullWidth
                  size='small'
                  className='mob_filedsettings bg-white'
                >
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={formik.values.Colorselectionchosen}
                    onChange={event => {
                      formik.handleChange(event)
                      setCLabel(event.target.value === 'true' ? 'Yes' : 'No')
                    }}
                    name='Colorselectionchosen'
                    label='Select'
                    sx={{
                      color: '#0253A0',
                      fontWeight: '600'
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={'true'}>Yes</MenuItem>
                    <MenuItem value={'false'}>No</MenuItem>
                  </Select>

                  <InputLabel htmlFor='demo-simple-select'>
                    {clabel === 'Select' ? (
                      <span className='font textinfield'> Select</span>
                    ) : (
                      <span className='font textinfield'>{clabel}</span>
                    )}
                  </InputLabel>
                </FormControl>
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.Colorselectionchosen}
                  </span>
                ) : null}
              </div>
            </div>
            <div className='row pt-4'>
              {/* <div className='col-lg-3 col-12 my-3'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Parking</strong>
                </p>
                <FormControl
                  fullWidth
                  size='small'
                  className='mob_filedsettings bg-white'
                >
                  <InputLabel id='demo-simple-select-label'>
                    <span className='font textinfield'>
                      {' '}
                      {formik.values.parking === ''
                        ? 'Select'
                        : formik.values.parking === 1
                          ? 'Included'
                          : 'Not Included'}
                    </span>
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={formik.values.parking}
                    onChange={formik.handleChange}
                    label='parkingisincl'
                    name='parking'
                    type='number'
                    sx={{
                      color: '#0253A0',
                      fontWeight: '600'
                    }}
                    inputRef={(input) => {
                      inputRefs.current['parking'] = input;
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={1}>
                      <span className='font'>Included</span>
                    </MenuItem>
                    <MenuItem value={0}>
                      <span className='font'>Not Included</span>
                    </MenuItem>
                  </Select>
                </FormControl>
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>{formik.errors.parking}</span>
                ) : null}
              </div> */}
              <div className='col-lg-3 col-12'>
                <p className='font fieldheadings m-0'>
                  <strong>Parking Type</strong>
                </p>
                <FormControl
                  fullWidth
                  size='small'
                  className='mob_filedsettings bg-white'
                >
                  <InputLabel id='demo-simple-select-label'>
                    <span className='font textinfield'>
                      {' '}
                      {formik.values.ParkingType === ''
                        ? 'Select'
                        : formik.values.ParkingType}
                    </span>
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={formik.values.ParkingType}
                    onChange={formik.handleChange}

                    label='ParkingType'
                    name='ParkingType'
                    sx={{
                      color: '#0253A0',
                      fontWeight: '600'
                    }}
                    inputRef={(input) => {
                      inputRefs.current['ParkingType'] = input;
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={'None'}>
                      <span className='font'>None</span>
                    </MenuItem>
                    <MenuItem value={'Attached'}>
                      <span className='font'>Attached</span>
                    </MenuItem>
                    <MenuItem value={'Detached'}>
                      <span className='font'>Detached</span>
                    </MenuItem>
                    <MenuItem value={'Underground'}>
                      <span className='font'>Underground</span>
                    </MenuItem>
                  </Select>
                </FormControl>
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.ParkingType}
                  </span>
                ) : null}
              </div>

              <div className='col-lg-3 col-12'>
                <p className='font fieldheadings m-0'>
                  <strong>No. of Parkings</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  type='number'
                  label={
                    <span className='font textinfield'>No of Parkings</span>
                  }
                  value={formik.values.parking}
                  onChange={e => {
                    if (e.target.value.length <= 1) {
                      formik.handleChange(e)
                    }
                  }}
                  disabled={formik.values.ParkingType === 'None'}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                  id='outlined-basic'
                  variant='outlined'
                  name='parking'
                  inputRef={(input) => {
                    inputRefs.current['parking'] = input;
                  }}
                />
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>{formik.errors.parking}</span>
                ) : null}
              </div>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Lease During Occupancy</strong>
                </p>
                <FormControl
                  fullWidth
                  size='small'
                  className='mob_filedsettings bg-white'
                >
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Select'
                    value={formik.values.LeaseDuringOccupancy}
                    onChange={event => {
                      formik.handleChange(event)
                      setLLabel(event.target.value === 'true' ? 'Yes' : 'No')
                    }}
                    name='LeaseDuringOccupancy'
                    sx={{
                      color: '#0253A0',
                      fontWeight: '600'
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={'true'}>Yes</MenuItem>
                    <MenuItem value={'false'}>No</MenuItem>
                  </Select>

                  <InputLabel htmlFor='demo-simple-select'>
                    {llabel === 'Select' ? (
                      <span className='font textinfield'> Select</span>
                    ) : (
                      <span className='font textinfield'>{llabel}</span>
                    )}
                  </InputLabel>
                </FormControl>
                {/* <span style={{ color: "red" }}>{formik.errors.CooperatingCommission}</span> */}
              </div>
              <div className='col-lg-3 col-12'>
                <p className='font fieldheadings m-0'>
                  <strong>No. of Lockers</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  type='number'
                  label={
                    <span className='font textinfield'>No. of Lockers</span>
                  }
                  value={formik.values.Locker}
                  onChange={e => {
                    if (e.target.value.length <= 2) {
                      formik.handleChange(e)
                    }
                  }}
                  inputRef={(input) => {
                    inputRefs.current['Locker'] = input;
                  }}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                  id='outlined-basic'
                  variant='outlined'
                  name='Locker'
                // name='noOfLocker'
                />
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.Locker}
                  </span>
                ) : null}
              </div>
            </div>

            <div className='row mt-4'>
              {/* <div className='col-lg-3 col-12 my-3'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Lockers</strong>
                </p>
                <FormControl
                  fullWidth
                  size='small'
                  className='mob_filedsettings bg-white'
                >
                  <InputLabel id='demo-simple-select-label'>
                    <span className='font textinfield'>
                      {' '}
                      {formik.values.Locker === ''
                        ? 'Select'
                        : formik.values.Locker === 1
                          ? 'Included'
                          : 'Not Included'}
                    </span>
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={formik.values.Locker}
                    onChange={formik.handleChange}
                    label='Lockerisinclud'
                    name='Locker'
                    sx={{
                      color: '#0253A0',
                      fontWeight: '600'
                    }}
                    inputRef={(input) => {
                      inputRefs.current['Locker'] = input;
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value={1}>
                      <span className='font'>Included</span>
                    </MenuItem>
                    <MenuItem value={0}>
                      <span className='font'>Not Included</span>
                    </MenuItem>
                  </Select>
                </FormControl>
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>{formik.errors.Locker}</span>
                ) : null}
              </div> */}
              <div className='col-lg-3 col-12'>
                <p className='font fieldheadings m-0'>
                  <strong>Occupancy Date</strong>
                </p>
                <TextField
                  size='small'
                  type='date'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.OccupanycDate}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white font'
                  id='outlined-basic'
                  variant='outlined'
                  name='OccupanycDate'
                  label={<span className='font textinfield'></span>}
                  inputRef={(input) => {
                    inputRefs.current['OccupanycDate'] = input;
                  }}
                />
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.OccupanycDate}
                  </span>
                ) : null}
              </div>
              {/* <div className='col-lg-3 col-12 my-3'>
                <p className='font fieldheadings m-0'>
                  <strong>Closing Date</strong>
                </p>
                <TextField
                  size='small'
                  type='date'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.ClosingDate}
                  className='fieldsettings  comWidth_Field mob_filedsettings bg-white font'
                  id='outlined-basic'
                  variant='outlined'
                  name='OccupanycDate'
                  label={<span className='font textinfield'></span>}
                />
                {isSubmitted ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.ClosingDate}
                  </span>
                ) : null}
              </div> */}
            </div>
            <div className='col-lg-6 col-12 pb-4 mt-3 mb-5'>
              <p className='font fieldheadings m-0'>
                <strong>Highlights</strong>
              </p>
              <TextField
                size='small'
                sx={{
                  width: '100%',
                  borderRadius: '4px'
                }}
                onChange={formik.handleChange}
                value={formik.values.Highlight}
                className='fieldsettings comWidth_Field mob_filedsettings font'
                id='outlined-basic'
                variant='outlined'
                multiline
                rows={6}
                inputProps={{
                  maxLength: 1000
                }}
                name='Highlight'
                label={<span className='font textinfield'>Highlights</span>}
                inputRef={(input) => {
                  inputRefs.current['Highlight'] = input;
                }}
              />
              {/* {isSubmitted ? (
                <span style={{ color: 'red' }}>{formik.errors.Highlight}</span>
              ) : null} */}
            </div>        
          </div>

       

          {/*Icon rOws Start */}
          {/* <div className="row">
            <p className="infoheading">Assignment Amenities</p>

            <div className="row">
              <div className="col-lg-3 p-0 col-12">
                {" "}
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <span className="fontzz">
                      AC
                    </span>
                  }
                />
              </div>
              <div className="col-lg-3  p-0  col-12">
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <span className="fontzz">
                      Balcony
                    </span>
                  }
                />
              </div>
              <div className="col-lg-3 p-0  col-12">
                {" "}
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <span className="fontzz">
                      Gym
                    </span>
                  }
                />
              </div>
              <div className="col-lg-3  p-0 col-12">
                {" "}
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <span className="fontzz">
                      Pool
                    </span>
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 p-0 col-12">
                <FormControlLabel
                  control={<Checkbox />}
                  label={<span className="fontzz">Heating</span>}
                />
              </div>
              <div className="col-lg-3  p-0 col-12">
                <FormControlLabel
                  control={<Checkbox />}
                  label={<span className="fontzz">Satellite TV</span>}
                />
              </div>
              <div className="col-lg-3 p-0  col-12">
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <span className="fontzz">
                      Fireplace
                    </span>
                  }
                />
              </div>

              <div className="col-lg-3  p-0 col-12">
                {" "}
                <FormControlLabel
                  control={<Checkbox />}
                  label={<span className="fontzz">Cable TV</span>}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3  p-0 col-12">
                {" "}
                <FormControlLabel
                  control={<Checkbox />}
                  label={<span className="fontzz">Hydro Electricity</span>}
                />
              </div>
              <div className="col-lg-3  p-0 col-12">
                {" "}
                <FormControlLabel
                  control={<Checkbox />}
                  label={<span className="fontzz">Cable TV</span>}
                />
              </div>
              <div className="col-lg-3  p-0 col-12">
                {" "}
                <FormControlLabel
                  control={<Checkbox />}
                  label={<span className="fontzz">Internet / Wifi</span>}
                />
              </div>
              <div className="col-lg-3 p-0  col-12">
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <span className="fontzz">
                      Dishwasher
                    </span>
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 p-0 col-12">
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <span className="fontzz">
                      Balcony
                    </span>
                  }
                />
              </div>
              <div className="col-lg-3 p-0 col-12">
                <FormControlLabel
                  control={<Checkbox />}
                  label={<span className="fontzz">Heating</span>}
                />
              </div>
              <div className="col-lg-3 p-0   col-12">
                {" "}
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <span className="fontzz">
                      Water
                    </span>
                  }
                />
              </div>
              <div className="col-lg-3  p-0 col-12">
                {" "}
                <FormControlLabel
                  control={<Checkbox />}
                  label={<span className="fontzz">Pool</span>}
                />
              </div>
            </div>
          </div> */}
        </div>
        {/* Assignment Media Attachments */}
        {/* {console.log("image data ", imageData)} */}

        {UserRole === 'Marketing' && (
          <>
            <div className='row pt-4'>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Source</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                  value={formik.values.source}
                  onChange={formik.handleChange}
                  id='outlined-basic'
                  label={
                    <span className='font textinfield'>Enter Source</span>
                  }
                  variant='outlined'
                  name='source'
                />
              </div>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Source Name</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                  value={formik.values.sourceName}
                  onChange={formik.handleChange}
                  id='outlined-basic'
                  label={
                    <span className='font textinfield'>Enter Source Name</span>
                  }
                  variant='outlined'
                  name='sourceName'
                />
              </div>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Source Number</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                  value={formik.values.sourceNumber}
                  onChange={formik.handleChange}
                  id='outlined-basic'
                  label={
                    <span className='font textinfield'>Enter Source Number</span>
                  }
                  variant='outlined'
                  name='sourceNumber'
                />
              </div>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Source Email</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                  value={formik.values.sourceEmail}
                  onChange={formik.handleChange}
                  id='outlined-basic'
                  label={
                    <span className='font textinfield'>Enter Source Email</span>
                  }
                  variant='outlined'
                  name='sourceEmail'
                />
              </div>
            </div>
            <div className='row pt-4'>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Source Id</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                  value={formik.values.sourceId}
                  onChange={formik.handleChange}
                  id='outlined-basic'
                  label={
                    <span className='font textinfield'>Enter Source Id</span>
                  }
                  variant='outlined'
                  name='sourceId'
                />
              </div>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>Source Url</strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                  value={formik.values.sourceUrl}
                  onChange={formik.handleChange}
                  id='outlined-basic'
                  label={
                    <span className='font textinfield'>Enter Source Url</span>
                  }
                  variant='outlined'
                  name='sourceUrl'
                />
              </div>
              <div className='col-lg-3 col-12'>
                {' '}
                <p className='font fieldheadings m-0'>
                  <strong>List Type </strong>
                </p>
                <TextField
                  size='small'
                  sx={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '4px'
                  }}
                  className='fieldsettings comWidth_Field mob_filedsettings bg-white'
                  value={formik.values.listType}
                  onChange={formik.handleChange}
                  id='outlined-basic'
                  label={
                    <span className='font textinfield'>Enter List Type</span>
                  }
                  variant='outlined'
                  name='listType'
                />
              </div>
            </div>
          </>
        )}

        <div className=''>
          {assignmentId ? null : (
            <>
              <p className='infoheading mediaInfo'>
                Assignment Media Attachments{' '}
              </p>

              <div className='row  row-with-gap pb-4  '>
                <div className='col-lg-3 cardwidth col-12 boxupload bg-white'>
                  <div onClick={handleImageOpen} className='x'>
                    <FiUpload className='FiUpload' />
                  </div>
                  <p className='m-0 pt-2 uploadfile'>
                    <strong>Upload a file </strong>
                  </p>
                  <p className='m-0 pt-2 uploadfiletxt'>
                    Please select Image files only
                  </p>
                  <input
                    accept='image/jpeg, image/png'
                    type='file'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                  />
                </div>

                {imageData?.map(item => (
                  <div
                    key={item.imageURL}
                    className='col-lg-3 cardwidth col-12 p-2 borderoncard bg-white'
                  >
                    <div style={{ position: 'relative' }}>
                      <img
                        className='randimages w-100'
                        src={item.imageURL}
                        alt='Listing Img'
                        loading="lazy"
                      />
                      {item.logo === 'true' ? (
                        <div
                          style={{ position: 'absolute', bottom: 0, right: 5 }}
                        >
                          <p className='mainstyling primary-bg'>Primary</p>
                        </div>
                      ) : null}

                      <div style={{ position: 'absolute', top: 10, right: 10 }}>
                        <p className='bin custom-cursor'>
                          <DeleteIcon
                            onClick={() => {
                              handleBoxDelete(item.imageURL)
                            }}
                          />
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12 '>
                        <p className='m-0 p-2'>
                          <strong>{item.caption}</strong>
                        </p>
                      </div>
                    </div>
                    <div className='row px-2'>
                      <div className='col-lg-4 col-12'>
                        <button className='cardsims'>Image</button>
                      </div>
                      {item.category && (
                        <>
                          <div className='col-lg-6 col-12'>
                            <button className='cardsime text-nowrap'>
                              {item.category}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    <div className='row'>
                      <div className='col-12 mt-5 w-100'>
                        {' '}
                        <button
                          onClick={() => handleEditOpen(item)}
                          className='w-100 sdfe'
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <FormGroup className="w-100 mt-2">
            <FormControlLabel
              control={
                <Checkbox
                checked={formik.values.showTo === "Realtor"}
                name="showTo"
                onChange={() =>
                  formik.setFieldValue("showTo", formik.values.showTo === "Realtor" ? null : "Realtor")
                }
                />
              }
              label={
                <span className="font fw-bold">
                 Only show to Realtors
                </span>
              }
            />
          </FormGroup>

        {/* <PricingCards redirect={'submit-assignment'} style={{ width: '60%' }} /> */}
        {/* {!assignmentId && UserRole !== 'Marketing' && (
          <div className='w-100'>
            <ExtraPkg selectedPackages={selectedPackages} setSelectedPackages={setSelectedPackages} />
          </div>
        )} */}

        <div className='sumitbutton p-3'>
          {submissionLoader ? (
            <Loader />
          ) : (
            <div className=''>
              <div className='w-100 text-center my-3'>
                {(isSubmitted && !formik.isValid && formik.dirty) ||
                  isfieldfilled ||
                  isAddressFilled ||
                  isdeveloperfilled ? (
                  <span className='text-center' style={{ color: 'red' }}>
                    Please fill the required fields
                  </span>
                ) : null}
              </div>
              <div className='d-flex justify-content-center'>
                <button
                  type='submit'
                  onClick={() => {
                    inputValue.length < 3 && setisfieldfilled(true)
                    developerName.length < 3 && setissdeveloperFilled(true)
                    const isCommonAddressInvalid = inputValue2.length === 0 ||
                      formik.values.city.length === 0 ||
                      formik.values.country.length === 0 ||
                      formik.values.region.length === 0 ||
                      formik.values.postalcode.length === 0;
                    const isMarketingAddressInvalid = isCommonAddressInvalid ||
                      formik.values.latitude === null || formik.values.latitude === '' ||
                      formik.values.longitude === null || formik.values.latitude === ''
                    setisAddressfilled(UserRole === 'Marketing' ? isMarketingAddressInvalid : isCommonAddressInvalid);
                    AutoFocustoField()
                    setIsSubmitted(true)
                    formik.handleSubmit()
                  }}
                  className=' Submitbutton p-2 mt-4'
                >
                  {assignmentId ? 'Update Assignment' : 'Submit Assignment'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='SubmitFooter'>
        <Footer />
      </div>
      {/* <PayModal
        showPayModal={showPayModal}
        setShowPayModal={setShowPayModal}
        handleClosePayModal={handleClosePayModal}
        selectedPackages={selectedPackages}
        showPayLaterBtn={showPayLaterBtn}
        handlePayLater={handlePayLater}
      /> */}
    </div>
  )
}
