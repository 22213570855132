import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import IconButton from "@mui/material/IconButton";
import { Popover } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  BackArrowIcon,
  CalculatorIcon,
  HeartEmptyIcon,
  LocationHeaderIcon,
  PropertyCommuteIcon,
  ShareIcon,
  WatchingNeighbourIcon,
  WatchSoldIcon,
} from "../../../SvgIcons/Icons";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";

import {
  addFavItem,
  removeFavItem,
} from "../../../ReduxStore/Slices/HeartDataSlice";
import { Link } from "react-scroll";
import baseURL from "../../../config";
import { useLocation } from "react-router-dom";

const PropertyHeader = ({
  isUserlogin,
  data,
  setIsSignUpModalOpen,
  handleWatchNeighborHoodClick,
  handleNotifyWhenSoldClick,
  handleCommuteModalClick,
  isMobileView,
  isScrollingDown,
  isNotified,
  isWatchingNeighbour,
  setIsCalculate,
}) => {
  const jwt_token = isUserlogin;
  const favouriteData = useSelector((state) => state.heartDataSlice);
  const userData = useSelector((state) => state.userProfileDataSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  const linkSlug = data?.slug;

  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps?q=${data?.latitude},${data?.longitude}`;
    window.open(url, "_blank");
  };

  const handleBackClick = ()=>{
    if(location.key !== "default"){
      navigate(-1)
    }else{
      navigate('/')
    }
  }

  //handle Favourite Work
  const [cardIde, setCardIde] = useState([]);
  const [FavInProgress, setFavInProgress] = useState(false);

  useEffect(() => {
    const cardIds = favouriteData?.favItems?.map((item) => item.uId);
    setCardIde(cardIds);
  }, [favouriteData]);

  const handleHeartClick = async (uId) => {
    if (!jwt_token || !userData) {
      setIsSignUpModalOpen(true);
      return;
    }
    if (FavInProgress) {
      return;
    }
    setFavInProgress(true);
    if (userData[0]?.emailConfirmed) {
      const isAlreadyFavorited = cardIde.includes(uId);
      if (isAlreadyFavorited) {
        setCardIde((prevIds) => prevIds.filter((itemId) => itemId !== uId));
        DeleteToFav(uId);
      } else {
        setCardIde((prevIds) => [...prevIds, uId]);
        AddTofav(uId);
      }
    } else {
      navigate("/email-confirmation?isEmailConfirmed=false");
    }
  };

  const AddTofav = async (uId) => {
    try {
      const data = {
        Id: "0",
        ProId: uId,
      };

      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const formData = new FormData();
      formData.append("AddFavouriteRequest", JSON.stringify(data));
      const response = await axios.post(
        `${baseURL}/api/User-Data/AddFavourite`,
        data,
        { headers }
      );

      if (response.data.result === 1) {
        dispatch(addFavItem({ uId: uId, tableUId: response?.data?.data?.uId }));
        setFavInProgress(false);
      } else {
        toast.error("Try Again!");
        setCardIde((prevIds) => prevIds.filter((itemId) => itemId !== uId));
        setFavInProgress(false);
      }
    } catch (error) {
      toast.error("Try Again!");
      setCardIde((prevIds) => prevIds.filter((itemId) => itemId !== uId));
      setFavInProgress(false);
    }
  };

  const DeleteToFav = async (uId) => {
    const tableUId = favouriteData?.favItems?.find((item) => item?.uId === uId);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
      };

      const response = await axios.delete(
        `${baseURL}/api/User-Data/DeleteFavourite/${tableUId?.tableUId}`,
        config
      );

      if (response.data.result === 1) {
        dispatch(removeFavItem({ uId: uId }));
        setFavInProgress(false);
      } else {
        toast.error("Delete Again!");
        setCardIde((prevIds) => [...prevIds, uId]);
        setFavInProgress(false);
      }
    } catch (error) {
      toast.error("Delete Again!");
      setCardIde((prevIds) => [...prevIds, uId]);
      setFavInProgress(false);
    }
  };

  //Share work
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };
  const [, setCopied] = useState(false);

  const handleCopyLink = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
      handlePopClose();
    }, 1500);
  };

  const handleFacebookLinkShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${`https://assignmentsold.ca/assignment-sale/`}${linkSlug}`
    );
  };
  const handleLinkdinLinkShare = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=https://assignmentsold.ca/assignment-sale/${linkSlug}`
    );
  };
  const handleWhatsAppLinkShare = () => {
    window.open(
      `https://wa.me/?text=https://assignmentsold.ca/assignment-sale/${linkSlug}`
    );
  };
  const handletwitterLinkShare = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=https://assignmentsold.ca/assignment-sale/${linkSlug}`
    );
  };

  return (
    <div
      className={`py-2 position-sticky ${
        isScrollingDown ? "Prop_secHeaderTop" : "Prop_header_static"
      }`}
      style={{
        borderBottom: "1px solid var(--Accent-Medium-Gray, #E2E2E2)",
        background: "#fff",
      }}
    >
      <div className="d-flex container align-items-center Propert_details_main_container Prop_header_container pt-0 justify-content-between">
        {!isMobileView && (
          <div className="Property_backSearch" onClick={handleBackClick}>
            <BackArrowIcon />
            Back to Search
          </div>
        )}
        <div className="d-flex align-items-center gap-2">
          {isMobileView && (
            <div className="PropertyHeader_Icon" onClick={handleBackClick}>
              <MdOutlineKeyboardArrowLeft size={"24"} />
            </div>
          )}

          <div className="PropertyHeader_Icon">
            <Link
              spy={true}
              activeClass="active"
              offset={-100}
              smooth={true}
              duration={500}
              to={"calculator"}
              onClick={() => setIsCalculate(true)}
            >
              <CalculatorIcon />
            </Link>
          </div>

          <div
            className={`PropertyHeader_Icon ${
              isNotified ? "DetailsIcon_LightBg" : ""
            }`}
            onClick={handleNotifyWhenSoldClick}
          >
            <WatchSoldIcon
              color={isNotified ? "#007BFF" : "#282828"}
              size={"24"}
            />
          </div>

          <div
            className={`PropertyHeader_Icon ${
              isWatchingNeighbour ? "DetailsIcon_LightBg" : ""
            }`}
            onClick={handleWatchNeighborHoodClick}
          >
            <WatchingNeighbourIcon
              color={isWatchingNeighbour ? "#007BFF" : "#282828"}
              size={"24"}
            />
          </div>

        {!isMobileView && (
            <div className="PropertyHeader_Icon" onClick={openGoogleMaps}>
            <LocationHeaderIcon />
          </div>
        )}

          <div
            className="PropertyHeader_Icon Hide_HeaderIcon"
            onClick={handleCommuteModalClick}
          >
            <PropertyCommuteIcon color={"#282828"} size={"24"} />
          </div>

          <div
            className="PropertyHeader_Icon"
            onClick={() => {
              handleHeartClick(data?.uId);
            }}
          >
            <HeartEmptyIcon
              color={"#282828"}
              fill={cardIde.includes(data?.uId) ? "#282828" : "none"}
            />
          </div>
          <div className="PropertyHeader_Icon">
            <IconButton
              aria-describedby="simple-popover"
              variant="contained"
              className="p-0"
              onClick={handlePopClick}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <ShareIcon />
            </IconButton>
            <Popover
              id="simple-popover"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handlePopClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              className="Share_featuresPopover"
              sx={{
                "& .MuiPopover-paper": {
                  borderRadius: "52px",
                  boxShadow: "none",
                  top: "100px !important",
                  width: "156px",
                },
              }}
            >
              <Typography sx={{ p: 1 }}>
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={handleCopyLink}
                  className="share__logos m-1"
                >
                  <img
                    src={require("../../../images/facebooklogo.png")}
                    className="share__logos"
                    alt="facebook logo"
                    onClick={handleFacebookLinkShare}
                    loading="lazy"
                  />
                </CopyToClipboard>
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={handleCopyLink}
                  className="share__logos"
                >
                  <img
                    src={require("../../../images/linkdinLogo.png")}
                    className="share__logos"
                    alt="Linkdin logo"
                    onClick={handleLinkdinLinkShare}
                    loading="lazy"
                  />
                </CopyToClipboard>
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={handleCopyLink}
                  className="share__logos mx-2"
                >
                  <img
                    src={require("../../../images/twitterlogo.png")}
                    className="share__logos"
                    alt="twitter logo"
                    onClick={handletwitterLinkShare}
                    loading="lazy"
                  />
                </CopyToClipboard>
                <CopyToClipboard
                  text={`${window.location.href}/${linkSlug}`}
                  onCopy={handleCopyLink}
                >
                  <img
                    src={require("../../../images/whatsapplogo.png")}
                    className="share__logos"
                    alt="whatsapp logo"
                    onClick={handleWhatsAppLinkShare}
                    loading="lazy"
                  />
                  {/* </a> */}
                </CopyToClipboard>
              </Typography>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PropertyHeader);
