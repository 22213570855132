import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import {
  BathIcon,
  BedIcon,
  BuildingIcon,
  EditIcon,
  PerSqftIcon,
  PropertyCommuteIcon,
  ScrollArrowIcon,
  ScrollArrowUpIcon,
  SqftIcon,
} from "../../SvgIcons/Icons";
import Cookies from "universal-cookie";
import NearbyCities from "../PropertyDetails/SubComponents/NearByCities";
import FindNearby from "../PropertyDetails/SubComponents/FindNearby";
import PopularSearches from "../PropertyDetails/SubComponents/PopularSearches";
import AgentQueryModal from "../PropertyDetails/SubComponents/Modals/AgentQueryModal";
import Footer from "../Footer/Footer";
import PropertyNotes from "../PropertyDetails/SubComponents/PropertyNotes";
import RatingModal from "../PropertyDetails/SubComponents/Modals/RatingModal";
import PropertyAgentCard from "../PropertyDetails/SubComponents/PropertyAgentCard";
import WhyBuyProperty from "../PropertyDetails/SubComponents/WhyBuyProperty";
import AboutProperty from "../PropertyDetails/SubComponents/AboutProperty";
import PriceHistory from "../PropertyDetails/SubComponents/PriceHistory";
import BasicDetails from "../PropertyDetails/SubComponents/BasicDetails";
import PropertyFaqs from "../PropertyDetails/SubComponents/PropertyFaqs";
import QueryCard from "../PropertyDetails/SubComponents/QueryCard";
import MortgageCalculator from "../PropertyDetails/SubComponents/MortgageCalculator";
import { Link } from "react-scroll";
import CommuteModal from "../PropertyDetails/SubComponents/Modals/CommuteModal";
import EmailConfirmationModal from "../Modals/EmaiConfirmationModal";
import PropertyHighlights from "../PropertyDetails/SubComponents/PropertyHighlights";
import SignUpModal from "../Modals/SignUpModal";
import Skeleton from "@mui/material/Skeleton";
import PropertyHeader from "../PropertyDetails/SubComponents/PropertyHeader";
import PropertyComment from "../PropertyDetails/SubComponents/PropertyComment";
import ViewMoreListings from "./SubComponents/ViewMoreListings";
import { PropertyData } from "../PropertyDetails/PropertData";

const FloorPlanDetails = () => {
  const cookies = new Cookies();
  const isUserlogin = cookies.get("jwt_authorization");
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const lastScrollTopRef = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [isCommuteModalOpen, setIsCommuteModalOpen] = useState(false);
  const [isEmailConfirmedModal, setIsEmailConfirmedModal] = useState(false);
  const [signUpModalHeading, setSignUpModalHeading] =
    useState("Create an account");

  //Hide or Show Navbar
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTopRef.current && !isScrollingDown) {
        setIsScrollingDown(true);
      } else if (
        currentScrollTop < lastScrollTopRef.current &&
        isScrollingDown
      ) {
        setIsScrollingDown(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrollingDown]);

  const handleSignUpModalClose = () => {
    setIsSignUpModalOpen(false);
    setSignUpModalHeading("Create an account");
  };

  const handleInfoModalClick = () => {
    setIsInfoModalOpen(true);
  };

  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  const handleRatingModalClose = () => {
    setRatingModalOpen(false);
  };

  const handleCommuteModalClick = () => {
    if (isUserlogin) {
      setIsCommuteModalOpen(true);
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading("Join us to navigate Your Commute!");
    }
  };

  const handleEmailConfirmedModalClose = () => {
    setIsEmailConfirmedModal(false);
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const PropertyStatus = "Sale";
  const isPriceDrop = false;

  const latitude = "43.65107";
  const longitude = "-79.347015";

  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {isLoading ? (
        <div className="container mt-3">
          <Skeleton animation="wave" variant="rounded" height={300} />
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ fontSize: "1.5rem" }}
          />
          <Skeleton variant="text" animation="wave" sx={{ fontSize: "1rem" }} />
        </div>
      ) : (
        <>
          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: isScrollingDown ? 100 : 1100,
              transition: "all ease .4s",
            }}
          >
            <Navbar />
          </div>
          <PropertyHeader
            isUserlogin={isUserlogin}
            setIsSignUpModalOpen={setIsSignUpModalOpen}
            handleInfoModalClick={handleInfoModalClick}
            handleCommuteModalClick={handleCommuteModalClick}
            isMobileView={isMobileView}
            isScrollingDown={isScrollingDown}
          />
          <div className="container Propert_details_main_container">
            <div className="row">
              <div className="col-lg-8 col-12">
                <div className="FloorPlan_heroSection">
                  <div className="">
                    <div className="floorPan_mainImage">
                      <img
                        className="w-100 h-100"
                        src="https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg"
                        alt="Property"
                      />
                    </div>
                    <div className="FloorPlan_main_information mt-3">
                      <div
                        className="PropertyStatusBadge PropertyDetails_Mbl_f14"
                        style={{
                          background:
                            PropertyStatus === "Sale" ? "#D1FADF" : "#FFDFC3",
                          color:
                            PropertyStatus === "Sale" ? "#008733" : "#BC300A",
                        }}
                      >
                        <span
                          style={{
                            background:
                              PropertyStatus === "Sale" ? "#008733" : "#BC300A",
                          }}
                        ></span>
                        {PropertyStatus === "Sale" ? "Selling Now" : "Sold"}
                      </div>
                      <h1>The Rockwell #10A</h1>
                      <div className="Prop_assignment_contentDetails">
                        <div className="d-flex align-items-center mt-2">
                          <h1>$85,000</h1>
                          <p
                            className={`${
                              isPriceDrop
                                ? "prop_show_upgrade"
                                : "prop_show_downgrade"
                            } m-0 ps-2 d-flex align-items-center`}
                            style={{
                              fontWeight: "600",
                              color: isPriceDrop ? "#008733" : "red",
                            }}
                          >
                            {isPriceDrop ? (
                              <ScrollArrowIcon color={"#008733"} size={24} />
                            ) : (
                              <ScrollArrowUpIcon color={"red"} size={24} />
                            )}
                            <span> $45K</span>
                          </p>
                        </div>
                        <p className="description PropertyDetails_Mbl_f14 m-0">
                          5601 Hudson Bend Rd Austin, TX 78734| Hudson Bend{" "}
                        </p>
                        <p className="description PropertyDetails_Mbl_f14 m-0">
                          {" "}
                          Est. $1335/mo
                          <span style={{ cursor: "pointer" }}>
                            {" "}
                            <Link
                              spy={true}
                              activeClass="active"
                              offset={-100}
                              smooth={true}
                              duration={500}
                              to={"calculator"}
                            >
                              <EditIcon />
                            </Link>
                          </span>
                        </p>
                      </div>

                      <div className="d-flex flex-wrap property_information_DetailsIcon mt-4">
                        <p>
                          <BedIcon /> <span>1 Bed</span>
                        </p>
                        <p>
                          <BathIcon color={"#282828"} /> <span>1 Bath</span>
                        </p>
                        <p>
                          <BuildingIcon /> <span>Condo</span>
                        </p>
                        <p>
                          <SqftIcon /> <span>720 sqft</span>
                        </p>
                        <p>
                          <PerSqftIcon /> <span>$1,650/SQFT</span>
                        </p>
                      </div>

                      <div className="Property_detailsWeb_hide w-100 prop_details_lgShow">
                        <img
                          src="/images/mapDirection.png"
                          alt="MapLocationImg"
                          loading="lazy"
                          className="Find_nearByMobileMapImg mt-3"
                          onClick={openGoogleMaps}
                        />
                        <button
                          className="Mbl_notifyBtn Mbl_notifyBtn_bluetbtn flex-row justify-content-between w-100 mt-3"
                          style={{
                            background: "var(--Primary-Natural-blue, #007BFF)",
                            maxWidth: "510px",
                            fontSize: "16px",
                            padding: "10px 16px",
                            fontWeight: "600",
                          }}
                          onClick={handleCommuteModalClick}
                        >
                          <span>Add a commute</span>
                          <PropertyCommuteIcon color={"#fff"} />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <div id="highlights" className="Prop_details_CompMargin">
                    <PropertyHighlights isMobileView={isMobileView} />
                  </div>
                  <div id="priceHistory" className="Prop_details_CompMargin">
                    <PriceHistory isMobileView={isMobileView} />
                  </div>
                  <div id="propertyDetails" className="Prop_details_CompMargin">
                    <BasicDetails isMobileView={isMobileView} />
                  </div>
                  <div id="reasonToBuy" className="Prop_details_CompMargin">
                    <WhyBuyProperty isMobileView={isMobileView} />
                  </div>
                  <div id="calculator" className="Prop_details_CompMargin">
                    <MortgageCalculator isMobileView={isMobileView} />
                  </div>
                  <div id="compareListing" className="Prop_details_CompMargin">
                    <ViewMoreListings isMobileView={isMobileView} />
                  </div>
                  <div className="Prop_details_CompMargin Details_mbl_margin">
                    <PropertyFaqs data={PropertyData?.FAQs}/>
                  </div>
                  <div
                    id="queryCard"
                    className="Prop_details_CompMargin Details_mbl_margin"
                  >
                    <QueryCard handleInfoModalClick={handleInfoModalClick} />
                  </div>
                  <div
                    id="propertyComment"
                    className="Prop_details_CompMargin Details_mbl_margin"
                  >
                    <PropertyComment />
                  </div>
                  <div
                    id="aboutProperty"
                    className="Prop_details_CompMargin Details_mbl_margin"
                  >
                    <AboutProperty isMobileView={isMobileView} />
                  </div>
                  <div id="popularSearches" className="Prop_details_CompMargin">
                    <PopularSearches isMobileView={isMobileView} />
                  </div>
                  <div id="nearByCities" className="Prop_details_CompMargin">
                    <NearbyCities isMobileView={isMobileView} />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-12 PropDetailsMob_lg_hide">
                <div>
                  <FindNearby
                    handleCommuteModalClick={handleCommuteModalClick}
                  />
                </div>
                <div
                  className={`position-sticky ${
                    isScrollingDown
                      ? "Stick_headerOnly"
                      : "prop_represent_linkStatic"
                  }  `}
                >
                  <div className="mt-3">
                    <PropertyAgentCard
                      handleInfoModalClick={handleInfoModalClick}
                    />
                  </div>
                  <div>
                    <PropertyNotes
                      setIsSignUpModalOpen={setIsSignUpModalOpen}
                      isUserlogin={isUserlogin}
                      setSignUpModalHeading={setSignUpModalHeading}
                    />
                  </div>
                </div>
              </div>
            </div>

            {isSignUpModalOpen && (
              <SignUpModal
                open={isSignUpModalOpen}
                handleClose={handleSignUpModalClose}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setIsEmailConfirmedModal={setIsEmailConfirmedModal}
                heading={signUpModalHeading}
              />
            )}
            {isInfoModalOpen && (
              <AgentQueryModal
                open={isInfoModalOpen}
                handleClose={handleInfoModalClose}
              />
            )}

            {ratingModalOpen && (
              <RatingModal
                open={ratingModalOpen}
                handleClose={handleRatingModalClose}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setSignUpModalHeading={setSignUpModalHeading}
                isUserlogin={isUserlogin}
              />
            )}

            {isCommuteModalOpen && (
              <CommuteModal
                open={isCommuteModalOpen}
                setIsCommuteModalOpen={setIsCommuteModalOpen}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setSignUpModalHeading={setSignUpModalHeading}
              />
            )}

            {isEmailConfirmedModal && (
              <EmailConfirmationModal
                open={isEmailConfirmedModal}
                handleClose={handleEmailConfirmedModalClose}
              />
            )}
          </div>

          <div className="Prop_detailsMobileFixedBtn prop_details_BtnShow Property_detailsBtn_hide">
            <button
              className="propertyDetails_moreInfo PropDetails_gradientBtn"
              onClick={handleInfoModalClick}
            >
              Request for more info
            </button>
            <button
              className="propertyDetails_moreInfo NoteBtn"
              onClick={() => {
                if (isUserlogin) {
                  setRatingModalOpen(true);
                } else {
                  setIsSignUpModalOpen(true);
                  setSignUpModalHeading(
                    "Become a member to access & Write Research Notes."
                  );
                }
              }}
            >
              Research Note
            </button>
          </div>
        </>
      )}

      <div style={{ marginTop: "6rem" }}>
        <Footer />
      </div>
    </>
  );
};

export default FloorPlanDetails;
