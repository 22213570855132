import React, { useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CalculatorIcon } from "../../../SvgIcons/Icons";

const AssignmentPricing = (props) => {
  const {
    data,
    sellerAskingPrice,
    isMobileView,
    purchasePrice,
    setPurchasePrice,
  } = props;
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  //Calculator Work

  const formatPurchaseValue = (value) => {
    return value ? parseFloat(value).toLocaleString("en-US") : "";
  };

  const handlePurchasePriceChange = (e) => {
    const rawValue = e.target.value;
    const numericValue = rawValue.replace(/[^0-9.]/g, "");
    setPurchasePrice(numericValue);
  };

  const totalToSeller = Math.ceil(
    purchasePrice - data?.originalPrice + data?.depositToBuilder
  );
  const depositPercentage = Math.ceil(purchasePrice * 0.05);
  const BalancePayment = Math.ceil(totalToSeller - depositPercentage);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion"
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <CalculatorIcon />}
          <h2 className="property_Details_heading">Assignment Pricing</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-100 propDetail_mortgageCalc">
          <div className="Prop_detailMortgage_calc mt-3">
            <div
              className={`p-3 d-flex ${isMobileView && "flex-column"}`}
              style={{
                backgroundColor: "var(--Primary-Light-blue, #E2F1FF)",
                borderRadius: "16px",
              }}
            >
              <div className="col-md-4 col-12">
                <h5>Seller Asking Price</h5>
                <h6>${sellerAskingPrice?.toLocaleString("en-Us")}</h6>
              </div>
              <div className="col-md-4 col-12 mbl_margin">
                <h5>Orignal Purchase Price</h5>
                <h6 className="m-0">
                  ${data?.originalPrice?.toLocaleString("en-US")}
                </h6>
              </div>
              <div className="col-md-4 col-12 mbl_margin">
                <h5>Orignal Purchase Deposit</h5>
                <h6 className="m-0">
                  {data?.depositToBuilder
                    ? `$${data?.depositToBuilder?.toLocaleString("en-US")}`
                    : "N/A"}
                </h6>
              </div>
            </div>
            <div
              className={`p-3 mt-3 d-flex ${isMobileView && "flex-column"}`}
              style={{
                backgroundColor: " var(--Accent-Light-Gray, #F4F4F5)",
                borderRadius: "16px",
              }}
            >
              <div className="col-md-4 col-12">
                <label>Bidding Price</label>
                <br />
                <input
                  type="text"
                  value={
                    formatPurchaseValue(purchasePrice) === ""
                      ? ""
                      : `$${formatPurchaseValue(purchasePrice)}`
                  }
                  onChange={handlePurchasePriceChange}
                  style={{color:'var(--Primary-Natural-blue, #007BFF)',fontWeight:'700'}}
                />
              </div>
            </div>
            <div
              className={`p-3 mt-3 Assignment_pricing d-flex ${isMobileView && "flex-column"}`}
              style={{
                backgroundColor: " var(--Accent-Light-Gray, #F4F4F5)",
                borderRadius: "16px",
              }}
            >
              <div className="col-md-4 col-12">
                <h5>Buyer's total payment</h5>
                <h6>${totalToSeller?.toLocaleString("en-US")}</h6>
              </div>
              <div className="col-md-4 col-12 mbl_margin">
                <h5>5% deposit upon acceptance</h5>
                <h6 className="m-0">
                  ${depositPercentage?.toLocaleString("en-US")}
                </h6>
              </div>
              <div className="col-md-4 col-12 mbl_margin">
                <h5>Balance upon completion</h5>
                <h6 className="m-0">
                  ${BalancePayment?.toLocaleString("en-US")}
                </h6>
              </div>
            </div>
            <div
              className={`p-3 Assignment_pricing mt-3 d-flex ${isMobileView && "flex-column"}`}
              style={{
                backgroundColor: " var(--Accent-Light-Gray, #F4F4F5)",
                borderRadius: "16px",
              }}
            >
              <div className="col-md-4 col-12">
                <h5>Occupancy-Due</h5>
                <h6>
                  {data?.occupancyDue
                    ? `$${data?.occupancyDue?.toLocaleString("en-US")}`
                    : "N/A"}
                </h6>
              </div>
              <div className="col-md-4 col-12 mbl_margin">
                <h5>Maintenance Fee</h5>
                <h6 className="m-0">
                  {data?.maintainenceFee
                    ? `$${data?.maintainenceFee?.toLocaleString("en-US")}`
                    : "N/A"}
                </h6>
              </div>
              <div className="col-md-4 col-12 mbl_margin">
                <h5>Development / Levy Charges</h5>
                <h6 className="m-0">
                  {data?.levyFee
                    ? `$${data?.levyFee?.toLocaleString("en-US")}`
                    : "N/A"}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(AssignmentPricing);
