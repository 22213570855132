import React, { useState, memo } from "react";
import {
  DaysOnMarketIcon,
  DollarIcon,
  HeartEmptyIcon,
  MarketActivityIcon,
  SavesIcon,
  ViewsIcon,
  WatchingNeighbourIcon,
  WatchSoldIcon,
} from "../../../SvgIcons/Icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const MarketActivity = ({ isMobileView, data }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion Property_simpleAccordion PropertyDetails_MobileAccordion py-2"
      sx={{ background: "#ffff" }}
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <MarketActivityIcon />}
          <h2 className="property_Details_heading">Market Activity</h2>
          {!isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
              Join the growing community—track your favorites, save etc and see
              what's trending, and stay ahead with real-time market insights!
            </p>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isMobileView && (
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
            Join the growing community—track your favorites, save etc and see
            what's trending, and stay ahead with real-time market insights!
          </p>
        )}

        <div className="w-100 marketActivity_CardContainer">
          {data?.listingActivityDTO?.fav >= 100 && (
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  background: "#FFBFCB",
                  borderColor: "#FFE0E6",
                }}
              >
                <HeartEmptyIcon size={"25"} color={"#BC300A"} />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  {data?.listingActivityDTO?.fav}
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Favorites</p>
              </div>
            </div>
          )}

          {data?.listingActivityDTO?.view >= 100 && (
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#ECFDF3",
                  background: "var(--Light-green, #D1FADF",
                }}
              >
                <ViewsIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                  {data?.listingActivityDTO?.view}
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Views</p>
              </div>
            </div>
          )}

          <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
            <div
              className="propertyDetail_homeValue_icon"
              style={{
                borderColor: "#F4ECFF",
                background: "#E4D2FF",
              }}
            >
              <DaysOnMarketIcon />
            </div>
            <div className="w-100 mt-2">
              <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                {data?.listingActivityDTO?.day
                  ? data?.listingActivityDTO?.day
                  : 0}
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Days on Market
              </p>
            </div>
          </div>

          <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
            <div
              className="propertyDetail_homeValue_icon"
              style={{
                borderColor: "#ECFDF3",
                background: "var(--Light-green, #D1FADF)",
              }}
            >
              <WatchingNeighbourIcon />
            </div>
            <div className="w-100 mt-2">
              <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                {data?.listingActivityDTO?.neighbour
                  ? data?.listingActivityDTO?.neighbour
                  : 0}
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14 Propmbl_f11">
                Watching Neighbourhood
              </p>
            </div>
          </div>

          <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
            <div
              className="propertyDetail_homeValue_icon"
              style={{
                borderColor: "#F4ECFF",
                background: "#E4D2FF",
              }}
            >
              <WatchSoldIcon />
            </div>
            <div className="w-100 mt-2">
              <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                {data?.listingActivityDTO?.notifySold
                  ? data?.listingActivityDTO?.notifySold
                  : 0}
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Notify when Sold
              </p>
            </div>
          </div>

          <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
            <div
              className="propertyDetail_homeValue_icon"
              style={{
                borderColor: "#FFFCDF",
                background: "var(--Light-yellow, #FFF7A0)",
              }}
            >
              <DollarIcon color={"#713F12"} />
            </div>
            <div className="w-100 mt-2">
              <h6 className="mt-3 mbl_marginTop0 market_activityCardHeading PropertyDetails_Mbl_f18">
                {data?.listingActivityDTO?.appsf
                  ? `$${Math.ceil(
                      data?.listingActivityDTO?.appsf
                    )?.toLocaleString("en-US")}`
                  : 0}
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Avg Price Per SqFt
              </p>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(MarketActivity);
